export const LOCATION = [
  {
    id: "toronto",
    value: "Toronto",
  },
  {
    id: "mississauga",
    value: "Mississauga",
  },
  {
    id: "brampton",
    value: "Brampton",
  },
  {
    id: "markham",
    value: "Markham",
  },
  {
    id: "vaughan",
    value: "Vaughan",
  },
  {
    id: "richmond-hill",
    value: "Richmond Hill",
  },
  {
    id: "pickering",
    value: "Pickering",
  },
  {
    id: "ajax",
    value: "Ajax",
  },
  {
    id: "whitby",
    value: "Whitby",
  },
  {
    id: "oshawa",
    value: "Oshawa",
  },
];

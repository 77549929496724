export const regions = [
    {
        name: "All Stores",
        value: "all"
    },
    {
        name: "North Africa",
        value: "Northern Africa"
    },
    {
        name: "East Africa",
        value: "Eastern Africa"
    },
    {
        name: "West Africa",
        value: "Western Africa"
    },
    {
        name: "South Africa",
        value: "Southern Africa"
    },
    {
        name: "Caribbean",
        value: "Caribbean"
    }
]
import _ from "lodash";
import { getTokenFromCookie } from "./constants";
import { putRequest } from "../redux/action";

export const socialRefLink = (link, social) => {
  switch (social) {
    case "Facebook":
      const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        link
      )}`;
      return window.open(facebookLink, "_blank");
    case "Whatsapp":
      const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `Check out this referral link: ${link}`
      )}`;
      return window.open(whatsappLink, "_blank");
    case "Telegram":
      const telegramLink = `https://t.me/share/url?url=${encodeURIComponent(
        link
      )}&text=${encodeURIComponent("Check out this referral link")}`;
      return window.open(telegramLink, "_blank");
    case "Messenger":
      const messengerLink = `fb-messenger://share/?link=${encodeURIComponent(
        link
      )}&app_id=123456`;
      return window.open(messengerLink, "_blank");
    case "Twitter":
      const tweetText = "Check out this referral link";
      const twitterLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        link
      )}&text=${encodeURIComponent(tweetText)}`;
      return window.open(twitterLink, "_blank");

    default:
      const subject = "Check out this referral link";
      const body = `Here's the link: ${link}`;
      const mailtoLink = `mailto:?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      return (window.location.href = mailtoLink);
  }
};

export const truncateText = (str, max) =>
  str?.length > max ? str.substr(0, max - 1) : str;

export const PasswordStrengthCheck = (password) => {
  const passwordStrength = {
    "1-upper-case-character": /[A-Z]/.test(password),
    "1-special-character": /[^a-zA-Z0-9]/.test(password),
    "8-characters": password.length >= 8,
  };
  const criteriaCount = Object.values(passwordStrength).filter(Boolean).length;
  return { criteriaCount, passwordStrength };
};

export const handlePlacesApiSuggestions = _.debounce(
  async (input, sessionToken, setLoadingPlaces, handleResult) => {
    try {
      setLoadingPlaces?.(true);
      const result = await putRequest(
        "/api/place",
        {
          input,
          token: sessionToken,
          getDetails: false,
        },
        getTokenFromCookie()
      );
      handleResult?.(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPlaces?.(false);
    }
  },
  700
);

export const handleDistanceApiSuggestions = _.debounce(async (payload, setFetchingAddress, handleResult) => {
  try {
    setFetchingAddress?.(true);
    const result = await putRequest("/api/distance", payload);
    handleResult?.(result);
  } catch (error) {
    console.log(error)
  } finally {
    setFetchingAddress?.(false);
  }
}, 700);

export const getPlaceDetails = async (input, sessionToken, place_id) => {
  try {
    const result = await putRequest(
      "/api/place",
      {
        input,
        token: sessionToken,
        place_id,
        getDetails: true,
      },
      getTokenFromCookie()
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};
//geolocation success function
export const handleSuccessCordinatePositions = (position) => {
  const latitude = position.coords.latitude;
  const longitude = position.coords.longitude;

  // Now you can use these coordinates to get the address
  return getAddressFromCoordinates(latitude, longitude);
};

// Error callback function
export const handleErrorCordinatePositionsResponse = () => {
  console.log("Unable to retrieve your location");
};

async function getAddressFromCoordinates(latitude, longitude) {
  const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

  const data = await fetch(url)
    .then((response) => response.json())
    .then((data) => {
      // localStorage.setItem(
      //   "locationDetails",
      //   JSON.stringify(data?.address)
      // );

      // localStorage.removeItem("place_details");
      return data;
    })
    .catch((error) => {
      console.log("Error: " + error);
    });

  return data;
}

export const getLocationPermissionState = (setState) => {
  navigator.permissions.query({ name: "geolocation" }).then((result) => {
    if (result.state === "granted") {
      setState("granted");
    } else if (result.state === "prompt") {
      navigator.geolocation.getCurrentPosition(
        (_position) => setState("granted"),
        () => setState("denied")
      );
    } else if (result.state === "denied") {
      setState("denied");
    }
  });
};
export const handleSetAddress = (value) => {
   // Initialize variables for the address components
   let streetAddress = '';
   let city = '';
   let state = '';
   let country = '';
   let postalCode = ''
 
  const parts = value.split(',').map(part => part.trim());
  
  // Loop through the parts array in reverse order
  for (let i = parts.length - 1; i >= 0; i--) {
    const part = parts[i];

    // Assign components based on the expected order
    if (i === parts.length - 1) {
      country = part; // Last part is the country
    } else if (i === parts.length - 2) {
      state = part; // second last part is the state
    } else if (i === parts.length - 3) {
      city = part; // third last part is the city
    } else if (i === 0) {
      streetAddress = parts.slice(0, i + 1).join(', '); // Join remaining parts as the street address
    }
  }
  return {country, state, city, streetAddress, postalCode}
}
import axios from "axios";
import { getTokenFromCookie } from "../utils/constants";
import { renderValidUrl } from "../utils/constants";

export const userLogin = (user) => dispatch => {
  dispatch({
    type: 'LOGIN_USER',
    payload: user,
  });
};

export const logOutUser = () => dispatch => {
  dispatch({
    type: 'LOG_OUT',
  });
};

export const guestLogin = () => dispatch => {
  dispatch({
    type: 'GUEST_LOGIN',
    loginGuest: true,
  });
};

export const setUserLocation = (hash) => dispatch => {
  dispatch({
    type: 'SET_LOCATION',
    ...hash
  })
}

export const setOrderID = (orderID) => dispatch => {
  dispatch({
    type: 'SET_ORDER_ID',
    payload: orderID,
  });
};

export const guestLogOut = () => dispatch => {
  dispatch({
    type: 'GUEST_LOGOUT',
  });
};

export const setUserInfoTab = val => dispatch => {
  dispatch({
    type: 'USER_INFO_TAB',
    userInfoTab: val,
  });
};

export const editUserInfo = () => dispatch => {
  dispatch({
    type: 'EDIT_INFO_TAB',
    editInfoTab: true,
  });
};
export const undoUserInfo = () => dispatch => {
  dispatch({
    type: 'UNDO_INFO_TAB',
  });
};

export const addToCart = (hash) => dispatch => {
  dispatch({
    type: 'ADD_TO_CART',
    ...hash
  })
}

export const handleCartOperation = (hash) => dispatch => {
  dispatch({
    type: 'HANDLE_CART',
    ...hash,
  });
};

export const updateCart = (hash) => dispatch => {
  dispatch({
    type: 'UPDATE_CART',
    ...hash
  });
};

export const clearDisabledinCart = (cart) => dispatch => {
  const updatedCart = {};

  Object.keys(cart).forEach(storeKey => {
    if (!cart[storeKey].disabled) {
      updatedCart[storeKey] = cart[storeKey];
    }
  });

  dispatch({
    type: 'UPDATE_CART',
    payload: updatedCart,
    inplace: true
  });
}

export const setOrderForPayment = (hash) => dispatch => {
  dispatch({
    type: 'CHECKOUT_ORDER',
    checkoutOrder: hash,
  });
};

export const changeCheckoutStore = (hash) => dispatch => {
  dispatch({
    type: 'CHECKOUT_STORE',
    checkoutStore: hash,
  });
};

export const clearCart = () => dispatch => {
  dispatch({
    type: 'CLEAR_CART',
  });
};

export const clearStore = (store) => dispatch => {
  dispatch({
    type: 'CLEAR_STORE',
    storeID: store,
  });
};

export const updateFilters = (hash) => dispatch => {
  dispatch({
    type: 'UPDATE_FILTERS',
    filters: hash,
  });
};

export const clearFilters = (hash) => dispatch => {
  dispatch({
    type: 'CLEAR_FILTERS',
    filters: hash,
  });
};

export const activeModal = val => dispatch => {
  dispatch({
    type: 'ACTIVE_MODAL',
    getActiveModalName: val,
  });
};

export const showForm = () => dispatch => {
  dispatch({
    type: 'SHOW_FORM',
    showUserForm: true,
  });
};

export const hideForm = () => dispatch => {
  dispatch({
    type: 'HIDE_FORM',
  });
};

export const showEditAddressForm = () => dispatch => {
  dispatch({
    type: 'SHOW_FORM_EDIT',
    editAddressForm: true,
  });
};

export const hideEditAddressForm = () => dispatch => {
  dispatch({
    type: 'HIDE_FORM_EDIT',
  });
};

export const updateUser = (user) => dispatch => {
  dispatch({
    type: 'UPDATE_USER',
    payload: user,
  })
}

export const resetApp = () => dispatch => {
  dispatch({
    type: 'RESET'
  })
}


export const postRequest = (url, data, token = false) => {
  return fetch(renderValidUrl(url), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',

      ...(token && { 'Authorization': `Bearer ${token}` })
    },
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(responseData => {
      return [true, responseData];
    })
    .catch(error => {
      console.error('Error:', error);
      return [false, error];
    });
};

export const putRequest = async (url, data, token) => {
  try {
    const response = await fetch(renderValidUrl(url), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` })
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return [true, responseData];
  } catch (error) {
    console.error('Error:', error);
    return [false, error];
  }
};

export const deleteRequest = async (url, token) => {
  try {
    const response = await fetch(renderValidUrl(url), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const responseData = await response.json();
    return [true, responseData];
  } catch (error) {
    console.error('Error:', error);
    return [false, error];
  }
};

export const fetchData = async (dispatch, url, type, token) => {
  dispatch({ type: 'SET_IS_FETCHING', isFetching: true });

  try {
    const { data } = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` })
      },
    });
    dispatch({ type: 'GET_API_REQUEST', hash: { [type]: data } });
  } catch (error) {
    dispatch({ type: 'SET_ERROR', error });
  }
};

export const CreateOrder = async (orderOutForPayment, handleCancelledPayment, handleSuccessfulPayment, successParam, cancelParam) => {
  const token = getTokenFromCookie()

  if (successParam === 'true') {
    try {
      const API_ORDERS_ENDPOINT = "/api/orders";
      const headerConfig = token
        ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        : {};

      const [success, response] = await postRequest(
        API_ORDERS_ENDPOINT,
        orderOutForPayment,
        headerConfig
      );

      if (!success || response.status !== 200) {
        console.error(response.error);
        throw new Error("Sorry. Couldn't submit your order. Please try again later!");
      }

      if (response.adHocStatus !== 200) {
        console.error(response.error);
      }

      handleSuccessfulPayment(response.orderID);

      return [true, 'success', response.message]

    } catch (error) {

      handleCancelledPayment();
      return [true, 'error', error.message, 'top-right']
    }
  } else if (cancelParam === 'true') {
    console.log("cancel order")
    handleCancelledPayment();
  } else {
    console.log('did not run')
  }

  return [false, null, null]
}

export const fetchStores = (location) => async (dispatch) => {
  const url = (location && location !== "") ? `/stores?location=${location}` : '/stores'

  await fetchData(dispatch, url, 'stores');
};
export const fetchProductCategories = () => async (dispatch) => {
  await fetchData(dispatch, '/product-categories', 'productCategories');
};

export const fetchProducts = () => async (dispatch) => {
  await fetchData(dispatch, '/products', 'products');
};

export const getOtp = () => async (dispatch) => {
  await fetchData(dispatch, '/generate-otp', 'generate-otp')
}

export const getUserProfile = () => async (dispatch) => {
  await fetchData(dispatch, '/users/me', 'userProfile')
}

export const getUserAddress = (token) => async (dispatch) => {
  await fetchData(dispatch, '/addresses', 'addresses', token)
}

export const getUserOrders = (token, customerID) => async (dispatch) => {
  await fetchData(dispatch, `/orders?customerID=${customerID}`, 'orders', token)
}

export const getUserReferralId = (token) => async (dispatch) => {
  await fetchData(dispatch, '/referrals', 'referrals', token)
}

export const getAnnouncementBanner = () => async (dispatch) => {
  await fetchData(dispatch, '/afro-banner', 'announcementBannerData')
}

export const testPlace = async (input, token) => {
  try {
    const { data } = await axios.get(
      renderValidUrl(`/api/place?address=${input}`), {
      headers: {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` })
      },
    });
    if (data.status === 200) {
      return [true, data]
    }
    throw new Error(data.message)
  } catch (error) {
    console.error(error.message)
    return [false, null]
  }
}

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export const addToast = (toast) => ({
  type: ADD_TOAST,
  payload: {
    ...toast,
    id: Math.random().toString(36).substr(2, 9), // Generate unique ID here
  },
});

export const removeToast = (id) => ({
  type: REMOVE_TOAST,
  id,
});



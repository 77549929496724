import {
  Document,
  Image,
  Page,
  StyleSheet,
  View,
  Text,
  Font
} from "@react-pdf/renderer";
import Lexend from '../../shared/fonts/Lexend-Regular.ttf';
import LexendBold from '../../shared/fonts/Lexend-Bold.ttf';
import { formatNumber } from "../../utils/constants";

const PdfLayout = ({ orderID, subTotal, serviceFee, firstName, lastName, delivery, grandTotal, taxes, tips, products, phoneNumber, deliveryOption, address, deliveryDateTime }) => {
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  const date = new Date(deliveryDateTime);
  const formattedDate = date.toLocaleDateString('en-US', options);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.pageWrapper}>
          <View style={styles.invoiceTextNumberContainer}>
            <Image
              src={"/receipt-logo.png"}
              style={{ width: "113px", height: "auto" }}
            />
            <Text style={styles.invoiceText}>RECEIPT</Text>
          </View>

          <View style={styles.invoiceForFromContainer}>
            <View style={styles.invoiceFor}>
              <Text style={styles.invoiceForFromTitle}>BILLING INFO:</Text>
              <View>
                <Text style={styles.invoiceForFromText}>
                  {firstName} {lastName}
                </Text>
                {
                  deliveryOption &&
                  <>
                    <Text style={styles.invoiceForFromText}>
                      {`${address.streetAddress},`}
                    </Text>
                    <Text style={styles.invoiceForFromText}>
                      {`${address.city}, ${address.state}, ${address.country}`}
                    </Text>
                  </>
                }
                <Text style={styles.invoiceForFromText}>{phoneNumber}</Text>
              </View>
            </View>
            <View style={styles.invoiceFrom}>
              <Text style={styles.invoiceForFromTitle}>Order Number:</Text>
              <View>
                <Text style={styles.invoiceForFromText}>
                  {`#${orderID}`}
                </Text>
              </View>
            </View>
            <View style={styles.invoiceFrom}>
              <Text style={styles.invoiceForFromTitle}>Date:</Text>
              <View>
                <Text style={styles.invoiceForFromText}>
                  {formattedDate}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={[styles.tableHeaderItem, { width: "40%" }]}>
                Description
              </Text>
              <Text style={[styles.tableHeaderItem, { width: "20%" }]}>
                Qty
              </Text>
              <Text style={[styles.tableHeaderItem, { width: "20%" }]}>
                Price($)
              </Text>
              <Text style={[styles.tableHeaderItem, { width: "20%" }]}>
                Amount($)
              </Text>
            </View>
            {products?.map((item) => {
              return (
                <View key={item?.id} style={styles.tableRow}>
                  <Text style={[styles.tableCol, { width: "40%" }]}>
                    {item?.name}
                  </Text>
                  <Text style={[styles.tableCol, { width: "20%" }]}>
                    {item?.amount}
                  </Text>
                  <Text style={[styles.tableCol, { width: "20%" }]}>
                    {formatNumber(item?.price * (1 + (item?.percentMarkup ?? 0) / 100) * (1 - (item?.percentDiscount ?? 0) / 100))}
                  </Text>
                  <Text style={[styles.tableCol, { width: "20%" }]}>
                    {formatNumber(item?.amount * item?.price * (1 + (item?.percentMarkup ?? 0) / 100) * (1 - (item?.percentDiscount ?? 0) / 100))}
                  </Text>
                </View>
              );
            })}
          </View>

          <View style={styles.signatureTotalContainer}>
            <View style={[styles.totalWrapper, { marginTop: "0px" }]}>
              <Text style={styles.totalText}>Sub-Total:</Text>
              <Text style={styles.totalText}>{`$${formatNumber(subTotal)}`}</Text>
            </View>
            <View style={styles.totalWrapper}>
              <Text style={styles.totalText}>Service Fee:</Text>
              <Text style={styles.totalText}>{`$${formatNumber(serviceFee)}`}</Text>
            </View>
            <View style={styles.totalWrapper}>
              <Text style={styles.totalText}>Delivery:</Text>
              <Text style={styles.totalText}>{`$${formatNumber(delivery)}`}</Text>
            </View>
            <View style={styles.totalWrapper}>
              <Text style={styles.totalText}>Taxes:</Text>
              <Text style={styles.totalText}>{`$${formatNumber(taxes)}`}</Text>
            </View>
            <View style={styles.totalWrapper}>
              <Text style={styles.totalText}>Tips:</Text>
              <Text style={styles.totalText}>{`$${formatNumber(tips)}`}</Text>
            </View>
            <View style={styles.totalWrapper}>
              <Text style={styles.totalTextBlack}>Total:</Text>
              <Text style={styles.totalTextBlack}>
                {`$${formatNumber(grandTotal)}`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.footer}>
          {/* <Text style={styles.footerTextGreen}>PAYMENT METHOD</Text>
          <Image
            src={"/Mastercard.png"}
            style={{ width: "36px", height: "auto" }}
          />
          <Text style={styles.footerText}>MASTERCARD</Text>
          <Text style={styles.footerText}>**** **** **** 2474</Text> */}
          <Text style={styles.footerContact}>
            For any questions please contact us at hi@afroshop.com
          </Text>
        </View>
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Lexend',
  fonts: [
    {
      src: Lexend,
    },
    {
      src: LexendBold,
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    fontSize: 12,
    color: "#333",
    backgroundColor: "#fff",
    fontFamily: 'Lexend'
  },
  pageWrapper: {
    padding: "0.4in 0.4in",
  },
  invoiceTextNumberContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: '10px',
  },
  invoiceText: {
    color: "#333333",
    fontWeight: 700,
    fontSize: '20px'
  },
  invoiceId: {
    textAlign: "center",
  },
  invoiceForFromContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: '40px',
  },
  invoiceForFromTitle: {
    marginBottom: 4,
    color: '#186F3D',
  },
  invoiceFor: {
    width: '30%',
  },
  invoiceFrom: {
  },
  invoiceForFromText: {
    color: "#7F7F7F",
    lineHeight: 1.5,
    // width: "174px",
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '10px'
  },
  dividerSM: {
    width: "100%",
    height: 1,
    marginTop: 12,
    marginBottom: 12,
    backgroundColor: "#e5e5e5",
  },
  dividerLG: {
    width: "100%",
    height: 1,
    marginTop: 40,
    marginBottom: 40,
    backgroundColor: "#e5e5e5",
  },
  table: {
    marginTop: 32,
    border: "1px solid #E6E6E6",
    borderBottom: 'none'
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
  },
  tableHeaderItem: {
    paddingVertical: 8,
    borderBottom: "1px solid #E6E6E6",
    fontSize: '10px',
    color: '#333333',
    textAlign: 'left',
    padding: '10px'
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #E6E6E6",
  },
  tableCol: {
    paddingVertical: 8,
    paddingHorizontal: 4,
    color: '#7F7F7F',
    fontSize: '10px',
    padding: '10px'
  },
  signatureTotalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginTop: 32,
    marginLeft: 'auto',
    width: "319px",
    height: '140px',
    padding: '20px',
    backgroundColor: "#F2F2F2",
    borderRadius: '3px'
  },
  signatureContainer: {},
  totalContainer: {},
  signatureText: {
    marginTop: 32,
  },
  totalWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    marginTop: '20px',
    width: '100%',
    alignItems: 'center'
  },
  totalText: {
    color: "#7F7F7F",
  },
  totalTextBlack: {
    color: "#333333",
    marginTop: '10px'
  },
  footer: {
    height: '193px',
    paddingTop: 8,
    marginTop: "auto",
    backgroundColor: "#F2F2F2",
    padding: "0.4in 0.4in",
  },
  footerText: {
    color: "#7F7F7F",
    lineHeight: 1.5,
    marginTop: '10px',
    fontSize: '10px'
  },
  footerTextGreen: {
    marginBottom: '20px',
    color: "#186F3D",
  },
  footerContact: {
    color: "#7F7F7F",
    marginTop: '20px',
  },
});

export default PdfLayout;

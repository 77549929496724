export const getStoreCartSize = (cart, store) => {
    let size = 0
    for (const product in cart[store]?.items) {
        size += cart[store]?.items[product]?.quantity
    }
    return size
}

export const getCartSize = (cart) => {
    let size = 0
    for (const store in cart) {
        size += getStoreCartSize(cart, store)
    }
    return size
}

export const canadaVAT = 13

export const getStoreTotals = (cart, store) => {
    let subTotal = 0.0
    let itemTaxes = 0.0
    let itemTotal = 0.0
    for (const product in cart[store]?.items) {
        itemTotal = (
            cart[store].items[product].quantity
            * cart[store].items[product].price
            * (cart[store].items[product].percentDiscount ? (1 - cart[store].items[product].percentDiscount / 100) : 1) // adding discount leg
            * (cart[store].items[product].percentMarkup ? (1 + cart[store].items[product].percentMarkup / 100) : 1) // adding markup leg
        )
        if (cart[store].items[product].taxable) {
            itemTaxes += itemTotal * canadaVAT / 100
        }
        subTotal += itemTotal
    }
    const serviceFee = subTotal * 0.1
    const total = subTotal * 1.1
    return { subTotal, total, itemTaxes, serviceFee }
}
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { locate, GreenCancel } from "../../../images";
import { PropTypes } from "prop-types";
import { Button } from "../../shared";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { activeModal, setUserLocation, testPlace } from "../../../redux/action";
import { SELECTED_LOCATION, getTokenFromCookie } from "../../../utils/constants";
import { OutSideClick } from "../../../helpers";
import {
  handleErrorCordinatePositionsResponse,
  getLocationPermissionState,
  handleSuccessCordinatePositions,
} from "../../../utils";
import InputComponent from "../../shared/inputComponent";
import AddressComponent from "../../confirm-order/address-component";

function DeliveryAvailabilty() {
  const dispatch = useDispatch();
  const token = getTokenFromCookie();
  const [valid, setValid] = useState(false)

  const handlelocationChange = (locationDetails) => {
    Cookies.set(SELECTED_LOCATION, locationDetails.display_name, { expires: 365 });
    dispatch(setUserLocation({ location: locationDetails }));
  }

  const handleGetLocationFromBrowser = () => {
    if (!savedCity) {
      getLocationPermissionState(setState);
      if (isLocationAvailable === "granted" && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const data = await handleSuccessCordinatePositions(position);
          const full_address = data.display_name

          const [success, responseData] = await testPlace(full_address, token)

          if (success) {
            handlelocationChange({
              display_name: responseData.short,
              full_address: responseData.address
            })
          }
        }, handleErrorCordinatePositionsResponse);
      }
    }
  };

  const [isLocationAvailable, setState] = useState("prompt");
  useLayoutEffect(() => {
    if (isLocationAvailable === "granted") {
      handleGetLocationFromBrowser();
      dispatch(activeModal(""));
    }
    else {
      getLocationPermissionState(setState);
    }
  }, [isLocationAvailable]);

  const reduxLocationState = useSelector((state) => state.location);
  const savedCity = Cookies.get(SELECTED_LOCATION);
  const [city, setCity] = useState(reduxLocationState.full_address);

  const [loadingPlaceDetails, setLoadingPlaceDetails] = useState(false);

  const modalRef = useRef(null);
  const locationClickOutside = OutSideClick(modalRef);

  const form = useForm({
    mode: "all",
    defaultValues: {
      City: reduxLocationState ?? ""
    },
  });

  const {
    setError,
    handleSubmit,
  } = form


  const handleSubmitDelivery = async () => {
    setLoadingPlaceDetails(true);
    try {
      const [success, data] = await testPlace(city, token)

      if (success) {
        handlelocationChange({
          display_name: data.short,
          full_address: data.address
        })
        dispatch(activeModal(""));
      }
      else {
        dispatch(activeModal("noDelivery"));
      }
    } catch (error) {
      console.error(error);
      setError("address", {
        type: "manual",
        message: "An error occurred while verifying the address."
      });
    } finally {
      setLoadingPlaceDetails(false);
    }


  };

  const handleCancelDeliveryAvailabilityModal = (event) => {
    event?.stopPropagation();
    handleGetLocationFromBrowser();
    dispatch(activeModal(""));
  };

  useEffect(() => {
    if (locationClickOutside) {
      handleCancelDeliveryAvailabilityModal();
    }
  }, [locationClickOutside]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex items-center pt-10 justify-center h-screen fixed inset-0 bg-blackTransparent bg-opacity-10 rounded-[4px] z-50"
    >
      {
        isLocationAvailable === "denied" &&
        <div
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
          className="px-6 py-4 lg:px-0 w-[400px] gap-4 md:w-[800px] bg-white mx-4 rounded-[4px]"
        >
          <div className="flex flex-col items-center relative justify-center">
            <div
              className="absolute lg:right-10 right-0 cursor-pointer top-4"
              onClick={handleCancelDeliveryAvailabilityModal}
            >
              <GreenCancel />
            </div>

            <div className="mx-auto mb-[0px]">
              <img src={locate} className="mx-auto" alt="location pin" />
            </div>
            <div className="text-center w-full md:w-[327px]">
              <h1 className="my-4 text-green text-[20px] font-[700]">
                Hi there!
              </h1>
              <div className="mb-4 leading-6 text-[#333333] text-[16px]">
                We currently offer delivery within the Greater Toronto Area (GTA)
                only. Please type in your Address or Province.
              </div>
              <form onSubmit={handleSubmit(handleSubmitDelivery)} className="h-[130px] flex flex-col justify-between mb-[30px]">
                <AddressComponent
                  name="city"
                  form={form}
                  defaultAddress={city}
                  setAddressField={(value) => setCity(value)}
                  showLabel={false}
                  setValid={setValid}
                />
                <Button
                  variant={!valid ? "disabled" : "primary"}
                  type="submit"
                  outline="green"
                  className="w-full h-[50px]"
                  loading={loadingPlaceDetails}
                >
                  Shop Now
                </Button>
              </form>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

DeliveryAvailabilty.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default DeliveryAvailabilty;

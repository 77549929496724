import React from "react";
import {
  DriversAuthLayout,
  DriversSignup,
  DriversSignupSuccess,
} from "../components";

const DriversSignupPage = () => {
  return (
    <DriversAuthLayout>
      <DriversSignup />
    </DriversAuthLayout>
  );
};

export default DriversSignupPage;

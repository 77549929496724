import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import {
  AfroshopLogo,
  Sms,
  eyeSlash,
  googleLogo,
  lock,
  guest,
} from "../../../images";

import { Button } from "../../shared";
import InputComponent from "../../shared/inputComponent";
import ConnectButton from "../../../googleLoginButton";
import { useForm } from "react-hook-form";
import {
  activeModal,
  guestLogOut,
  userLogin,
  updateUser,
  postRequest,
} from "../../../redux/action";
import { handleBackdropClick } from "../../../helpers/backdropClick";
import { AFROPOT_TOKEN, expirationDate } from "../../../utils/constants";
import PasswordCriteria from "../../shared/password-criteria/password-criteria";
import { PasswordStrengthCheck } from "../../../utils";
import useToast from "../../toast/useToast";

function AuthScreen({ auth }) {
  const [showPassword, setShowPassword] = useState();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [refUserCode, setUserRefCode] = useState(null);
  const loginGuest = useSelector((state) => state.loginGuest);
  const getActiveModalName = useSelector((state) => state.getActiveModalName);

  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const showToast = useToast();


  const hasRefSegment = location.pathname.includes("/ref");
  const ref = refUserCode || Cookies.get("refCode");

  function handleShowPassword() {
    if (!showPassword) setShowPassword(true);
    else setShowPassword(false);
  }

  const closeModal = () => {
    dispatch(activeModal(""));
  };

  useEffect(() => {
    if (hasRefSegment) {
      const urlPath = location.pathname.split("/");
      const lastUrlString = urlPath[urlPath.length - 1];

      const refCode = lastUrlString.slice(-6);
      setUserRefCode(refCode);
    }
  }, [hasRefSegment]);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isDirty },
    watch,
  } = useForm({ mode: "all", defaultValues: { email: "", password: "" } });

  // Watch for changes in the password field
  const watchPassword = watch("password");

  const handleUserLogin = async (value) => {
    if (loginGuest) {
      navigate("/confirm-order");
    }
    setLoading(true);
    try {
      const [success, responseData] = await postRequest("/api/auth/local", {
        identifier: value.email,
        password: value.password,
      });
      if (!success || responseData?.error) {
        console.error(responseData?.error?.message);
        showToast('error',
          `${
            responseData?.error?.message || "An Error occured while logging in"
          }`,
           'top-right', 1000);
      } else {
        reset();
        dispatch(userLogin(responseData?.user));
        Cookies.set(AFROPOT_TOKEN, responseData?.jwt, {
          expires: expirationDate,
        });
        dispatch(activeModal(""));
        if (!loginGuest) {
          navigate("/");
        }
      }
    } catch (error) {
      showToast('error',"An error occured while logging in", 'top-right', 1000);
    } finally {
      setLoading(false);
    }
  };

  const handleGuestNavigation = () => {
    dispatch(guestLogOut());
    dispatch(activeModal(""));
    navigate("/confirm-order");
  };

  const handleHomeNavigation = () => {
    dispatch(activeModal(""));
    navigate("/");
  };

  const sendUserOtp = async (value) => {
    setLoading(true);
    try {
      const [success, response] = await postRequest("/api/otps", {
        email: value.email,
      });

      if (!success || response?.error) {
        console.error(response.error.message);
      showToast('error',
        `${
            response?.error?.message ||
            "An error occured while sending your otp"
          }`, 
        'top-right', 1000);
      } else {
        showToast('success', 'otp sent to your email adress', 'top-right', 1000);
        dispatch(
          updateUser({
            username: value.email,
            email: value.email,
            password: value.password,
            ...(ref && { referralCode: ref }),
          })
        );
        dispatch(activeModal("emailVerify"));
        if (ref) {
          navigate("/", { replace: true });
        }
      }
    } catch (error) {
      showToast('error', "An error occured while sending your otp", 'top-right', 1000);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    const value = getValues();
    if (auth) {
      sendUserOtp(value);
    } else {
      handleUserLogin(value);
    }
  };
  const { criteriaCount, passwordStrength } = PasswordStrengthCheck(password);

  return (
    <div>
      <div
        className={`fixed inset-0 bg-blackTransparent bg-opacity-10 z-50 ${
          getActiveModalName?.length > 0 ? "flex" : "hidden"
        } justify-center items-center z-[200]`}
        onClick={(e) => handleBackdropClick(e, closeModal)}
      >
        <div className="md:w-[585px] w-[98%] md:py-[32px] py-7 px-[24px] bg-[#FFFFFF] rounded-lg md:h-screen overflow-y-auto z-[2000]">
          <div className="h-fit flex flex-col justify-center items-center gap-6">
            <AfroshopLogo
              onClick={handleHomeNavigation}
              className="cursor-pointer"
            />
            <div className="w-[342px] flex flex-col justify-center items-center">
              <h5 className="text-[#333333] text-[16px] md:text-[20px] font-bold mt-5 mb-1">
                {auth ? "Sign Up" : "Log In"}
              </h5>
              <span className="text-[#CCCCCC] text-[13px] md:text-[16px]">
                {auth
                  ? "Register to shop with us"
                  : "Log In to shop with us"}
              </span>
            </div>

            <ConnectButton provider="google" />

            {loginGuest && (
              <button
                onClick={handleGuestNavigation}
                className="md:w-[400px] w-[98%] flex flex-row justify-center items-center gap-2 pl-2 py-2 border border-[#CCCCCC] border-solid text-[#333333] text-base rounded lg:flex md:flex"
              >
                <img src={guest} alt="guest" />
                <span className="text-[16px] text-[#333333] font-normal">
                  Checkout as a Guest
                </span>
              </button>
            )}

            <span className="text-[#CCCCCC] text-[13px] md:text-[16px]">
              or
            </span>

            <form
              className="flex flex-col gap-2 w-full justify-center items-center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="md:w-[400px] w-full mt-2 flex flex-col gap-2">
                <label className="text-[#B3B3B3] text-xs font-normal">
                  Email
                </label>

                <InputComponent
                  type="email"
                  placeholder="Enter email"
                  fieldName="email"
                  requiredMessage="Enter valid email"
                  patternValue={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/}
                  patternMessage="Invalid email address"
                  compoundValidation={true}
                  register={register}
                  errors={errors}
                  leftIcon={Sms}
                />
              </div>

              <div className="md:w-[400px] w-full mt-2 flex flex-col gap-2">
                <label className="text-[#B3B3B3] text-xs font-normal">
                  Password
                </label>

                <InputComponent
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  fieldName="password"
                  requiredMessage="Enter valid password"
                  // patternValue={/^[A-Za-z]\w{7,14}$/}
                  compoundValidation={true}
                  register={register}
                  errors={errors}
                  leftIcon={lock}
                  rightIcon={eyeSlash}
                  onIconClick={handleShowPassword}
                  handleChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  iconClassName="cursor-pointer"
                  onFocus={() => (auth ? setIsPasswordFocused(true) : {})}
                  onBlur={() => (auth ? setIsPasswordFocused(false) : {})}
                />

                {!auth && (
                  <p
                    className="text-right text-[10px] font-normal cursor-pointer w-[fit-content] self-end"
                    onClick={() => dispatch(activeModal("reset"))}
                  >
                    Forgot Password?
                  </p>
                )}
              </div>

              {auth && isPasswordFocused && watchPassword && (
                <div className="w-[98%] md:w-[80%] md:py-[32px] py-7 px-[24px]">
                <PasswordCriteria
                  criteriaCount={criteriaCount}
                  passwordStrength={passwordStrength}
                />
                </div>
              )}
              <Button
                className="mt-3 md:w-[400px] w-[86vw]"
                icon="white"
                type="submit"
                loading={loading}
                disabled={ auth ? criteriaCount < 3 : !isDirty}
              >
                {auth ? "Sign Up" : "Log In"}
              </Button>
            </form>

            <div>
              <span className="text-[#CCCCCC] text-[13px] md:text-[16px] font-normal">
                {auth ? "Already have an account?" : "Don’t have an account?"}{" "}
                <button
                  className="text-[#186F3D] underline"
                  loading={loading}
                  onClick={() => {
                    auth
                      ? dispatch(activeModal("login"))
                      : dispatch(activeModal("signUp"));
                  }}
                >
                  {auth ? "Log In" : "Sign Up"}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthScreen;

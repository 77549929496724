import React from "react";
import { AfroShopBusinessHeroImage, ArrowLeft } from "../../images";
import { useForm } from "react-hook-form";
import { Button } from "../shared";
import SignupForm from "./sign-up-steps/sign-up-form";
import SetupPassword from "./sign-up-steps/setup-password";
import ReceivedSubmission from "./sign-up-steps/submission-received";
import { useNavigate } from "react-router-dom";
import useSignUp from "./useSignUp";

const HeroSection = () => {
  const navigate = useNavigate();
  const form = useForm({ mode: "all", defaultValues: {} });

  const {onSubmit, loading, step, setStep} = useSignUp();

  return (
    <div className="lg:mb-[600px] xl:mb-[500px] 2xl:mb-[400px] mb-0">
      <div className="lg:hidden flex flex-col relative">
        <div class="relative w-full h-[500px]">
          {" "}
          <img
            src={AfroShopBusinessHeroImage}
            alt=""
            className="w-full inset-0 lg:hidden h-full w-auto object-cover"
          />
          <div class="absolute inset-0 bg-black opacity-40"></div>
        </div>

        <div className="flex flex-col gap-4 absolute bottom-0 p-6 sm:p-4">
          <h1 className="md:text-[45px] text-[31px] text-white sm:leading-[45px] font-bold">
            Grow your business online with Afroshop
          </h1>
          <p className="text-white leading-6 text-base">
            List Your Store Today and Reach Millions of Customers
          </p>
          <Button
            type="submit"
            size={"full"}
            onClick={() => {
              navigate("/business/signup");
            }}
          >
            List My Store
          </Button>
        </div>
      </div>
      <div className="hidden lg:flex justify-center lg:h-[700px] xl:h-[900px] 2xl:h-[1000px]">
        {/* Background image and text */}
        <div className="relative w-full h-full bg-cover bg-center items-center max-h-[899px] max-w-[1300px] flex">
          {/* Dark overlay */}
          <img
            src={AfroShopBusinessHeroImage}
            alt=""
            className="absolute h-full w-full inset-0 mx-auto rounded-[50px]"
          />

          {/* Text overlay */}
          <div className="absolute z-1 left-0 text-white 2xl:max-w-[607px] xl:max-w-[557px] lg:max-w-[400px] px-6 mt-9">
            <h1 className="xl:text-6xl lg:text-5xl font-bold mb-4 leading-[72px]">
              Grow your business online with Afroshop
            </h1>
            <p className="xl:text-2xl lg:text-base">
              List Your Store Today and Reach Millions of Customers
            </p>
          </div>
        </div>

        {/* Form */}
        <div className="absolute bg-white p-8 right-24 lg:top-[500px] xl:top-[700px] xl:top-[800px] rounded-lg shadow-lg max-w-[585px] w-full z-5">
          {step < 3 && (
            <h2 className="text-[20px] text-[#186F3D] font-bold mb-6">
              List your store and get started
            </h2>
          )}
          {step === 2 && (
            <div
              className="flex items-center absolute top-[20px] right-0 pr-8"
              onClick={() => setStep((prev) => prev - 1)}
            >
              <ArrowLeft
                className="mr-2 cursor-pointer text-sm"
                alt="arrow-left"
              />
              <p
                aria-label="Back"
                className="text-sm font-normal text-[#333333] cursor-pointer"
              >
                Go Back
              </p>
            </div>
          )}

          <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
            {step === 1 && <SignupForm form={form} loading={loading} />}
            {/* {step === 2 && <SetupPassword form={form} loading={loading} />} */}
            {step === 3 && <ReceivedSubmission />}
          </form>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

import React, { useState } from "react";
import { AfroshopDriversLogo, CloseIcon, Menu } from "../../images";
import { Button } from "../shared";
import { useNavigate } from "react-router-dom";


const menuItems = [
  { label: "Benefits", href: "#benefits" },
  { label: "How to Apply", href: "#apply" },
  { label: "Testimonials", href: "#testimonials" },
  { label: "FAQs", href: "#faqs" },
];


const NavLink = ({ label, href, onClick }) => (
  <li className="py-2 px-[20px]">
    <a href={href} onClick={onClick} className="cursor-pointer">
      {label}
    </a>
  </li>
);


const MobileMenu = ({ onClose, navigate }) => (
  <div className="bg-[#FFFFFF] w-full h-[100vh] absolute left-0 right-0 z-10 py-[32px] px-[24px] lg:hidden block">
    <CloseIcon alt="Close" className="absolute right-[24px] cursor-pointer" onClick={onClose} />
    <ul className="w-full flex flex-col gap-4 items-center text-[16px] leading-[24px] text-[#1E1E1E] mt-4">
      {menuItems.map((item) => (
        <NavLink key={item.href} label={item.label} href={item.href} onClick={onClose} />
      ))}
      <li className="w-full">
        <Button className="h-40px w-full" variant="primary" onClick={() => navigate("/signup")}>
          Sign Up
        </Button>
      </li>
    </ul>
  </div>
);

const DriversNav = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMobileNav = () => setMobileNavOpen(!mobileNavOpen);

  return (
    <nav className="sm:py-[24px] sm:px-[70px] py-[12px] px-[24px] sm:h-[92px] w-full h-[68px] flex justify-between fixed bg-white z-[10] shadow">
      <a href="/drivers">
        <img src={AfroshopDriversLogo} alt="drivers" />
      </a>

      <ul className="hidden lg:flex gap-[40px] items-center text-[13px] leading-[23px] text-[#333333]">
        {menuItems.map((item) => (
          <li key={item.href}>
            <a href={item.href}>{item.label}</a>
          </li>
        ))}
        <li>
          <Button className="md:flex lg:mr-8 md:mr-4 h-[40px]" variant="primary" onClick={() => navigate("/signup")}>
            Sign Up
          </Button>
        </li>
      </ul>

      <Menu alt="menu" className="lg:hidden block cursor-pointer self-center" onClick={toggleMobileNav} />

      {mobileNavOpen && <MobileMenu onClose={toggleMobileNav} navigate={navigate} />}
    </nav>
  );
};

export default DriversNav;

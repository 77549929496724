import React, { useState } from "react";
import { ExpandedFilterIcon, ExpandFilterIcon } from "../../../images";

const Item = ({ title, description }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div className="flex flex-col justify-between gap-[10px]">
      <div
        onClick={() => { setExpanded((expanded) => (!expanded)) }}
        className="flex justify-between gap-[15px] cursor-pointer"
      >
        <h2 className="text-[16px] lg:text-[20px] text-[#333333] font-bold">
          {title}
        </h2>
        {
          expanded ?
          <div>
            <ExpandedFilterIcon />
          </div>
            :
            <div>
            <ExpandFilterIcon />
          </div>
        }
      </div>
      {
        expanded &&
        <p className="text-[#333333]">
          {description}
        </p>
      }
      <hr />
    </div>
  )
}
const FAQ = () => {
  const data = [
    {
      title: "What types of African groceries can I find on Afroshop?",
      description: "Afroshop offers a wide range of African groceries, including popular spices like curry and ginger, staple grains like rice and millet, traditional flours such as cassava and sorghum, essential condiments, snacks like plantain chips, beverages including hibiscus tea, and much more. Whether you need ingredients for everyday meals or rare items from specific African regions, Afroshop has it all."
    },
    {
      title: "What are the delivery options available on Afroshop?",
      description: "Afroshop offers a variety of delivery options to cater to different needs. You can choose from standard shipping, express delivery for faster service, and even same-day delivery for select locations. We also offer scheduled deliveries so you can select a convenient time for your order to arrive. Shipping costs vary based on location and the size of the order."
    },
    {
      title: "How long does it take for my order to be delivered?",
      description: "Delivery times on Afroshop depend on your location and the delivery option you select. Standard delivery usually takes 3-5 business days, while express delivery can get your order to you within 1-2 business days. For same-day deliveries, orders must be placed before a certain cut-off time. You can always track your order for real-time updates on its delivery status."
    },
    {
      title: "What if I have an issue with my order or need assistance?",
      description: "If you have any issues with your order, whether it’s an item missing, damaged, or incorrect, our customer service team is here to help. You can contact us through email or our support chat, and we’ll assist you in resolving the issue as quickly as possible. We’re committed to making sure you have a smooth and satisfactory shopping experience on Afroshop."
    },
    {
      title: "Do you offer returns or refunds?",
      description: "Yes, Afroshop offers a return and refund policy for certain products. If you're not satisfied with your purchase, you can return it within 14 days of receiving your order. Refunds are issued once we receive the returned item in its original condition. Some items, such as perishable goods or opened products, may not be eligible for return. Please refer to our full return policy for detailed information."
    },
    {
      title: "Can I track my order after it's been placed?",
      description: "Absolutely! Once your order has been processed and shipped, you'll receive a tracking number that you can use to follow your order's progress online. You can check the status of your delivery through our website or the shipping carrier's site. This way, you’ll always know exactly when your order will arrive."
    }
  ]

  return (
    <section className="w-full max-w-[400px] lg:max-w-[700px] flex flex-col justify-center items-center gap-[40px] mx-auto pb-[70px] px-[24px] xl:px-[0px] lg:max-w-[1300px]">
      <h1 className="text-[20px] lg:text-[39px] text-[#186F3D] lg:max-w-[681px] font-extrabold leading-tight text-center underline decoration-transparent inline-flex flex-col">
        Frequently Asked Questions
      </h1>
      <div className="w-full flex flex-col justify-between flex-wrap gap-[16px] xl:gap-[40px]">
        {
          data.map((item, index) => (
            <Item key={index} title={item.title} description={item.description} />
          ))
        }
      </div>
    </section >
  );
};

export default FAQ;

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OutSideClick } from "../../helpers";
import Cookies from "js-cookie";
import { AfroshopLogo, Cart, Menu, Location, AvatarIcon } from "../../images";
import {
  activeModal,
  clearDisabledinCart,
  setUserLocation,
} from "../../redux/action";
import { Button } from "../shared";
import CartCheckout from "../cart-with-products";
import { getCartSize } from "../../helpers/cartFunctions";
import { LOCATION } from "../../data/menuItems";
import LocationForm from "../location-form";
import { SELECTED_LOCATION } from "../../utils/constants";
import MobileNavbar from "./mobile-nav";

const Navbar = () => {
  const [cartSize, setCartSize] = useState(0);
  const [open, setOpen] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [openLocationDropdown, setOpenLocationDropdown] = useState(false);
  const locationRef = useRef(null);
  const locationClickOutside = OutSideClick(locationRef);
  const cart = useSelector((state) => state.cart);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const getActiveModalName = useSelector((state) => state.getActiveModalName);
  const locationValue = useSelector((state) => state.location.display_name);

  const [location, setLocation] = useState(
    Cookies.get(SELECTED_LOCATION) || locationValue
  );
  const [openLocationForm, setOpenLocationForm] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showCity = LOCATION.find((data) => data.value === location);
  const handleShowCart = () => {
    // setShowCart(true);
    navigate(`/early-access`)
  }


  const handleHideCart = () => {
    document
      .querySelector(".cart-checkout-container")
      .classList.add("slide-out");

    setTimeout(() => {
      setShowCart(false);
    }, 100);

    dispatch(clearDisabledinCart(cart));
  };

  useEffect(() => {
    if (locationClickOutside) {
      setOpenLocationDropdown(false);
    }
  }, [locationClickOutside]);

  useEffect(() => {
    setCartSize(getCartSize(cart));
  }, [cart]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (!event.target.closest(".cart-checkout-container")) {
        setShowCart(false);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const handleLoginClick = () => {
    // dispatch(activeModal("login"));
    // setOpen(false);
    navigate(`/early-access`)
  };

  const handlesignUp = () => {
    // dispatch(activeModal("signUp"));
    // setOpen(false)
    navigate(`/early-access`)
  };

  const handleProfile = () => {
    // if (getActiveModalName === "loginProfile") {
    //   dispatch(activeModal(""));
    // } else {
    //   dispatch(activeModal("loginProfile"));
    // }
    navigate(`/early-access`)
  };

  const handleLocationClick = (location) => {
    dispatch(setUserLocation({ location: location }));
    setLocation(location);
    Cookies.set(SELECTED_LOCATION, location, { expires: 365 });
  };

  useEffect(() => {
    setLocation(locationValue);
  }, [locationValue]);

  const handleMenuClick = () => {
    setOpen((open) => !open);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 766) {
        if (getActiveModalName === "loginProfile") {
          dispatch(activeModal(""));
          setOpen(true);
        }
        if (openLocationDropdown) {
          dispatch(activeModal("deliveryAvailability"))
        }
      } else {
        if (open) {
          dispatch(activeModal("loginProfile"));
          setOpen(false);
        }
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getActiveModalName, openLocationDropdown]);

  return (
    <div className="sticky top-0 bg-white z-[10] shadow">
      {showCart && (
        <div className="fixed inset-0 flex justify-end bg-blackTransparent bg-opacity-10 z-50 ">
          <div className="overflow-y-scroll cart-checkout-this">
            <div className="cart-checkout-container slide-in">
              <CartCheckout className="" handleHideCart={handleHideCart} />
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-row justify-between content-start items-center py-3 lg:py-6 lg:px-16 px-6 text-[13px] text-[#333333] leading-6 font-normal bg-[#FFFFFF]">
        <div>
          <Link to="/">
            <AfroshopLogo />
          </Link>
        </div>
        <div className="flex flex-row justify-end items-center w-full">
          {isAuthenticated ? (
            <>
              <div className="relative mr-8" onClick={handleShowCart}>
                <Cart className="cursor-pointer" />
                {cartSize !== 0 && (
                  <span className="absolute bottom-3 left-3 text-white bg-[#186F3D] inline-flex items-center justify-center w-5 h-5 rounded-full">
                    {cartSize}
                  </span>
                )}
              </div>
              <AvatarIcon
                className="hidden lg:flex cursor-pointer mr-8"
                onClick={handleProfile}
              />
              {location && (
                <div
                  className="relative h-min max-w-[150px] cursor-pointer hidden lg:flex flex-col "
                  ref={locationRef}
                  onClick={() => dispatch(activeModal("deliveryAvailability"))}
                >
                  <div className=" hidden lg:flex items-center gap-1">
                    <Location />
                    <p className="text-[#186F3D] items-center text-center">
                      {location}
                    </p>
                  </div>
                  <p className="hidden lg:flex text-[10px] text-[#007AFF] text-center underline underline-offset-1">
                    Change Location
                  </p>

                  {/* {openLocationDropdown && (
                    <ul className="bg-white absolute top-full right-0 min-w-[327px] w-full shadow-lg rounded z-[10] max-h-[192px] overflow-auto custom-scrollbar">
                      {LOCATION.map((el) => (
                        <li
                          key={el.id}
                          className="text-base border-b last:border-none py-2.5 px-3 border-[#fafafa] bg-white rounded cursor-pointer hover:bg-[#F2F2F2] hover:text-[#186F3D]"
                          onClick={() => {
                            handleLocationClick(el.value);
                            setOpenLocationDropdown(false);
                          }}
                        >
                          {el.value}
                        </li>
                      ))}
                    </ul>
                  )} */}
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-row justify-end items-center w-full">
              <a
                className="hidden lg:flex min-w-fit lg:mr-8 md:mr-4 cursor-pointer"
                href="/business"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sell on Afroshop
              </a>
              {/* <a
                className="hidden lg:flex min-w-fit lg:mr-8 md:mr-4 cursor-pointer"
                href="/drivers"
                target="_blank"
                rel="noopener noreferrer"
              >
                Deliver with Afroshop
              </a> */}
              <p
                className="hidden lg:flex cursor-pointer min-w-fit lg:mr-8 md:mr-4"
                onClick={handleLoginClick}
              >
                Log in
              </p>
              <Button
                className="hidden lg:mr-8 md:mr-4"
                onClick={handlesignUp}
              >
                Sign Up
              </Button>
              <div
                className="relative cursor-pointer mr-8"
                onClick={handleShowCart}
              >
                <Cart />
                {cartSize !== 0 && (
                  <span className="absolute bottom-3 left-3 text-white bg-[#186F3D] inline-flex items-center justify-center w-5 h-5 rounded-full">
                    {cartSize}
                  </span>
                )}
              </div>
              {location && (
                <div
                  className="relative h-min cursor-pointer hidden lg:flex flex-col"
                  ref={locationRef}
                  onClick={() => {
                    dispatch(activeModal("deliveryAvailability"));
                  }}
                >
                  <div className="hidden lg:flex items-center gap-1">
                    <Location />
                    <p className="text-[#186F3D] items-center text-center">
                      {showCity ? `${location}, CA` : location}
                    </p>
                  </div>
                  <p className="hidden lg:flex text-[10px] text-[#007AFF] leading-[15px] text-center underline underline-offset-1">
                    Change Location
                  </p>

                  {/* {openLocationDropdown && (
                    <ul className="bg-white absolute top-full right-0 min-w-[327px] w-full shadow-lg rounded z-[10] max-h-[192px] overflow-auto">
                      {LOCATION.map((el) => (
                        <li
                          key={el.id}
                          className="text-base border-b last:border-none py-2.5 px-3 border-[#fafafa] bg-white rounded cursor-pointer hover:bg-[#F2F2F2] hover:text-[#186F3D]"
                          onClick={() => {
                            handleLocationClick(el.value);
                            setOpenLocationDropdown(false);
                          }}
                        >
                          {el.value}
                        </li>
                      ))}
                    </ul>
                  )} */}
                </div>
              )}
            </div>
          )}

          {/* For Mobile */}
          <Menu
            className="flex lg:hidden cursor-pointer ml-3"
            onClick={handleMenuClick}
          />
          {open && (
            <MobileNavbar
              open={open}
              setOpen={setOpen}
              location={location}
              openLocationForm={openLocationForm}
              setOpenLocationForm={setOpenLocationForm}
            />
          )}
          {/* {openLocationForm && (
            <LocationForm
              location={location}
              handleLocationClick={handleLocationClick}
              setOpenLocationForm={setOpenLocationForm}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

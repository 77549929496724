import React, { useCallback, useEffect, useRef, useState } from "react";
import InputComponent from ".";
import { LoadingAnimation } from "../../../images";
import styled from "styled-components";
import { handlePlacesApiSuggestions } from "../../../utils";

export const StyledList = styled.ul`
  box-shadow: 0 8px 16px 0 rgba(51, 51, 51, 0.12);
  border-radius: 4px;
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin: 0;
  display: ${(props) => (props.open ? "block" : "none")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  transition: display 0.3s, opacity 0.3s;
  height: 100%;
  max-height: 150px;
  overflow-y: auto;
`;

export const StyledListItem = styled.li`
  padding: 20px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
    color: #186f3d;
  }
`;
/**
 * @description Place Api Input Component
 *
 * */

const PlaceInputComponent = ({
  inputRef,
  register,
  errors,
  city,
  setCity,
  handleSelectChange,
  sessionToken,
  setSessionToken,
}) => {
  const [loadingPlaces, setLoadingPlaces] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [noSuggestions, setNoSuggestionsResponse] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const optionRefs = useRef([]);

  const handleCityChange = (event, data, bool) => {
    handleSelectChange(event, data); //a props function called on click of an option
    setIsOpen(bool);
    setHighlightedIndex(-1);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      //assigns highlighted option when the enter key is clicked
      event.preventDefault();
      if (highlightedIndex >= 0 && highlightedIndex < suggestions?.length) {
        handleSelectChange(event,  suggestions[highlightedIndex]);
        setIsOpen(false);
        setHighlightedIndex(-1);
      }
    } else if (event.key === "ArrowDown") {
      //activates scrolling of options when using the arrow down key on your keyboard
      event.preventDefault();
      setHighlightedIndex((prevIndex) => {
        const newIndex = Math.min(prevIndex + 1, suggestions.length - 1);
        scrollToOption(newIndex);
        return newIndex;
      });
      setIsOpen(true);
    } else if (event.key === "ArrowUp") {
      //activates scrolling of options when using the arrow up key on your keyboard
      event.preventDefault();
      setHighlightedIndex((prevIndex) => {
        const newIndex = Math.max(prevIndex - 1, 0);
        scrollToOption(newIndex);
        return newIndex;
      });
      setIsOpen(true);
    } else if (event.key === "Tab") {
      //assigns autocomplete word to the input when tab is clicked
      event.preventDefault();
      if (highlightedIndex >= 0 && highlightedIndex < suggestions?.length) {
       handleSelectChange(event, suggestions[highlightedIndex]);
       setHighlightedIndex(-1);
       setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    if (noSuggestions) {
      //closes the dropdown after 3 seconds of displaying "No results found"
      const timer = setTimeout(() => {
        setNoSuggestionsResponse(undefined);
        setSuggestions();
        setIsOpen(false);
      }, 3000);

      // Cleanup the timer when the component unmounts or message changes
      return () => clearTimeout(timer);
    }
  }, [noSuggestions]);

  const scrollToOption = (index) => {
    //activates the scroll feature when the arrow up or arrow down key is clicked on your keyboard
    if (optionRefs.current[index]) {
      optionRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const filteredOptions = //filters options to display only options with the input included in the option text
    suggestions?.length >= 0 &&
    suggestions.filter((option) =>
      option?.value.toLowerCase().includes(city.toLowerCase())
    );

  const suggestion = //gets the exact suggestion based on the highlighted index
    highlightedIndex >= 0 && city && suggestions?.length >= 0
      ? filteredOptions[highlightedIndex]
      : "";

  const fetchSuggestions = useCallback(async (input) => {
    try {
      if (input?.length > 3) {
        await handlePlacesApiSuggestions(
          input,
          sessionToken,
          setLoadingPlaces,
          (result) => {
            const [success, responseData] = result;
            if (success && responseData && responseData?.status !== 400) {
              setSessionToken(responseData?.sessionToken);
              const places = responseData?.data?.map((place) => {
                return {
                  label: place?.description,
                  value: place?.description,
                  place_id: place?.place_id,
                };
              });
              setSuggestions(places);
            } else if (responseData?.status === 400 && responseData?.error) {
              setNoSuggestionsResponse("No results found");
            }
          }
        );
      } else {
        setIsOpen(false);
        setLoadingPlaces(false);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchSuggestions(city);
  }, [city, fetchSuggestions]); //fetch suggestions when city changes

  return (
    <div>
      <div className="relative w-full">
        <p className="text-left text-[#B3B3B3] text-[13px] py-2">City / Address</p>
        <InputComponent
          placeholder="Enter City"
          fieldName="City"
          ref={inputRef}
          handleChange={(e) => {
            setCity(e?.target?.value);
            if (e.target?.value?.length > 3) {
              setIsOpen(true);
            } else {
              setIsOpen(false);
            }
            setHighlightedIndex(0);
          }}
          keyDown={handleInputKeyDown}
          register={register}
          errors={errors}
          required={true}
          requiredMessage={"Enter city"}
          name={"City"}
          type={"text"}
          inputType={"text"}
        />
        {highlightedIndex >= 0 &&
          suggestions?.length >= 0 &&
          city?.length > 3 && (
            <div
              className={`absolute left-0 px-[21px] top-[6.5px] py-2 flex items-center text-gray overflow-hidden whitespace-nowrap text-ellipsis
                          opacity-[0.5] pointer-events-none ${
                            suggestion?.label?.length >
                            inputRef?.current?.clientWidth
                              ? `max-w-[${inputRef?.current?.clientWidth}]`
                              : "w-full"
                          } ${
                inputRef?.current?.scrollWidth > inputRef?.current?.clientWidth
                  ? "hidden"
                  : "block"
              }`}
            >
              {city}
              <span className="font-bold -z-50 overflow-hidden whitespace-nowrap text-ellipsis">
                {suggestion?.value.substring(city.length)}
              </span>
            </div>
          )}
      </div>
      <div className="relative">
        <div className="mb-4 text-start absolute bg-white rounded px-2 pt-2 w-full">
          <StyledList className="custom-scrollbar" open={isOpen}>
            {loadingPlaces ? (
              <div className="flex justify-center py-5">
                <LoadingAnimation color={"#186F3D"} />
              </div>
            ) : (
              suggestions &&
              !noSuggestions &&
              suggestions?.map((data, index) => {
                return (
                  <StyledListItem
                    ref={(el) => (optionRefs.current[index] = el)}
                    key={index}
                    className={`${
                      index === highlightedIndex
                        ? "bg-[#f1f1f1] text-[#186F3D]"
                        : ""
                    } `}
                    id={data?.value}
                    onMouseEnter={(e) => {
                      setHighlightedIndex(index);
                    }}
                    onClick={(e) => {
                      handleCityChange(e, data, false);
                    }}
                  >
                    {data?.value}
                  </StyledListItem>
                );
              })
            )}
            {noSuggestions && (
              <StyledListItem onClick={() => setIsOpen(false)}>
                {noSuggestions}
              </StyledListItem>
            )}
          </StyledList>
        </div>
      </div>
    </div>
  );
};

export default PlaceInputComponent;

const formatOpeningDays = (openDays, openingTimes) => {
  if (!openDays || openDays.length === 0) {
    return "Closed";
  }

  const currentTime = new Date();
  const openingTime = new Date();
  const closingTime = new Date();
  const [openingHours, openingMinutes] = openingTimes.from.split(':');
  const [closingHours, closingMinutes] = openingTimes.to.split(':');
  openingTime.setHours(openingHours, openingMinutes);
  closingTime.setHours(closingHours, closingMinutes);

  if (currentTime < openingTime || currentTime > closingTime) {
    return "Closed";
  }

  if (openDays.length === 1) {
    const singleDay = openDays[0].openDays.charAt(0).toUpperCase() + openDays[0].openDays.slice(1, 3);
    return `Open ${singleDay}`;
  }

  const firstDay = openDays[0].openDays.charAt(0).toUpperCase() + openDays[0].openDays.slice(1, 3);
  const lastDay = openDays[openDays.length - 1].openDays.charAt(0).toUpperCase() + openDays[openDays.length - 1].openDays.slice(1, 3);

  return `Open ${firstDay} - ${lastDay}`;
};

export default formatOpeningDays;
import React from "react";
import { Trash, WarningRed, WarningYellow } from "../../images";
import { handleCartOperation } from "../../redux/action";
import { useDispatch } from "react-redux";
import { formatNumber, renderValidUrl } from "../../utils/constants";
import { DefaultProductImage } from "../../images";

const CartItem = ({ storeID, data }) => {
  const {
    SKU,
    disabled,
    name,
    price,
    images,
    alt,
    pricingType,
    quantity,
    percentDiscount,
    percentMarkup,
    availability,
    unitWeightInGrams,
  } = data;
  const dispatch = useDispatch();
  const uniqueID = `${SKU}`;
  const handleCart = (operation, availability = 0) => {
    dispatch(
      handleCartOperation({ storeID, uniqueID, operation, availability })
    );
  };

  return (
    <div className="py-4">
      {disabled && (
        <div className="text-[#FF3B30] flex items-center gap-2 pb-3">
          <img src={WarningRed} alt="" className="w-[24px] h-[24px]" />
          <p>This item is no longer available. Please remove to continue.</p>
        </div>
      )}
      {parseInt(availability) > 0 && quantity > parseInt(availability) && (
        <div className="text-[#FF9500] flex items-center gap-2 pb-4">
          <img src={WarningYellow} alt="" className="w-[24px] h-[24px]" />
          <p>
            {`This store has only ${availability} ${pricingType.replace(
              /^per\s*/,
              ""
            )}${pricingType === "per Item" && parseInt(availability) > 1
              ? "s"
              : ""
              } available. Adjust the
            quantity or remove from the cart.`}
          </p>
          <span
            className="border border-[#FF9500] text-[#FF9500] px-3 rounded py-2 h-fit cursor-pointer ml-auto"
            onClick={() => {
              handleCart("ADJUST", parseInt(availability));
            }}
          >
            Adjust
          </span>
        </div>
      )}
      <div
        className={`flex items-center justify-between ${disabled || quantity > parseInt(availability) ? "opacity-50" : ""
          }`}
      >
        <div className="flex items-center">
          <img
            src={
              images?.length > 0
                ? renderValidUrl(images[0])
                : DefaultProductImage
            }
            className="mr-4 w-[50px] h-[54px] sm:w-[74px] sm:h-[84px]"
            alt={alt}
          />

          <div>
            <p className="font-semibold text-[10px] w-full leading-[15px] sm:leading-[24px] sm:text-[16px] whitespace-wrap">
              {name}
            </p>
            <p>
              {" "}
              {data.availability <= 5 && data.availability != 0 ? (
                <p className="text-[#FCAE17] text-[13px]">
                  {data.availability}{" "}
                  {`${data.pricingType.replace(/^per\s*/, "")}${data.pricingType === "per Item" &&
                    parseInt(data.availability) > 1
                    ? "s"
                    : ""
                    }`}{" "}
                  Left
                </p>
              ) : null}
              {data.availability == 0 ? (
                <p className="text-[#FF3B30] text-[13px]">Out of Stock</p>
              ) : null}
            </p>
            <p className="font-bold text-[#25945A] sm:mt-2">
              {data?.percentDiscount > 0 && (
                <>
                  <span className="text-[8px] leading-[9px] sm:text-[12px] sm:leading-[16px] text-[#999] line-through font-lexend font-normal">
                    $
                  </span>
                  <span className="text-[10px] leading-[20px] sm:text-[16px] sm:leading-[24px] text-[#999] line-through font-lexend font-normal">
                    {formatNumber(price * quantity * (1 + (percentMarkup ?? 0) / 100))}
                  </span>{" "}
                </>
              )}
              <span className="text-[10px] leading-[15px] sm:text-[13px] sm:leading-[23px] text-[#25945A]">
                $
              </span>
              <span className="text-[13px] leading-[23px] sm:text-[20px] sm:leading-[32px] text-[#186F3D]">
                {formatNumber(price * quantity * (1 + (percentMarkup ?? 0) / 100) * (1 - (percentDiscount ?? 0) / 100))}
              </span>
            </p>
          </div>
        </div>

        <div className="flex items-center justify-end w-[30%]">
          <button
            className="bg-green rounded text-white w-[32px] cursor-pointer h-[32px]"
            onClick={() => handleCart("DECREMENT")}
            disabled={disabled}
          >
            -
          </button>

          <p className="text-center w-[40%] text-[#333333]">{quantity}</p>
          <button
            disabled={disabled || quantity >= parseInt(availability)}
            className={`bg-green rounded text-white w-[32px] h-[32px] cursor-pointer ${quantity >= parseInt(availability) ? "bg-zinc-500" : ""
              }`}
            onClick={() => {
              if (quantity < parseInt(availability))
                return handleCart("INCREMENT");
            }}
          >
            +
          </button>
        </div>
      </div>

      <div className="flex justify-between text-[13px] leading-[23px] sm:text-[16px] sm:leading-[24px]">
        {Array.isArray(pricingType) ? (
          <select
            name="weight-measurement"
            className="font-normal text-[#4F4F4F]"
          >
            {pricingType.map((item, key) => {
              return (
                <option value={item} key={key}>
                  {item}
                </option>
              );
            })}
          </select>
        ) : pricingType === "per Item" ? (
          <p className="font-normal text-sm text-[#4F4F4F] py-2">
            {
              unitWeightInGrams > 1000
                ? `${unitWeightInGrams / 1000}kg`
                : unitWeightInGrams > 0
                  ? `${unitWeightInGrams}g`
                  : ""
            }
          </p>
        ) : (
          <p className="font-normal text-sm text-[#4F4F4F] py-2">
            {pricingType}
          </p>
        )}

        <div
          className="flex items-center text-[#FF3B30] font-normal cursor-pointer"
          onClick={() => handleCart("REMOVE")}
        >
          <Trash className="mr-1 w-[16px] h-[16px] sm:w-[24px] sm:h-[24px]" />
          <p>Remove</p>
        </div>
      </div>
    </div>
  );
};

export default CartItem;

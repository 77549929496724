import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from "../shared";
import CartItem from "../confirm-order/cartItem";
import { useDispatch, useSelector } from "react-redux";
import { getStoreCartSize, getStoreTotals } from "../../helpers/cartFunctions";
import { activeModal, guestLogin, changeCheckoutStore } from "../../redux/action";
import { ClockIcon, RightArrowIcon, BorderTop } from "../../images";
import { getName } from "../../helpers/getStoreName";
import { formatOpeningDays, convertTimeFormat } from "../../helpers";
import { renderValidUrl, formatNumber } from "../../utils/constants";

const StoreCart = ({ store, handleHideCart }) => {
  const cart = useSelector(state => state.cart)
  const isAuthenticated = useSelector(state => state.isAuthenticated)
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  // const [serviceFee, setFee] = useState(0)

  useEffect(() => {
    const totals = getStoreTotals(cart, store)

    setTotal(totals.total)
    setSubTotal(totals.subTotal)
    // setFee(totals.serviceFee)
    setQuantity(getStoreCartSize(cart, store));
  }, [cart, store]);

  const [expanded, setExpanded] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleCheckout = () => {
    handleHideCart();
    dispatch(changeCheckoutStore(store))
    navigate("/confirm-order");
  }

  const handleRedirect = (home = false) => {
    const redirectLink = home ? "/#store" : `/store/${getName(cart[store].name)}`;

    handleHideCart();
    navigate(redirectLink)
  }

  const handleAuthNavigation = () => {
    handleHideCart();
    dispatch(changeCheckoutStore(store))
    dispatch(guestLogin());
    dispatch(activeModal('login'));
  }

  if (!Object.keys(cart).includes(store)) {
    return null
  }

  // Format opening days
  const formattedOpeningDays = formatOpeningDays(cart[store]?.openDays, cart[store]?.openingTimes);

  return (
    <section className="flex flex-col gap-[12px] py-[32px] px-[24px] shadow-[0px_4px_8px_rgba(51,51,51,0.16)]">
      <div className="flex items-center lg:gap-[40px] gap-[24px]">
        <img
          src={renderValidUrl(cart[store]?.image)}
          alt=""
          className="lg:w-[100px] lg:h-[100px] w-[70px] h-[70px] rounded-[50%]"
        />
        <div className="flex-1">
          <div className="flex items-center justify-between">
            <div
              className={`rounded-full text-[10px] py-[2px] lg:py-[8px] px-[8px] lg:px-2.5 lg:px-4 ${formatOpeningDays(cart[store]?.openDays, cart[store]?.openingTimes) !== "Closed"
                ? "bg-[rgba(24,111,61,0.1)] text-[#186F3D]"
                : "bg-[rgba(255,59,48,0.1)] text-[#FF3B30]"
                }`}
            >
              {formatOpeningDays(cart[store]?.openDays, cart[store]?.openingTimes)}
            </div>


            <p className="text-[#B3B3B3] text-[10px] leading-[15px]">
              {`${quantity} Item${quantity > 1 ? 's' : ''}`}
            </p>
          </div>
          <h3 className="text-[16px] md:text-[18px] lg:text-[25px] text-[#333333] font-bold mt-[12px] mb-[2px]">
            {cart[store]?.name}
          </h3>
          <div className="flex items-center">
            {cart[store]?.isOpen && <ClockIcon className="mr-[0.3rem]" />}
            <p
              className={`rounded-full text-[10px] leading-[15px] ${cart[store]?.isOpen ? " text-[#186F3D]" : " text-[#B3B3B3]"
                }`}
            >
              {formattedOpeningDays === "Closed" ? "Not Delivering" :
                `Delivery from ${convertTimeFormat(cart[store]?.deliveryTime.from)} -
              ${convertTimeFormat(cart[store]?.deliveryTime.to)}`
              }
            </p>
          </div>
        </div>
      </div>

      <BorderTop className="my-[8px] w-[100%]" />
      {
        cart[store].disabled ?
          <p className="text-[#186F3D] leading-6">
            This store isn't available anymore on Afroshop. Shop from other stores <a className="underline cursor-pointer" onClick={() => handleRedirect(true)}>here.</a>
          </p>
          :
          <>
            <div className="flex justify-between align-center cursor-pointer" onClick={() => setExpanded(!expanded)}>
              <p className="text-lexend text-base font-normal leading-6 text-[#186F3D]">View {quantity} item{quantity > 1 ? 's' : ''} in cart</p>
              <img className={`h-[24px] ${expanded && 'rotate-90'}`} src={RightArrowIcon} alt="expand store in cart" />
            </div>

            {
              expanded &&
              <>
                {
                  Object.keys(cart[store].items).map((product, key) =>
                    <CartItem storeID={store} key={key} data={cart[store]?.items[product]} />
                  )
                }
                <BorderTop className="mt-6 w-[100%]" />

                {/* <div className="border-b border-[#E6E6E6] py-4 mb-4 sm:mb-8">
                  <div className="flex justify-between my-2">
                    <p className="font-normal text-[#4F4F4F] text-[13px] sm:text-[16px] leading-[24px]">
                      Sub-Total
                    </p>
                    <p className="font-semibold text-[#333333]">
                      <span className="text-[13px] leading-[23px]">$</span>
                      <span className="text-[16px] leading-[24px]">
                        {
                          formatNumber(subTotal)
                        }
                      </span>
                    </p>
                  </div> */}

                  {/* <div className="flex justify-between my-2">
                    <p className="font-normal text-[#4F4F4F] text-[13px] sm:text-[16px] leading-[24px]">
                      Service Fee
                    </p>
                    <p className="font-semibold text-[#333333]">
                      <span className="text-[13px] leading-[23px]">$</span>
                      <span className="text-[16px] leading-[24px]">
                        {
                          formatNumber(serviceFee)
                        }
                      </span>
                    </p>
                  </div> 
                </div>*/}

                <div className=" flex justify-between mb-4">
                  <p className="font-semibold text-[13px] sm:text-[16px] leading-[24px]">Total</p>
                  <p className="font-bold">
                    <span className="text-[13px] leading-[23px] text-[#25945A]">$</span>
                    <span className="text-[20px] leading-[32px] text-[#186F3D]">
                      {
                        formatNumber(subTotal)
                      }
                    </span>
                  </p>
                </div>
              </>
            }

            <Button
              type="button"
              variant="primary"
              size="full"
              outline="green"
              className="py-2"
              disabled={subTotal < 15}
              onClick={() => {
                if (isAuthenticated) {
                  handleCheckout();
                } else {
                  handleAuthNavigation();
                }
              }}
            >
               {subTotal < 15 ? "Minimum Checkout of $15" : `Checkout ($${formatNumber(subTotal)})`}
            </Button>

            <Button
              type="button"
              variant="tertiary"
              size="full"
              outline="green"
              className="py-2"
              onClick={() => {
                handleRedirect();
              }}
            >
              Continue Shopping
            </Button>
          </>
      }
    </section >
  )
}

export default StoreCart;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { postRequest, putRequest, userLogin } from "../../redux/action";
import useToast from "../toast/useToast";
import Cookies from "js-cookie";
import { AFROADMIN_TOKEN, expirationDate } from "../../utils/constants";
import { handleSetAddress } from "../../utils";

export default function useSignUp() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const showToast = useToast();

  const createStore = async (data) => {
    // const address = handleSetAddress(data.businessAddress);
    setLoading(true);
    try {
      const [success, responseData] = await postRequest(
        `/api/store-suggestions?user=owner`,
        {
          name: data.businessName,
          businessAddress: data.businessAddress,
          businessType: data.businessType,
          storeType: data.startType,
          address: data.businessAddress,
          countries: data.countries,
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          email: data.email,
        }
      );

      if (!success || responseData?.error) {
        throw new Error(
          responseData?.error?.message || "An error occurred, please try again"
        );
      }
      setStep(3)
    } catch (error) {
      showToast(
        "error",
        `${error?.message || "An error occured please try again"
        }`,
        "top-right",
        2000
      );
    } finally {
      setLoading(false);
    }
  };
  // update create user role to admin
  const updateUserRole = async (token, user) => {
    try {
      const [success, responseData] = await putRequest(
        `/api/users/${user.id}`,
        {
          role: 3,
        },
        token
      );
      if (!success || responseData?.error) {
        console.error(responseData.error.message);
        showToast(
          "error",
          `${responseData?.error?.message || "An error occured please try again"
          }`,
          "top-right",
          2000
        );
      }
    } catch (error) {
      showToast(
        "error",
        `${error?.message || "An error occured please try again"}`,
        "top-right",
        2000
      );
    }
  };

  const registerUser = async (data) => {
    setLoading(true);
    try {
      console.log(data.password && data.confirmPassword && data.password !== data.confirmPasswordata)
      if (data.password && data.confirmPassword && data.password !== data.confirmPassword) {
        showToast(
          "error",
          `${"Passwords do not match" || "An error occured while signing up"
          }`,
          "top-right",
          3000
        );
        return
      }

      const [success, responseData] = await postRequest(
        "/api/auth/local/register",
        {
          username: data.email,
          email: data.email,
          password: data.password,
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber
        }
      );

      if (!success || responseData?.error) {
        console.error(responseData.error.message);
        showToast(
          "error",
          `${responseData?.error?.message || "An error occured while signing up"
          }`,
          "top-right",
          3000
        );
      } else {
        await updateUserRole(responseData?.jwt, responseData?.user);
        await createStore(responseData?.jwt, responseData?.user, data);

        Cookies.set(AFROADMIN_TOKEN, responseData?.jwt, {
          expires: expirationDate,
        });
        setStep((prev) => prev + 1);
        showToast(
          "success",
          "Store owner successfully created!",
          "top-right",
          2000
        );
      }
    } catch (error) {
      showToast(
        "error",
        `${error || "An error occured while signing up"}`,
        "top-right",
        2000
      );
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    if (step === 3) {
      window.location.href = "https://afroshop-admin.vercel.app/login";
    }
    else {
      await createStore(data);
    }
  };

  return {
    updateUserRole,
    onSubmit,
    loading,
    step,
    setStep,
  };
}

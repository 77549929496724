import React, { useCallback, useState, useEffect } from "react";
import { Filter, InputIcon } from "../../../images";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getName } from "../../../helpers/getStoreName";
import StoreFilterPanel from "../../filterPanel/storeFilter";
import { regions } from "../../../data/storeRegions";
import { debounce } from "lodash";
import StoreCard from "../../cards/store";
import { activeModal } from "../../../redux/action";

const Stores = () => {
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stores = useSelector((state) => state.stores);
  const [storesData, setStoresData] = useState([]);
  const locationValue = useSelector((state) => state.location.display_name);

  useEffect(() => {
    if (Array.isArray(stores) && stores !== null) {
      setStoresData(stores);
    } else {
      setStoresData([]);
    }
  }, [stores]);

  const handleSearch = useCallback(
    debounce((query) => {
      const filteredStores = stores?.filter((store) =>
        store?.name.includes(query)
      );
      setStoresData(filteredStores);
    }, 500),
    [stores]
  );

  const handleFilterSearch = (searchValue) => {
    setSearchValue(searchValue);
    handleSearch(searchValue);
  };

  const handleModalOpen = () => {
    dispatch(activeModal("newStores"));
  };

  const handleIsMobile = (width) => width < 768;
  const [isMobile, setMobile] = useState(handleIsMobile(window.innerWidth));
  const handleResize = () => {
    setMobile(handleIsMobile(window.innerWidth));
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [openFilter, setOpenFilter] = useState(false);

  return (
    <div className="px-4 px-8 mb-4 w-full xl:max-w-[1300px] mx-auto" id="store">
      <div
        className={`flex flex-row gap-[8px] justify-between w-full ${isMobile ? "flex-col" : ""
          }`}
      >
        <h2 className="text-[#186F3D] font-bold text-base lg:text-[31px] leading-[45px]">
          Stores
        </h2>
        <div className="flex relative gap-2">
          <InputIcon className="absolute top-[10px] left-[20px]" />
          <form className="flex w-full rounded-sm" onSubmit={() => { }}>
            <input
              type="text"
              className={`bg-[#F2F2F2] w-[400px] rounded-sm h-[43px] 
              py-2 pl-14 focus:border-2 focus:border-[#186F3D] ${isMobile ? "w-full " : ""
                }`}
              placeholder="Search by a store name"
              value={searchValue}
              onChange={(e) => {
                handleFilterSearch(e.target.value);
              }}
            />
          </form>
          {isMobile && (
            <div
              className="flex flex-row items-center gap-1 cursor-pointer"
              onClick={() => setOpenFilter(!openFilter)}
            >
              <Filter />
              <span className="text-[#186F3D] lg:text-base text-sm flex whitespace-nowrap justify-center items-center text-center">
                Filter
              </span>
            </div>
          )}
        </div>
      </div>

      <StoreFilterPanel
        isMobile={isMobile}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        regions={regions}
        allStores={stores}
        storesData={storesData}
        setStoresData={setStoresData}
      />

      {storesData.length > 0 && stores.length > 0 && (
        <div className="text-[13px] border border-[#007AFF] bg-[#007AFF0D] text-gray-400 p-4 rounded mt-6">
          Here are all stores close to you{locationValue ? ` (${locationValue})` : ""}. Don’t see an
          Afro-Caribbean store close to you?{" "}
          <span
            onClick={handleModalOpen}
            className="text-[#007AFF] underline cursor-pointer"
          >
            Fill this form
          </span>{" "}
          and tell us about it.
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 min-[1440px]:gap-x-10 mt-8 cursor-pointer">
        {storesData?.map((card, index) => (
          <StoreCard
            card={card}
            key={index}
            onClick={() => navigate(`/early-access`, "_blank")}
            full
            cartIcon
          />
        ))}
      </div>

      {
        storesData?.length === 0 ? (
          stores?.length === 0 ? (
            <div className="h-[300px] bg-gray shadow w-full flex flex-col justify-center items-center text-center">
              <p className="text-[20px] leading-[32px] text-[#186F3D] font-bold my-3">
                We're sorry. We don't have any stores available here right now!
              </p>
              <p className="text-[#333333] w-[491px]">
                This is likely due to some maintenance being done and we will be back up soon. Thank you.
              </p>
            </div>
          ) : (
            <div className="bg-gray shadow w-full flex flex-col justify-center items-center text-center pb-4">
              <p className="text-[20px] leading-[32px] text-[#186F3D] font-bold mt-1 mb-1">
                We're Sorry.
              </p>
              <p className="text-[20px] leading-[32px] text-[#186F3D] font-bold mb-6">
                Can’t find your favorite Afro-Caribbean store? Help us bring it to Afroshop!
              </p>
              <p className="text-[#333333] lg:max-w-[90%]">
                We’re always expanding our store list to serve you better. If your favorite store isn’t here yet, let us know! Submit a quick suggestion below, and we’ll do our best to add it soon.
              </p>
              <span onClick={handleModalOpen} className="text-[#007AFF] underline cursor-pointer mt-2">
                Fill this form
              </span>{" "}
            </div>
          )
        ) : null
      }


    </div >
  );
};

export default Stores;

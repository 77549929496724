import React, { useState, useEffect, useLayoutEffect } from "react";
import { WhiteCancel } from "../../images";

const GreenBanner = ({ announcementBannerData }) => {
  const [open, setOpen] = useState(true);

  useLayoutEffect(() => {
    const isClosedForSession = sessionStorage.getItem("isClosedForSession");
    if (isClosedForSession === "true") {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [announcementBannerData, open]);

  // useEffect(() => {
  //   const isClosedForSession = sessionStorage.getItem("isClosedForSession");
  //   if (isClosedForSession === "true") setOpen(false);
  // }, []);

  const handleClose = () => {
    sessionStorage.setItem("isClosedForSession", "true");
    setOpen(!open);
  };

  return (
    <>
      {open && (
        <div className="flex flex-row justify-between items-center bg-[#186F3D] lg:px-16 px-6 py-3 text-white text-base cursor-pointer">
          <p>{announcementBannerData?.discountText}</p>
          <WhiteCancel onClick={handleClose} />
        </div>
      )}
    </>
  );
};

export default GreenBanner;

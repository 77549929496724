import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { subYears } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import InputComponent from "../../shared/inputComponent";
import { useForm, Controller } from "react-hook-form";
import {
  BlackEmailIcon,
  BlackPhone,
  BlackUserIcon,
  DateIconBlack,
} from "../../../images";
import { Button, RadioButton } from "../../shared";
import useInput from "../../../hooks/useInput";
import useToast from "../../toast/useToast";
import { postRequest } from "../../../redux/action";

const SignUp = () => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all", shouldFocusError: false });

  const selectedUploadMethod = watch("uploadMethod"); // Watch the uploadMethod field

  const phoneInput = useInput("", "number");

  const navigate = useNavigate();
  const showToast = useToast();

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      dateOfBirth: data.dateOfBirth.toISOString().split("T")[0],
      isManualUpload: data.uploadMethod === "manual" ? true : false,
    };
    localStorage.setItem("userEmail", data.email);
    try {
      const apiUrl = `/api/drivers`;
      const [success, response] = await postRequest(apiUrl, formData);
      if (success && response.status === 200) {
        showToast("success", response.message, "top-right", 10000);
        navigate("/signup-success");
      } else {
        showToast("error", response?.error?.message, "top-right", 10000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      showToast(
        "error",
        "An error occurred submitting your info",
        "top-right",
        5000
      );
    } finally {
      setLoading(false);
      reset();
      phoneInput.reset();
    }
  };

  return (
    <div>
      <h3 className="font-bold text-green text-[20px] lg:text-[31px] pb-[12px]">
        Drive with Afroshop
      </h3>
      <p className="text-[13px] lg:text-[16px] text-gray-400 pb-[40px]">
        Unlock Delivery Opportunities with AfroShop! <br /> Submit your details
        to be eligible to deliver African groceries with us.
      </p>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-[48px]"
      >
        <div className="flex flex-col lg:flex-row justify-between gap-[24px] w-full">
          <div>
            <label className="capitalize block text-[13px] leading-[23px] text-[#b3b3b3] font-normal mb-2">
              First Name
            </label>
            <InputComponent
              placeholder="Enter"
              type="text"
              leftIcon={BlackUserIcon}
              fieldName={"firstName"}
              required={true}
              requiredMessage={"This field is required"}
              register={register}
              errors={errors}
              className=""
            />
          </div>
          <div>
            <label className="capitalize block text-[13px] leading-[23px] text-[#b3b3b3] font-normal mb-2">
              Last Name
            </label>
            <InputComponent
              placeholder="Enter"
              type="text"
              leftIcon={BlackUserIcon}
              fieldName={"lastName"}
              required={true}
              requiredMessage={"This field is required"}
              register={register}
              errors={errors}
              className=""
            />
          </div>
        </div>

        <div>
          <label className="capitalize block text-[13px] leading-[23px] text-[#b3b3b3] font-normal mb-2">
            Email
          </label>
          <InputComponent
            placeholder="Enter"
            type="text"
            fieldName={"email"}
            leftIcon={BlackEmailIcon}
            required={true}
            requiredMessage={"This field is required"}
            register={register}
            errors={errors}
            patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
            patternMessage={`Enter Valid Email Address`}
            className=""
          />
        </div>

        <div>
          <label className="capitalize block text-[13px] leading-[23px] text-[#b3b3b3] font-normal mb-2">
            Phone
          </label>
          <InputComponent
            placeholder="Enter"
            type="text"
            fieldName={"phone"}
            leftIcon={BlackPhone}
            required={true}
            requiredMessage={"This field is required"}
            register={register}
            errors={errors}
            patternValue={/^[+]?\d{10,13}$/}
            patternMessage={`Enter Valid Phone number`}
            value={phoneInput.value}
            handleChange={(e) => phoneInput.handleInputChange(e)}
            className=""
          />
        </div>

        <div className="w-full">
          <label className="capitalize block text-[13px] leading-[23px] text-[#b3b3b3] font-normal mb-2">
            Date of Birth (18 years and above)
          </label>
          <div className="relative w-full">
            <Controller
              name="dateOfBirth"
              control={control}
              defaultValue={null}
              rules={{
                required: "Date field is required",
                validate: (value) => {
                  if (!value) return "Date of Birth is required";
                  const today = new Date();
                  const ageLimitDate = subYears(today, 18); // Calculate the minimum allowed date
                  if (value > ageLimitDate) {
                    return "You must be at least 18 years old";
                  }
                  return true;
                },
              }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  maxDate={subYears(new Date(), 18)} // User cannot select a future date
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  className="w-full bg-[#F2F2F2] h-[56px] py-[8px] px-[40px] border-none text-[#333333] focus:outline-none text-[16px] font-normal placeholder-[#999999]"
                />
              )}
            />
            {errors.dateOfBirth && (
              <p className="text-red-500 text-xs mt-1">
                {errors.dateOfBirth.message}
              </p>
            )}
            <img
              src={DateIconBlack}
              alt="Date Picker Icon"
              className="absolute top-4 left-2"
            />
          </div>
        </div>

        {/* Driver's Document Upload */}
        <div className="mb-4">
          <label className="capitalize block text-[13px] leading-[23px] text-gray-400 text-lg font-normal mb-2">
            Background check requirement :
          </label>
          <p className="text-sm text-gray-400 mb-4">
            To be eligible as a driver, you must provide a recent background
            check.
          </p>
          <p className="text-sm text-gray-400 mb-4">
            How would you like to proceed with your background check ?
          </p>
          <Controller
            name="uploadMethod"
            control={control}
            rules={{ required: "Please select an option" }}
            render={({ field }) => (
              <div className="space-y-2">
                <label className="flex items-center space-x-2">
                  <RadioButton
                    id="uploadMethod-partner"
                    name="uploadMethod"
                    value="partner"
                    checked={field.value === "partner"}
                    handleChange={() => field.onChange("partner")}
                  />
                  <span className="text-[#b3b3b3]">
                    <p className="text-sm text-gray-400">
                      ⁠I need to complete a background check through Afroshop's
                      partner service ( Attract $50 fee)
                    </p>
                  </span>
                </label>

                <label className="flex items-center space-x-2">
                  <RadioButton
                    id="uploadMethod-manual"
                    name="uploadMethod"
                    value="manual"
                    checked={field.value === "manual"}
                    handleChange={() => field.onChange("manual")}
                  />
                  <span className="text-[#b3b3b3]">
                    <p className="text-sm text-gray-400">
                      ⁠I have a recent background check ( completed in the last
                      3 months ) and will upload it.
                    </p>
                  </span>
                </label>
              </div>
            )}
          />

          {errors.uploadMethod && (
            <p className="text-red-500 text-xs mt-1">
              {errors.uploadMethod.message}
            </p>
          )}
        </div>

        <Button
          type="submit"
          size="full"
          variant={isValid ? "primary" : "disabled"}
          outline={isValid ? "green" : "grey"}
          loading={loading}
          className={`${loading ? "!bg-[#186F3D]" : ""}`}
          disabled={loading || !selectedUploadMethod} // Disable if no selection
        >
          Sign Up
        </Button>
      </form>
    </div>
  );
};

export default SignUp;

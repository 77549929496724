import React, { useState } from "react";
import { PrivacyLock, dropdownArrow, dropdownArrowUp } from "../../images";
import { Link, animateScroll as scroll } from "react-scroll";
import styled from "styled-components";

const pageSections = [
  {
    section: " What Information Do We Collect?",
    path: "#part1",
  },
  {
    section: "How Do We Process Our Information?",
    path: "#part2",
  },
  {
    section: "What Legal Bases Do We Rely On To Process Your Information?",
    path: "#part3",
  },
  {
    section: "When and With Whom Do We Share Your Personal Information?",
    path: "#part4",
  },
  {
    section: "Do We Use Cookies and Other Tracking Technologies?",
    path: "#part5",
  },
  {
    section: "How Do We Handle Your Social Logins?",
    path: "#part6",
  },
  {
    section: "How Long Do We Keep Your Information?",
    path: "#part7",
  },
  {
    section: "How Do We Keep Your Information Safe?",
    path: "#part8",
  },
  {
    section: "What Are Your Privacy Rights?",
    path: "#part9",
  },
  {
    section: "Controls For Do-Not-Track Features.",
    path: "#part10",
  },
  {
    section: "Do United States Residents Have Specific Privacy Rights?",
    path: "#part11",
  },
  {
    section: "Do Other Regions Have Specific Privacy Rights?",
    path: "#part12",
  },
  {
    section: "Do We Make Updates To This Notice?",
    path: "#part13",
  },
  {
    section: "How Can You Contact Us About This Notice?",
    path: "#part14",
  },
  {
    section:
      " How Can You Review, Update/Delete The Data We Collect From You?",
    path: "#part15",
  },
];

const StyledList = styled.ul`
  box-shadow: 0 8px 16px 0 rgba(51, 51, 51, 0.12);
  border-radius: 4px;
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin: 0;
  display: ${(props) => (props.open ? "block" : "none")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  transition: display 0.3s, opacity 0.3s;
  height: 150px;
  overflow-y: auto;
`;

const StyledListItem = styled.li`
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
    color: #186f3d;
  }

`;

const Placeholder = styled.div`
  padding: 0.75rem;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  border-radius: 0.25rem;
  cursor: pointer;
`;

const PrivacyPolicy = () => {
  // const [selected, setSelected] = useState("");
  const [selected, setSelected] = useState("Privacy Policy");
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setSelected(event.target.value);
    scroll.scrollTo(document.getElementById(event.target.value).offsetTop);
  };

  const handleClick = (option) => {
    setSelected(option);
    setOpen(false);
  };

  return (
    <div className="w-[100%]">
      <div>
        <div className="h-[356px] bg-[#ECFADC] flex flex-col relative justify-center items-center">
          <div className="absolute sm:top-[53px]">
            <img src={PrivacyLock} />
          </div>
          <div className="sm:pr-10 z-[2]">
            <h1 className="font-extrabold sm:text-[61px] text-center text-[31px] text-green">
              Privacy Policy
            </h1>

            <h3 className="text-center sm:text-[25px] text-[13px] font-bold">
              Last Updated: March 1, 2024
            </h3>
          </div>
        </div>
        <div className="flex gap-[36px] lg:p-[74px]" id="#part20">
          <div className="w-[268px] hidden lg:block h-[100vh] sticky top-0 overflow-y-auto privacy">
            <h5 className="text-[#003D1A] text-[16px] font-semibold pb-4 sticky top-0 z-50 bg-white">
              Privacy Policy
            </h5>
            <div className="flex flex-col gap-8 text-green ">
              <Link to="#part0" className="cursor-pointer" smooth={true}>
                <h5>Summary of Key Points</h5>
              </Link>
              <ol className="list-decimal list-inside flex flex-col gap-8 pl-2">
                {pageSections.map(({ section, path }, key) => {
                  return (
                    <li className="cursor-pointer" key={key}>
                      <Link to={path} smooth={true}>
                        {section}
                      </Link>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
          {/* </div> */}

          {/* mobile view drop-down */}
          <div className="w-[100%] lg:w-[80%] mt-5 relative ">
            <div>
              <div className="text-gray-400 mx-[24px] my-[36px] sticky top-0 z-50 bg-white lg:hidden block">
                <div className="w-[100%] py-4">
                  <div className="lg:hidden block relative">
                    <Placeholder onClick={() => setOpen(!open)}>
                    <span className="absolute right-[10px] top-[15px]"><img src={open ?  dropdownArrowUp : dropdownArrow } alt="drop-down"/></span>
                      {selected || "Please select an option"}
                    </Placeholder>

                    <StyledList open={open}>
                      <StyledListItem
                        onClick={() => handleClick("Privacy Policy")}
                      >
                        Privacy Policy
                      </StyledListItem>
                      <Link to="#part0" smooth={true}  offset={-250}>
                        <StyledListItem
                          onClick={() => handleClick("Summary Of Key Points")}
                        >
                          Summary Of Key Points
                        </StyledListItem>
                      </Link>
                      {pageSections.map(({ section, path }, key) => {
                        return (
                          <Link to={path} smooth={true} offset={-250}>
                            <StyledListItem
                              onClick={() =>
                                handleClick(`${key + 1}. ${section}`)
                              }
                            >
                              {key + 1}. {section}
                            </StyledListItem>
                          </Link>
                        );
                      })}
                    </StyledList>
                  </div>
                </div>
              </div>
              <div className="mx-6 lg:mx-0">
                <p className="pb-4">
                  This privacy notice for Afroshop ("we," "us," or "our"),
                  describes how and why we might collect,store, use, and/or
                  share
                  <strong>("process")</strong>
                  your information when you use our services
                  <strong>("Services")</strong>, such as when you:
                  <ul className="list-disc list-inside pl-5">
                    <li>
                      Visit our website at Afroshop.ca, or any website of ours
                      that links to this privacy notice
                    </li>
                    <li>
                      Engage with us in other related ways, including any sales,
                      marketing, or events
                    </li>
                  </ul>
                </p>
                <p>
                  Questions or concerns? Reading this privacy notice will help
                  you understand your privacy rights and choices. If you do not
                  agree with our policies and practices, please do not use our
                  Services. If you still have any questions or concerns, please
                  contact us at info@afroshop.ca
                </p>
              </div>

              <div id="#part0">
                <div className="mx-6 lg:mx-0 mt-4">
                  <h2 className="text-[20px] font-bold text-green">
                    Summary Of Key Points
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <div className="">
                    <p className="font-semibold pb-4">
                      This summary provides key points from our privacy notice,
                      but you can find out more details about any of these
                      topics by clicking the link following each key point or by
                      using our{" "}
                      <Link
                        className="underline text-[#007AFF] cursor-pointer"
                        to="#part20"
                        smooth={true}
                        offset={-250}
                      >
                        table of contents
                      </Link>{" "}
                      below to find the section you are looking for.
                    </p>

                    <p className="pb-4">
                      <strong>What personal information do we process?</strong>{" "}
                      When you visit, use, or navigate our Services, we may
                      process personal information depending on how you interact
                      with us and the Services, the choices you make, and the
                      products and features you use. Learn more about{" "}
                      <Link
                        className="underline text-[#007AFF] cursor-pointer"
                        to="#part1"
                        smooth={true}
                        
                      >
                        personal information you disclose to us
                      </Link>
                      .
                    </p>
                    <p className="pb-4">
                      <strong>
                        Do we process any sensitive personal information?
                      </strong>
                      We may process sensitive personal information when
                      necessary with your consent or as otherwise permitted by
                      applicable law. Learn more about{" "}
                      <Link
                        className="underline text-[#007AFF] cursor-pointer"
                        to="#part21"
                        smooth={true}
                        offset={-100}
                      >
                        sensitive information we process
                      </Link>
                      .
                    </p>
                    <p className="pb-4">
                      <strong>
                        Do we receive any information from third parties?
                      </strong>
                      We do not receive any information from third parties.
                    </p>
                    <p className="pb-4">
                      <strong>How do we process your information?</strong> We
                      process your information to provide, improve, and
                      administer our Services, communicate with you, for
                      security and fraud prevention, and to comply with law. We
                      may also process your information for other purposes with
                      your consent. We process your information only when we
                      have a valid legal reason to do so. Learn more about{" "}
                      <Link
                        className="underline text-[#007AFF] cursor-pointer"
                        to="#part2"
                        smooth={true}
                        offset={-100}
                      >
                        how we process your information
                      </Link>
                      .
                    </p>
                    <p className="pb-4">
                      <strong>
                        In what situations and with which parties do we share
                        personal information?
                      </strong>
                      We may share information in specific situations and with
                      specific third parties. Learn more about{" "}
                      <Link
                        className="underline text-[#007AFF] cursor-pointer"
                        to="#part4"
                        smooth={true}
                        offset={-100}
                      >
                        when and with whom we share your personal information
                      </Link>
                      .
                    </p>
                    <p className="pb-4">
                      <strong>How do we keep your information safe?</strong> We
                      have organizational and technical processes and procedures
                      in place to protect your personal information. However, no
                      electronic transmission over the internet or information
                      storage technology can be guaranteed to be 100% secure, so
                      we cannot promise or guarantee that hackers,
                      cybercriminals, or other unauthorized third parties will
                      not be able to defeat our security and improperly collect,
                      access, steal, or modify your information. Learn more
                      about{" "}
                      <Link
                        className="underline text-[#007AFF] cursor-pointer"
                        to="#part8"
                        smooth={true}
                        offset={-100}
                      >
                        how we keep your information safe
                      </Link>
                      .
                    </p>
                    <p className="pb-4">
                      <strong> What are your rights?</strong> Depending on where
                      you are located geographically, the applicable privacy law
                      may mean you have certain rights regarding your personal
                      information. Learn more about{" "}
                      <Link
                        className="underline text-[#007AFF] cursor-pointer"
                        to="#part9"
                        smooth={true}
                        offset={-100}
                      >
                        your privacy rights
                      </Link>
                      .
                    </p>
                    <p className="pb-4">
                      How do you exercise your rights? The easiest way to
                      exercise your rights is by submitting a{" "}
                      <Link
                        className="underline text-[#007AFF] cursor-pointer"
                        to="#part14"
                        smooth={true}
                        offset={-100}
                      >
                        data subject access request
                      </Link>
                      , or by contacting us. We will consider and act upon any
                      request in accordance with applicable data protection
                      laws.
                    </p>
                    <p>
                      <strong>
                        Want to learn more about what we do with any information
                        we collect?
                      </strong>{" "}
                      <Link
                        className="underline text-[#007AFF] cursor-pointer"
                        to="#part1"
                        smooth={true}
                        offset={-100}
                      >
                        Review the privacy notice in full
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div id="#part1">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    1. What Information Do We Collect?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    <strong>Personal information you disclose to us</strong>
                  </p>
                  <p className="pb-4">
                    <strong>In Short:</strong> We collect personal information
                    that you provide to us.
                  </p>
                  <p className="pb-4">
                    We collect personal information that you voluntarily provide
                    to us when you register on the Services, express an interest
                    in obtaining information about us or our products and
                    Services, when you #participate in activities on the
                    Services, or otherwise when you contact us.
                  </p>
                  <div className="pb-4">
                    <strong>Personal Information Provided by You. </strong>
                    <p>
                      The personal information that we collect depends on the
                      context of your interactions with us and the Services, the
                      choices you make, and the products and features you use.
                      The personal information we collect may include the
                      following:
                      <ul className="list-disc list-outside ml-5">
                        <li>names</li>
                        <li>phone numbers</li>
                        <li>email addresses</li>
                        <li>mailing addresses</li>
                        <li>usernames</li>
                        <li>passwords</li>
                        <li>contact preferences</li>
                        <li>contact or authentication data</li>
                        <li>billing addresses</li>
                        <li>debit/credit card numbers</li>
                      </ul>
                    </p>
                  </div>
                  <div className="pb-4"id="#part21">
                    <p>
                      <strong>Sensitive Information. </strong>
                    </p>
                    <p>
                      When necessary, with your consent or as otherwise
                      permitted by applicable law, we process the following
                      categories of sensitive information:
                      <ul className="list-disc list-outside ml-5">
                        <li>financial data</li>
                        <li>biometric data</li>
                      </ul>
                    </p>
                  </div>
                  <p className="pb-4">
                    <strong>Payment Data.</strong> We may collect data necessary
                    to process your payment if you make purchases, such as your
                    payment instrument number, and the security code associated
                    with your payment instrument. All payment data is
                    temporarily stored by Samcart, and deleted after the
                    transaction is completed. You may find their privacy notice
                    link(s) here:{" "}
                    <a
                      className="underline text-[#007AFF]"
                      href="https://www.samcart.com/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.samcart.com/privacy-policy. 
                    </a>
                  </p>
                  <p className="pb-4">
                    All personal information that you provide to us must be
                    true, complete, and accurate, and you must notify us of any
                    changes to such personal information.
                  </p>
                  <div className="pb-4">
                    <p>
                      <strong>Information automatically collected</strong>
                    </p>
                    <p>
                      <strong>In Short:</strong> Some information-such as your
                      Internet Protocol (IP) address and/or browser and device
                      characteristics is collected automatically when you visit
                      our Services.
                    </p>
                  </div>
                  <p className="pb-4">
                    We automatically collect certain information when you visit,
                    use, or navigate the Services. This information does not
                    reveal your specific identity (like your name or contact
                    information) but may include device and usage information,
                    such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs,
                    device name, country, location, information about how and
                    when you use our Services, and other technical information.
                    This information is primarily needed to maintain the
                    security and operation of our Services, and for our internal
                    analytics and reporting purposes.
                  </p>
                  <p className="pb-4">
                    Like many businesses, we also collect information through
                    cookies and similar technologies.
                  </p>
                  <div>
                    <p>The information we collect includes:</p>
                    <p>
                      <ul className="list-disc list-outside ml-5">
                        <li>
                          <strong>Log and Usage Data</strong>. Log and usage
                          data is service-related, diagnostic, usage, and
                          performance information our servers automatically
                          collect when you access or use our Services and which
                          we record in log files. Depending on how you interact
                          with us, this log data may include your IP address,
                          device information, browser type, and settings and
                          information about your activity in the Services (such
                          as the date/time stamps associated with your
                          usage,pages and files viewed, searches, and other
                          actions you take such as which features you use),
                          device event information (such as system activity,
                          error reports (sometimes called "crash dumps"),and
                          hardware settings).
                        </li>
                        <li>
                          <strong>Device Data</strong>. We collect device data
                          such as information about your computer, phone,
                          tablet, or other device you use to access the
                          Services. Depending on the device used, this device
                          data may include information such as your IP address
                          (or proxy server), device and application
                          identification numbers, location, browser type,
                          hardware model, Internet service provider and/or
                          mobile carrier, operating system, and system
                          configuration information.
                        </li>
                        <li>
                          <strong>Location Data</strong>. We collect location
                          data such as information about your device's location,
                          which can be either precise or imprecise. How much
                          information we collect depends on the type and
                          settings of the device you use to access the Services.
                          For example, we may use GPS and other technologies to
                          collect geolocation data that tells us your current
                          location (based on your IP address). You can opt out
                          of allowing us to collect this information either by
                          refusing access to the information or by disabling
                          your Location setting on your device. However, if you
                          choose to opt out, you may not be able to use certain
                          aspects of the Services.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
              <div id="#part2">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    2. How Do We Process Your Information?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    <strong>In Short:</strong> We process your information to
                    provide, improve, and administer our Services, communicate
                    with you, for security and fraud prevention, and to comply
                    with law. We may also process your information for other
                    purposes with your consent.
                  </p>
                  <p className="pb-4">
                    <strong>
                      We process your personal information for a variety of
                      reasons, depending on how you interact with our Services,
                      including:
                    </strong>
                  </p>
                  <p className="pb-4">
                    <ul className="list-disc list-outside ml-5">
                      <li className="pb-4">
                        <strong>
                          To facilitate account creation and authentication and
                          otherwise manage user accounts
                        </strong>
                        . We may process your information so you can create and
                        log in to your account, as well as keep your account in
                        working order.
                      </li>
                      <li className="pb-4">
                        <strong>
                          To facilitate account creation and authentication and
                          otherwise manage user accounts.
                        </strong>
                        We may process your information so you can create and
                        log in to your account, as well as keep your account in
                        working order.
                      </li>
                      <li className="pb-4">
                        <strong>
                          To deliver and facilitate delivery of services to the
                          user.
                        </strong>
                        We may process your information to provide you with the
                        requested service.
                      </li>
                      <li className="pb-4">
                        <strong>
                          To respond to user inquiries/offer support to users.
                        </strong>
                        We may process your information to respond to your
                        inquiries and solve any potential issues you might have
                        with the requested service.
                      </li>
                      <li className="pb-4">
                        <strong>
                          To send administrative information to you.
                        </strong>
                        We may process your information to send you details
                        about our products and services, changes to our terms
                        and policies, and other similar information.
                      </li>
                      <li className="pb-4">
                        <strong>To fulfill and manage your orders.</strong> We
                        may process your information to fulfill and manage your
                        orders, payments, returns, and exchanges made through
                        the Services
                      </li>
                      <li className="pb-4">
                        <strong>To enable user-to-user communications.</strong>{" "}
                        We may process your information if you choose to use any
                        of our offerings that allow for communication with
                        another user.
                      </li>
                      <li className="pb-4">
                        <strong>To request feedback.</strong> We may process
                        your information when necessary to request feedback and
                        to contact you about your use of our Services.
                      </li>
                      <li className="pb-4">
                        To send you marketing and promotional communications. We
                        may process the personal information you send to us for
                        our marketing purposes, if this is in accordance with
                        your marketing preferences. You can opt out of our
                        marketing emails at any time. For more information, see{" "}
                        <Link
                          className="underline text-[#007AFF] cursor-pointer"
                          to="#part9"
                          smooth={true}
                          offset={-100}
                        >
                          "WHAT ARE YOUR PRIVACY RIGHTS?"
                        </Link>{" "}
                        below.
                      </li>
                      <li className="pb-4">
                        <strong>To deliver targeted advertising to you.</strong>
                        We may process your information to develop and display
                        personalized content and advertising tailored to your
                        interests, location, and more.
                      </li>
                      <li className="pb-4">
                        <strong>To protect our Services.</strong> We may process
                        your information as #part of our efforts to keep our
                        Services safe and secure, including fraud monitoring and
                        prevention.
                      </li>
                      <li className="pb-4">
                        <strong>To identify usage trends.</strong> We may
                        process information about how you use our Services to
                        better understand how they are being used so we can
                        improve them.
                      </li>
                      <li className="pb-4">
                        <strong>
                          To determine the effectiveness of our marketing and
                          promotional campaigns.
                        </strong>
                        We may process your information to better understand how
                        to provide marketing and promotional campaigns that are
                        most relevant to you.
                      </li>
                      <li>
                        <strong>
                          To save or protect an individual's vital interest.
                        </strong>
                        We may process your information when necessary to save
                        or protect an individual's vital interest, such as to
                        prevent harm.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div id="#part3">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    3. What Legal Bases Do We Rely On To Process Your
                    Information?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    <strong>In Short:</strong> We only process your personal
                    information when we believe it is necessary and we have a
                    valid legal reason (i.e. legal basis) to do so under
                    applicable law, like with your consent, to comply with laws,
                    to provide you with services to enter into or fulfill our
                    contractual obligations, to protect your rights, or to
                    fulfill our legitimate business interests. 
                  </p>
                  <p className="pb-4">
                    If you are located in the EU or UK, this section applies to
                    you.
                  </p>
                  <div className="pb-4">
                    <ul className="list-disc list-outside ml-5">
                      <li className="pb-4">
                        The General Data Protection Regulation (GDPR) and UK
                        GDPR require us to explain the valid legal bases we rely
                        on in order to process your personal information. As
                        such, we may rely on the following legal bases to
                        process your personal information:
                      </li>
                      <li className="pb-4">
                        <strong>Consent.</strong> We may process your
                        information if you have given us permission (i.e.,
                        consent) to use your personal information for a specific
                        purpose. You can withdraw your consent at any time.
                        Learn more about{" "}
                        <Link
                          className="underline text-[#007AFF] cursor-pointer"
                          to="#part23"
                          smooth={true}
                          offset={-100}
                        >
                          withdrawing your consent.
                        </Link>
                      </li>
                      <li className="pb-4">
                        <strong>Performance of a Contract.</strong> We may
                        process your personal information when we believe it is
                        necessary to fulfill our contractual obligations to you,
                        including providing our Services or at your request
                        prior to entering into a contract with you.
                      </li>
                      <li>
                        <strong>Legitimate Interests.</strong> We may process
                        your information when we believe it is reasonably
                        necessary to achieve our legitimate business interests
                        and those interests do not outweigh your interests and
                        fundamental rights and freedoms. For example, we may
                        process your personal information for some of the
                        purposes described in order to:
                        <ul className="list-disc list-inside py-4">
                          <li>
                            Send users information about special offers and
                            discounts on our products and services
                          </li>
                          <li>
                            Develop and display personalized and relevant
                            advertising content for our users
                          </li>
                          <li>
                            Analyze how our Services are used so we can improve
                            them to engage and retain users
                          </li>
                          <li>Support our marketing activities</li>
                          <li>
                            Diagnose problems and/or prevent fraudulent
                            activitie
                          </li>
                          <li>
                            Understand how our users use our products and
                            services so we can improve user experience
                          </li>
                        </ul>
                      </li>
                      <li className="pb-4">
                        <strong>Legal Obligations.</strong> We may process your
                        information where we believe it is necessary for
                        compliance with our legal obligations, such as to
                        cooperate with a law enforcement body or regulatory
                        agency, exercise or defend our legal rights, or disclose
                        your information as evidence in litigation in which we
                        are involved.
                      </li>
                      <li className="pb-4">
                        <strong>Vital Interests.</strong> We may process your
                        information where we believe it is necessary to protect
                        your vital interests or the vital interests of a third
                        #party, such as situations involving potential threats
                        to the safety of any per
                      </li>
                    </ul>
                  </div>
                  <p className="pb-4">
                    <strong>
                      If you are located in Canada, this section applies to you.
                    </strong>
                  </p>
                  <p className="pb-4">
                    We may process your information if you have given us
                    specific permission (i.e.,express consent) to use your
                    personal information for a specific purpose, or in
                    situations where your permission can be inferred
                    (i.e.,implied consent). You can{" "}
                    <Link
                      className="underline text-[#007AFF] cursor-pointer"
                      to="#part23"
                      smooth={true}
                      offset={-100}
                    >
                      withdraw your consent
                    </Link>{" "}
                    at any time.
                  </p>
                  <div className="pb-4">
                    <p>
                      In some exceptional cases, we may be legally permitted
                      under applicable law to process your information without
                      your consent, including, for example:
                    </p>
                    <ul className="list-disc list-outside ml-5">
                      <li>
                        If collection is clearly in the interests of an
                        individual and consent cannot be obtained in a timely
                        way.
                      </li>
                      <li>
                        For investigations and fraud detection and prevention
                      </li>
                      <li>
                        If it is contained in a witness statement and the
                        collection is necessary to For business transactions
                        provided certain conditions are met
                      </li>
                      <li>
                        If it is contained in a witness statement and the
                        collection is necessary to assess, process, or settle an
                        insurance claim
                      </li>
                      <li>
                        For identifying injured, ill, or deceased persons and
                        communicating with next of kin
                      </li>
                      <li>
                        If we have reasonable grounds to believe an individual
                        has been, is, or may b victim of financial abuse
                      </li>
                      <li>
                        If it is reasonable to expect collection and use with
                        consent would compromise the availability or the
                        accuracy of the information and the collection is
                        reasonable for purposes related to investigating a
                        breach of an agreement or contravention of the laws of
                        Canada or a province
                      </li>
                      <li>
                        If disclosure is required to comply with a subpoena,
                        warrant, court order, or rules of the court relating to
                        the production of record
                      </li>
                      <li>
                        If it was produced by an individual in the course of
                        their employment, business or profession and the
                        collection is consistent with the purposes for which the
                        information was produced
                      </li>
                      <li>
                        If the collection is solely for journalistic, artistic,
                        or literary purposes
                      </li>
                      <li>
                        If the information is publicly available and is
                        specified by the regulations
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="#part4">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    4. When and With Whom Do We Share Your Personal Information?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    <strong>In Short:</strong> We may share information in
                    specific situations described in this section and/or with
                    the following third parties.
                  </p>
                  <p className="pb-4">
                    We may need to share your personal information in the
                    following situations:
                  </p>
                  <div>
                    <ul className="list-disc list-outside ml-5">
                      <li className="pb-4">
                        <strong> Business Transfers.</strong> We may share or
                        transfer your information in connection with, or during
                        negotiations of, any merger, sale of company assets,
                        financing, or acquisition of all or a portion of our
                        business to another  company.
                      </li>
                      <li className="pb-4">
                        <strong>When we use Google Analytics.</strong> We may
                        share your information with Google Analytics to track
                        and analyze the use of the Services. The Google
                        Analytics Advertising Features that we may use include:
                        Google Analytics Demographics and Interests Reporting.
                        To opt out of being tracked by Google Analytics across
                        the Services, visit{" "}
                        <a
                          className="underline text-[#007AFF]"
                          href="https://tools.google.com/dlpage/gaoptout"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://tools.google.com/dlpage/gaoptout
                        </a>
                        . You can opt out of Google Analytics Advertising
                        Features through{" "}
                        <a
                          className="underline text-[#007AFF]"
                          href="https://myadcenter.google.com/home?sasb=true&ref=ad-settings"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Ads Settings
                        </a>{" "}
                        and Ad Settings for mobile apps. Other opt out means
                        include{" "}
                        <a
                          className="underline text-[#007AFF]"
                          href="http://optout.networkadvertising.org/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          http://optout.networkadvertising.org/{" "}
                        </a>{" "}
                        and{" "}
                        <a
                          className="underline text-[#007AFF]"
                          href="http://www.networkadvertising.org/mobile-choice"
                          target="_blank"
                          rel="noreferrer"
                        >
                          http://www.networkadvertising.org/mobile-choice
                        </a>
                        . For more information on the privacy practices of
                        Google, please visit the {"  "}{" "}
                        <a
                          className="underline text-[#007AFF]"
                          href="https://policies.google.com/privacy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Google Privacy & Terms page
                        </a>
                        .
                      </li>
                      <li className="pb-4">
                        <strong>When we use Google Maps Platform APIs.</strong>{" "}
                        We may share your information with certain Google Maps
                        Platform APIs (e.g., Google Maps API, Places API).We
                        obtain and store on your device ("cache") your location
                        for six (6) months. You may revoke your consent anytime
                        by contacting us at the contact details provided at the
                        end of this document.
                      </li>
                      <li className="pb-4">
                        <strong> Affiliates.</strong> We may share your
                        information with our affiliates, in which case we will
                        require those affiliates to honor this privacy notice.
                        Affiliates include our parent company and any
                        subsidiaries, joint venture #partners, or other
                        companies that we control or that are under common
                        control with us
                      </li>
                      <li className="pb-4">
                        <strong>Business #Partners.</strong> We may share your
                        information with our business #partners to offer you
                        certain products, services, or promotions.
                      </li>
                      <li className="pb-4">
                        <strong>Other Users.</strong> When you share personal
                        information (for example, by posting comments,
                        contributions, or other content to the Services) or
                        otherwise interact with public areas of the Services,
                        such personal information may be viewed by all users and
                        may be publicly made available outside the Services in
                        perpetuity. If you interact with other users of our
                        Services and register for our Services through a social
                        network (such as Facebook), your contacts on the social
                        network will see your name, profile photo, and
                        descriptions of your activity. Similarly, other users
                        will be able to view descriptions of your activity,
                        communicate with you within our Services, and view your
                        profile.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="#part5">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    5. Do We Use Cookies and Other Tracking Technologies?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    <strong>In Short:</strong> We may use cookies and other
                    tracking technologies to collect and store your information
                  </p>
                  <p className="pb-4">
                    We may use cookies and similar tracking technologies (lile
                    web beacons and pixels) to access or store information.
                    Specific information about how we use such technologies and
                    how you can refuse certain cookies is set out in our Cookie
                    Notice.
                  </p>
                </div>
              </div>
              <div id="#part6">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    6. How Do We Handle Your Social Logins?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    <strong>In Short:</strong> If you choose to register or log
                    in to our Services using a social media account, we may have
                    access to certain information about yo
                  </p>
                  <p className="pb-4">
                    Our Services offer you the ability to register and log in
                    using your third-#party social media account details (like
                    your Facebook or Twitter logins).Where you choose to do
                    this, we will receive certain profile information about you
                    from your social media provider. The profile information we
                    receive may vary depending on the social media provider
                    concerned, but will often include your name, email address,
                    friends list, and profile picture, as well as other
                    information you choose to make public on such a social media
                    platform
                  </p>
                  <p className="pb-4">
                    We will use the information we receive only for the purposes
                    that are described in this privacy notice or that are
                    otherwise made clear to you on the relevant Services. Please
                    note that we do not control, and are not responsible for,
                    other uses of your personal information by your third-#party
                    social media provider. We recommend that you review their
                    privacy notice to understand how they collect, use, and
                    share your personal information, and how you can set your
                    privacy preferences on their sites and apps.
                  </p>
                </div>
              </div>
              <div id="#part7">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    7. How Long Do We Keep Your Information?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    <strong>In Short:</strong> We keep your information for as
                    long as necessary to fulfill the purposes outlined in this
                    privacy notice unless otherwise required by law
                  </p>
                  <p className="pb-4">
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting, or other legal
                    requirements). No purpose in this notice will require us
                    keeping your personal information for longer than the period
                    of time in which users have an account with us.
                  </p>
                  <p className="pb-4">
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting, or other legal
                    requirements). No purpose in this notice will require us
                    keeping your personal information for longer than the period
                    of time in which users have an account with us.
                  </p>
                </div>
              </div>
              <div id="#part8">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    8. How Do We Keep Your Information Safe?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    <strong>In Short:</strong> We aim to protect your personal
                    information through a system of organizational and technical
                    security measures
                  </p>
                  <p className="pb-4">
                    We have implemented appropriate and reasonable technical and
                    organizational security measures designed to protect the
                    security of any personal information we process. However,
                    despite our safeguards and efforts to secure your
                    information, no electronic transmission over the Internet or
                    information storage technology can be guaranteed to be 100%
                    secure, so we cannot promise or guarantee that
                    hackers,cybercriminals, or other unauthorized third parties
                    will not be able to defeat our security and improperly
                    collect, access, steal, or modify your information. Although
                    we will do our best to protect your personal information,
                    transmission of personal information to and from our
                    Services is at your own risk. You should only access the
                    Services within a secure environment.
                  </p>
                </div>
              </div>
              <div id="#part9">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    9. What Are Your Privacy Rights?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    <strong>In Short:</strong> In sore regions, such as the
                    European Economic Area (EEA), United Kingdom (UK),
                    Switzerland, and Canada, you have rights that allow you
                    greater access to and control over your personal
                    information. You may review, change, or terminate your
                    account at any time.
                  </p>
                  <p className="pb-4">
                    In some regions (like the EEA, UK, Switzerland, and Canada),
                    you have certain rights under applicable data protection
                    laws. These may include the right (i) to request access and
                    obtain a copy of your personal information, (ii) to request
                    rectification or erasure, (iii) to restrict the processing
                    of your personal information, (w) if applicable, to data
                    portability, and (v) not to be subject to automated
                    decision-making. In certain circumstances, you may also have
                    the right to object to the processing of your personal
                    information. You can make such a request by contacting us by
                    using the contact details provided in the section{" "}
                    <Link
                      className="underline text-[#007AFF] cursor-pointer"
                      to="#part14"
                      smooth={true}
                      offset={-100}
                    >
                      “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below
                    </Link>
                    .
                  </p>
                  <p className="pb-4">
                    We will consider and act upon any request in accordance with
                    applicable data protection laws
                  </p>
                  <p className="pb-4">
                    If you are located in the EEA or UK and you believe we are
                    unlawfully processing your personal information, you also
                    have the right to complain to your
                    <a
                      className="underline text-[#007AFF]"
                      href="https://ec.europa.eu/newsroom/article29/items/612080https://docs.google.com/document/d/1VBEKaeNniJKi-PgJYy6Nn1l0Cs4gytOxNoWoKWRTrUg/edit#heading=h.e37hhc53odxq"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Member State data protection authority or UK data
                      protection authority
                    </a>
                    .
                  </p>
                  <p className="pb-4">
                    If you are located in Switzerland, you may contact the{" "}
                    <a
                      className="underline text-[#007AFF]"
                      href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Federal Data Protection and Information Commissioner
                    </a>
                    .
                  </p>
                  <p className="pb-4" id="#part23">
                    <strong> Withdrawing your consent:</strong> If we are
                    relying on your consent to process your personal
                    information, which may be express and/or implied consent
                    depending on the applicable law, you have the right to
                    withdraw your consent at any time. You can withdraw your
                    consent at any time by contacting us by using the contact
                    details provided in the section{" "}
                    <Link
                      className="underline text-[#007AFF] cursor-pointer"
                      to="#part14"
                      smooth={true}
                      offset={-100}
                    >
                      "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" 
                    </Link>{" "}
                    below or updating your preferences.
                  </p>
                  <p className="pb-4">
                    However, please note that this will not affect the
                    lawfulness of the processing before its withdrawal nor, when
                    applicable law allows, will it affect the processing of your
                    personal information conducted in reliance on lawful
                    processing grounds other than consent.
                  </p>
                  <p className="pb-4">
                    <strong>
                      Opting out of marketing and promotional communications:
                    </strong>{" "}
                    You can unsubscribe from our marketing and promotional
                    communications at any time by clicking on the unsubscribe
                    link in the emails that we send, replying "STOP" or
                    "UNSUBSCRIBE" to the SMS messages that we send, or by
                    contacting us using the details provided in the section{" "}
                    <Link
                      className="underline text-[#007AFF] cursor-pointer"
                      to="#part14"
                      smooth={true}
                      offset={-100}
                    >
                      "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                    </Link>{" "}
                    below. You will then be removed from the marketing lists.
                    However, we may still communicate with you-for example, to
                    send you service-related messages that are necessary for the
                    administration and use of your account, to respond to
                    service requests, or for other non-marketing purposes.
                  </p>
                  <p className="pb-4">Account Information</p>
                  <div className="pb-4">
                    <p>
                      If you would at any time like to review or change the
                      information in your account or terminate your account, you
                      can
                    </p>
                    <ul className="list-disc list-inside">
                      <li>
                        Log in to your account settings and update your user
                        account.
                      </li>
                    </ul>
                    <p>
                      Upon your request to terminate your account, we will
                      deactivate or delete your account and information from our
                      active databases. However, we may retain some information
                      in our files to prevent fraud, troubleshoot problems,
                      assist with any investigations, enforce our legal terms
                      and/or comply with applicable legal requirements.
                    </p>
                  </div>
                  <div className="pb-4">
                    <p>
                      <strong>Cookies and similar technologies:</strong> Most
                      Web browsers are set to accept cookies by default. If you
                      prefer, you can usually choose to set your browser to
                      remove cookies and to reject cookies. If you choose to
                      remove cookies or reject cookies, this could affect
                      certain features or services of our Services.
                    </p>
                    <p>
                      If you have questions or comments about your privacy
                      rights, you may email us at info@afroshop.ca.
                    </p>
                  </div>
                </div>
              </div>
              <div id="#part10">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    10. Controls For Do-Not-Track Features
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track ("DNT") feature
                    or setting you can activate to signal your privacy
                    preference not to have data about your online browsing
                    activities monitored and collected. At this stage no uniform
                    technology standard for recognizing and implementing DNT
                    signals has been finalized. As such, we do not currently
                    respond to DNT browser signals or any other mechanism that
                    automatically communicates your choice not to be tracked
                    online. If a standard for online tracking is adopted that we
                    must follow in the future, we will inform you about that
                    practice in a revised version of this privacy notice.
                  </p>
                </div>
              </div>

              <div id="#part11">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    11. Do United States Residents Have Specific Privacy Rights?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    <strong>In Short:</strong> If you are a resident of, you are
                    granted specific rights regarding access to your personal
                    information.
                  </p>
                  <p className="pb-4">
                    <strong>
                      What categories of personal information do we collect?
                    </strong>
                  </p>
                  <p className="pb-4">
                    We have collected the following categories of personal
                    information in the past twelve (12) months:
                  </p>
                  <div className="lg:hidden block">
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p>A. Identifiers</p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>
                            Contact details, such as real name,alias,postal
                            address, telephone or mobile contact number, unique
                            personal identifier, online identifier, Internet
                            Protocol address, email address, and account name
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p>
                            {" "}
                            B. Personal information as defined California
                            Customer Records statute
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>
                            Name, contact information, education, employment,
                            employment, employment history and financial
                            information
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p>
                            {" "}
                            C. Protected classification characteristics under
                            state or federal law
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>Gender and date of birth</p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p> D. Commercial information</p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>
                            Transaction information, purchase history, financial
                            details, and payment information
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p>E. Biometric information</p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>Fingerprints and voiceprints</p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p>F. Internet or other similar network activity</p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>
                            Browsing history, search history, online behavior,
                            interest data, and interactions with our and other
                            websites, applications, systems, and advertisements
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p>G. Geolocation data</p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>Device location</p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p>
                            {" "}
                            H. Audio, electronic, visual, thermal, olfactory, or
                            similar informatio
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>
                            Images and audio, video or call recordings created
                            in connection with our business activities
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p>
                            I. Professional or employment-related information
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>
                            Business contact details in order to provide you our
                            Services at a business level or job title, work
                            history, and professional qualifications if you
                            apply for a job with us
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p>J. Education Information</p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>Student records and directory information</p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p>
                            {" "}
                            K. Inferences drawn from collected personal
                            information
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>
                            Inferences drawn from any of the collected personal
                            information listed above to create a profile or
                            summary about, for example, an individual's
                            preferences and characteristics
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                    <div>
                      <div className="flex flex-col gap-[24px] bg-[#F2F2F2] p-3 my-9">
                        <div>
                          <p className="text-green pb-4">
                            <strong>CATEGORY</strong>
                          </p>
                          <p>L. Sensitive personal Information</p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>EXAMPLES</strong>
                          </p>
                          <p>
                            Account Login information, biometric data and
                            precise geolocation
                          </p>
                        </div>
                        <div>
                          <p className="text-green pb-4">
                            <strong>COLLECTED</strong>
                          </p>
                          <p>NO</p>
                        </div>
                      </div>
                      <div className=" border-b border-[#E6E6E6]"></div>
                    </div>
                  </div>
                  <div className="pb-4 hidden lg:block">
                    <table cellSpacing="0">
                      <thead className="bg-[#F2F2F2]">
                        <tr>
                          <th className="py-4 px-6 text-left text-green">
                            CATEGORY
                          </th>
                          <th className="py-4 px-6 text-left text-green">
                            EXAMPLES
                          </th>
                          <th className="py-4 px-6 text-left text-green">
                            COLLECTED
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            A. Identifiers
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Contact details, such as real name,alias,postal
                            address, telephone or mobile contact number, unique
                            personal identifier, online identifier, Internet
                            Protocol address, email address, and account name
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            B. Personal information as defined California
                            Customer Records statute
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Name, contact information, education, employment,
                            employment, employment history and financial
                            information
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            C. Protected classification characteristics under
                            state or federal law
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Gender and date of birth
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            D. Commercial information
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Transaction information, purchase history, financial
                            details, and payment information
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            E. Biometric information
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Fingerprints and voiceprints
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            F. Internet or other similar network activity
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Browsing history, search history, online behavior,
                            interest data, and interactions with our and other
                            websites, applications, systems, and advertisements
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            G. Geolocation data
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Device location
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            H. Audio, electronic, visual, thermal, olfactory, or
                            similar information
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Images and audio, video or call recordings created
                            in connection with our business activities
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            I. Professional or employment-related information
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Business contact details in order to provide you our
                            Services at a business level or job title, work
                            history, and professional qualifications if you
                            apply for a job with us
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            J. Education Information
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Student records and directory information
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            K. Inferences drawn from collected personal
                            information
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Inferences drawn from any of the collected personal
                            information listed above to create a profile or
                            summary about, for example, an individual's
                            preferences and characteristics
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            L. Sensitive personal Information
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            Account Login information, biometric data and
                            precise geolocation
                          </td>
                          <td className="px-6 py-2 border-b border-[#E6E6E6] text-[13px]">
                            NO
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="pb-4">
                    We may also collect other personal information outside of
                    these categories through instances where you interact with
                    us in person, online, or by phone or mail in the context of:
                  </p>
                  <div className="pb-4">
                    <p>
                      We will use and retain the collected personal information
                      as needed to provide the Services or for:
                    </p>
                    <ul className="list-disc list-outside ml-5">
                      <li>
                        Category B - All data except financial information (card
                        information) will be kept with us as long as user has an
                        account with us
                      </li>
                      <li>
                        Category L - As long as the user has an account with us,
                        except for Debit or credit card details
                      </li>
                    </ul>
                  </div>
                  <p className="pb-4">
                    Category L information may be used, or disclosed to a
                    service provider or contractor, for additional, specified
                    purposes. You have the right to limit the use or disclosure
                    of your sensitive personal information.
                  </p>
                  <div className="pb-4">
                    <p className="pb-4">
                      We may also collect other personal information outside of
                      these categories through instances where you interact with
                      us in person, online, or by phone or mail in the context
                      of:
                    </p>
                    <ul className="list-disc list-outside ml-5">
                      <li>
                        Receiving help through our customer support channels;
                      </li>
                      <li>
                        Participation in customer surveys or contests; and
                      </li>
                      <li>
                        Facilitation in the delivery of our Services and to
                        respond to your inquiries.
                      </li>
                    </ul>
                  </div>
                  <p className="pb-4">
                    <strong>
                      How do we use and share your personal information?
                    </strong>
                  </p>
                  <p className="pb-4">
                    Learn about how we use your personal information in the
                    section,{" "}
                    <Link
                      className="underline text-[#007AFF] cursor-pointer"
                      to="#part2"
                      smooth={true}
                      offset={-100}
                    >
                      "HOW DO WE PROCESS YOUR INFORMATION?
                    </Link>
                  </p>
                  <p className="pb-4">
                    Will your information be shared with anyone else?
                  </p>
                  <p className="pb-4">
                    We may disclose your personal information with our service
                    providers pursuant to a written contract between us and each
                    service provider. Learn more about how we disclose personal
                    information in the section,{" "}
                    <Link
                      className="underline text-[#007AFF] cursor-pointer"
                      to="#part4"
                      smooth={true}
                      offset={-100}
                    >
                      "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                      INFORMATION?"
                    </Link>
                  </p>
                  <p className="pb-4">
                    We may use your personal information for our own business
                    purposes, such as for undertaking internal research for
                    technological development and demonstration. This is not
                    considered to be "selling" of your personal information.
                  </p>
                  <p className="pb-4">
                    We have not disclosed, sold, or shared any personal
                    information to third parties for a business or commercial
                    purpose in the preceding twelve (12) months. We will not
                    sell or share personal information in the future belonging
                    to website visitors, users, and other consumers.
                  </p>
                  <h3 className="pb-4">
                    <strong>California Residents</strong>
                  </h3>
                  <p className="pb-4">
                    California Civil Code Section 1798.83, also known as the
                    "Shine The Light" law permits our users who are California
                    residents to request and obtain from us, once a year and
                    free of charge, information about categories of personal
                    information (if any)we disclosed to third parties for direct
                    marketing purposes and the names and addresses of all third
                    parties with which we shared personal information in the
                    immediately preceding calendar year. If you are a California
                    resident and would like to make such a request, please
                    submit your request in writing to us using the contact
                    information provided below.
                  </p>
                  <p className="pb-4">
                    If you are under 18 years of age, reside in California, and
                    have a registered account with the Services, you have the
                    right to request removal of unwanted data that you publicly
                    post on the Services. To request removal of such data,
                    please contact us using the contact information provided
                    below and include the email address associated with your
                    account and a statement that you reside in California. We
                    will make sure the data is not publicly displayed on the
                    Services, but please be aware that the data may not be
                    completely or comprehensively removed from all our systems
                    (e.g., backups,etc.).
                  </p>
                  <div className="pb-4">
                    <h3 className="pb-4">
                      <strong>CCPA Privacy Notice</strong>
                    </h3>
                    <div>
                      <p>
                        This section applies only to California residents. Under
                        the California Consumer Privacy Act(CCPA), you have the
                        rights listed below
                      </p>
                      <p className="pl-2">
                        The California Code of Regulations defines a "residents"
                        as:
                      </p>
                    </div>
                    <p className="pb-4">
                      (1) every individual who is in the State of California for
                      other than a temporary or transitory purpose and
                    </p>
                    <p className="pb-4">
                      (2)every individual who is domiciled in the State of
                      California who is outside the State of California for a
                      temporary or transitory purpose
                    </p>
                    <p className="pb-4">
                      All other individuals are defined as "non-residents."
                    </p>
                    <p className="pb-4">
                      If this definition of "resident" applies to you, we must
                      adhere to certain rights and obligations regarding your
                      personal information.
                    </p>
                  </div>
                  <h3 className="pb-4">
                    <strong>
                      Your rights with respect to your personal data
                    </strong>
                  </h3>
                  <h3 className="pb-4">
                    <strong>
                      Right to request deletion of the data-Request to delete
                    </strong>
                  </h3>

                  <p className="pb-4">
                    You can ask for the deletion of your personal information.
                    If you ask us to delete your personal information, we will
                    respect your request and delete your personal information,
                    subject to certain exceptions provided by law, such as (but
                    not limited to) the exercise by another consumer of his or
                    her right to free speech, our compliance requirements
                    resulting from a legal obligation, or any processing that
                    may be required to protect against illegal activities.
                  </p>

                  <h3 className="pb-4">
                    <strong>Right to be informed-Request to know</strong>
                  </h3>
                  <div className="pb-4">
                    <p>
                      Depending on the circumstances, you have a right to know:
                    </p>
                    <ul className="list-disc list-outside ml-5">
                      <li>
                        whether we collect and use your personal information;
                      </li>
                      <li>
                        the categories of personal information that we collect
                      </li>
                      <li>
                        the purposes for which the collected personal
                        information is used;
                      </li>
                      <li>
                        whether we sell or share personal information to third
                        partie
                      </li>
                      <li>
                        the categories of personal information that we sold,
                        shared, or disclosed for a business purpos
                      </li>
                      <li>
                        the categories of third parties to whom the personal
                        information was sold,shared, or disclosed for a business
                        purpose
                      </li>
                      <li>
                        the business or commercial purpose for collecting,
                        selling, or sharing personal information; and
                      </li>
                      <li>
                        the specific pieces of personal information we collected
                        about you.
                      </li>
                    </ul>
                  </div>
                  <p className="pb-4">
                    In accordance with applicable law, we are not obligated to
                    provide or delete consumer information that is de-identified
                    in response to a consumer request or to re-identify
                    individual data to verify a consumer request.
                  </p>
                  <h3 className="pb-4">
                    <strong>
                      Right to Non-Discrimination for the Exercise of a
                      Consumer's Privacy Rights
                    </strong>
                  </h3>
                  <p className="pb-4">
                    We will not discriminate against you if you exercise your
                    privacy rights.
                  </p>
                  <h3 className="pb-4">
                    <strong>
                      Right to Limit Use and Disclosure of Sensitive Personal
                      Information
                    </strong>
                  </h3>
                  <div className="pb-4">
                    <p>If the business collects any of the following:</p>
                    <ul className="list-disc list-outside ml-5">
                      <li>
                        social security information, drivers' licenses, state ID
                        cards, passport numbers
                      </li>
                      <li>account login information</li>
                      <li>
                        credit card numbers, financial account information, or
                        credentials allowing access to such accounts
                      </li>
                      <li>precise geolocation</li>
                      <li>
                        racial or ethnic origin, religious or philosophical
                        beliefs, union membership
                      </li>
                      <li>
                        the contents of email and text, unless the business is
                        the intended recipient of the communica
                      </li>
                      <li>genetic data, biometric data, and health dat</li>
                      <li>data concerning sexual orientation and sex life</li>
                    </ul>
                  </div>
                  <p className="pb-4">
                    You have the right to direct that business to limit its use
                    of your sensitive personal information to that use which is
                    necessary to perform the Services.
                  </p>
                  <p className="pb-4">
                    Once a business receives your request, they are no longer
                    allowed to use or disclose your sensitive personal
                    information for any other purpose unless you provide consent
                    for the use or disclosure of sensitive personal information
                    for additional purposes.
                  </p>
                  <p className="pb-4">
                    Please note that sensitive personal information that is
                    collected or processed without the purpose of inferring
                    characteristics about a consumer is not covered by this
                    right, as well as the publicly available information
                  </p>
                  <p className="pb-4">
                    To exercise your right to limit use and disclosure of
                    sensitive personal information,please email info@afroshop.ca
                    or submit a how can you review.
                  </p>
                  <h3 className="pb-4">
                    <strong>Verification process</strong>
                  </h3>
                  <p className="pb-4">
                    Upon receiving your request, we will need to verify your
                    identity to determine you are the same person about whom we
                    have the information in our system. These verification
                    efforts require us to ask you to provide information so that
                    we can match it with information you have previously
                    provided us. For instance, depending on the type of request
                    you submit, we may ask you to provide certain information so
                    that we can match the information you provide with the
                    information we already have on file or we may contact you
                    through a communication method (e.g., phone or email) that
                    you have previously provided to us. We may also use other
                    verification methods as the circumstances dictate.
                  </p>
                  <p className="pb-4">
                    We will only use personal information provided in your
                    request to verify your identity or authority to make the
                    request. To the extent possible, we will avoid requesting
                    additional information from you for the purposes of
                    verification. However, if we cannot verify your identity
                    from the information already maintained by us, we may
                    request that you provide additional information for the
                    purposes of verifying your identity and for security or
                    fraud-prevention purposes. We will delete such additionally
                    provided information as soon as we finish verifying you.
                  </p>
                  <h3 className="pb-4">
                    <strong>Other privacy rights</strong>
                  </h3>
                  <p className="pb-4">
                    <ul className="list-disc list-outside ml-5">
                      <li>
                        You may object to the processing of your personal
                        information.
                      </li>
                      <li>
                        You may request correction of your personal data if it
                        is incorrect or no longer relevant, or ask to restrict
                        the processing of the information.
                      </li>
                      <li>
                        You can designate an authorized agent to make a request
                        under the CCPA on your behalf. We may deny a request
                        from an authorized agent that does not submit proof that
                        they have been validly authorized to act on your behalf
                        in accordance with the CCPA.
                      </li>
                      <li>
                        You may request to opt out from future selling or
                        sharing of your personal information to third parties.
                        Upon receiving an opt-out request, we will act upon the
                        request as soon as feasibly possible, but no later than
                        fifteen (15)days from the date of the request
                        submission.
                      </li>
                    </ul>
                  </p>
                  <p className="pb-4">
                    To exercise these rights, you can contact us by submitting a{" "}
                    <Link
                      className="underline text-[#007AFF] cursor-pointer"
                      to="#part14"
                      smooth={true}
                      offset={-100}
                    >
                      data subject access request
                    </Link>
                    , by email at info@afroshop.ca, by visiting Afroshop.ca, or
                    by referring to the contact details at the bottom of this
                    document. If you have a complaint about how we handle your
                    data, we would like to hear from you.
                  </p>
                  <h3 className="pb-4">
                    <strong>Colorado Residents</strong>
                  </h3>
                  <div className="pb-4">
                    <p>
                      This section applies only to Colorado residents. Under the
                      Colorado Privacy Act (CPA), you have the rights listed
                      below.
                    </p>
                    <p>
                      However, these rights are not absolute, and in certain
                      cases, we may decline your request as permitted by law.
                    </p>
                    <p>
                      <ul className="list-disc list-outside ml-5">
                        <li>
                          Right to be informed whether or not we are processing
                          your personal data
                        </li>
                        <li>Right to access your personal data</li>
                        <li>
                          Right to correct inaccuracies in your personal data
                        </li>
                        <li>Right to request deletion of your personal data</li>
                        <li>
                          Right to obtain a copy of the personal data you
                          previously shared with us
                        </li>
                        <li>
                          Right to opt out of the processing of your personal
                          data if it is used for targeted advertising, the sale
                          of personal data, or profiling in furtherance of
                          decisions that produce legal or similarly significant
                          effects ("profiling")
                        </li>
                      </ul>
                    </p>
                  </div>
                  <h3 className="pb-4">
                    <strong>
                      Exercise your rights provided under the Virginia VCDPA
                    </strong>
                  </h3>
                  <p className="pb-4">
                    You may contact us by email at info@afroshop.ca or submit a{" "}
                    <Link
                      className="underline text-[#007AFF] cursor-pointer"
                      to="#part14"
                      smooth={true}
                      offset={-100}
                    >
                      
                    </Link>
                    .
                  </p>
                  <p className="pb-4">
                    If you are using an authorized agent to exercise your
                    rights, we may deny a request if the authorized agent does
                    not submit proof that they have been validly authorized to
                    act on your behalf.
                  </p>
                  <h3 className="pb-4">
                    <strong>Verification process</strong>
                  </h3>
                  <p className="pb-4">
                    We may request that you provide additional information
                    reasonably necessary to verify you and your consumer's
                    request. If you submit the request through an authorized
                    agent, we may need to collect additional information to
                    verify your identity before processing your request.
                  </p>
                  <p className="pb-4">
                    Upon receiving your request, we will respond without undue
                    delay, but in all cases, within forty-five (45) days of
                    receipt. The response period may be extended once by
                    forty-five (45) additional days when reasonably necessary.
                    We will inform you of any such extension within the initial
                    45-day response period, together with the reason for the
                    extension.
                  </p>
                  <h3 className="pb-4">
                    <strong>Right to appeal</strong>
                  </h3>
                  <p className="pb-4">
                    If we decline to take action regarding your request, we will
                    inform you of our decision and reasoning behind it. If you
                    wish to appeal our decision, please email us at
                    info@afroshop.ca. Within sixty (60) days of receipt of an
                    appeal, we will inform you in writing of any action taken or
                    not taken in response to the appeal, including a written
                    explanation of the reasons for the decisions. If your appeal
                    is denied, you may contact the Attorney General to submit a
                    complaint.
                  </p>
                </div>
              </div>

              <div id="#part12">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    12. Do Other Regions Have Specific Privacy Rights?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    <strong> In Short: </strong>You may have additional rights
                    based on the country you reside in.
                  </p>
                  <p className="pb-4">
                    <strong>Australia and New Zealand</strong>
                  </p>
                  <p className="pb-4">
                    We collect and process your personal information under the
                    obligations and conditions set by Australia's Privacy Act
                    1988 and New Zealand's Privacy Act 2020(Privacy Act).
                  </p>
                  <p className="pb-4">
                    This privacy notice satisfies the notice requirements
                    defined in both Privacy Acts,in #particular: what personal
                    information we collect from you, from which sources, for
                    which purposes, and other recipients of your personal
                    information
                  </p>
                  <p className="pb-4">
                    If you do not wish to provide the personal information
                    necessary to fulfill their applicable purpose, it may affect
                    our ability to provide our services, in #particular:
                  </p>
                  <p className="pb-4">
                    <ul className="list-disc list-outside ml-5">
                      <li>offer you the products or services that you wa</li>
                      <li>respond to or help with your requests</li>
                      <li>manage your account with us</li>
                      <li>confirm your identity and protect your account</li>
                    </ul>
                  </p>
                  <p className="pb-4">
                    At any time, you have the right to request access to or
                    correction of your personal information. You can make such a
                    request by contacting us by using the contact details
                    provided in the section{" "}
                    <Link
                      className="underline text-[#007AFF] cursor-pointer"
                      to="#part15"
                      smooth={true}
                      offset={-100}
                    >
                      "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                      FROM YOU?"
                    </Link>
                  </p>
                  <p className="pb-4">
                    If you believe we are unlawfully processing your personal
                    information, you have the right to submit a complaint about
                    a breach of the Australian Privacy Principles to the{" "}
                    <a
                      className="underline text-[#007AFF]"
                      href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Office of the Australian Information Commissioner
                    </a>{" "}
                    and a breach of New Zealand's Privacy Principles to the
                    <a
                      className="underline text-[#007AFF]"
                      href="https://www.privacy.org.nz/your-rights/making-a-complaint-to-the-privacy-commissioner/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Office of New Zealand Privacy Commissioner
                    </a>
                    .
                  </p>
                  <p className="pb-4">
                    <strong>Republic of South Africa</strong>
                  </p>
                  <p className="pb-4">
                    At any time, you have the right to request access to or
                    correction of your personal information. You can make such a
                    request by contacting us by using the contact details
                    provided in the section{" "}
                    <Link
                      className="underline text-[#007AFF] cursor-pointer"
                      to="#part15"
                      smooth={true}
                      offset={-100}
                    >
                      "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                      FROM YOU?"{" "}
                    </Link>
                  </p>
                  <p className="pb-4">
                    If you are unsatisfied with the manner in which we address
                    any complaint with regard to our processing of personal
                    information, you can contact the office of the regulator,the
                    details of which are:
                  </p>
                  <div className="pb-4">
                    <p>The Information Regulator(South Africa)</p>
                    <p>General enquiries: enquiries@inforegulator.org.za</p>
                    <p>Complaints (complete POPIA/PAIA form)</p>
                    <p>
                      PAIAComplaints@inforegulator.org.za &
                      POPIAComplaints@inforegulator.org.za
                    </p>
                  </div>
                </div>
              </div>

              <div id="#part13">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    13. Do We Make Updates To This Notice?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    In Short: Yes, we will update this notice as necessary to
                    stay compliant with relevant laws.
                  </p>
                  <p className="pb-4">
                    We may update this privacy notice from time to time. The
                    updated version will be indicated by an updated "Revised"
                    date and the updated version will be effective as soon as it
                    is accessible. If we make material changes to this privacy
                    notice, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a
                    notification. We encourage you to review this privacy notice
                    frequently to be informed of how we are protecting your
                    information.
                  </p>
                </div>
              </div>
              <div id="#part14">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    14. How Can You Contact Us About This Notice?
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    If you have questions or comments about this notice, you may
                    email us at info@afroshop.ca or contact us by post at:
                  </p>
                  <div className="pb-4">
                    <p>Afroshop Grocery and Foodcourt Ltd</p>
                    <p>174 Darlingside Drive</p>
                    <p>Toronto, Ontario M1E 3P6,</p>
                    <p>Canada</p>
                  </div>
                </div>
              </div>
              <div id="#part15">
                <div className="mx-6 lg:mx-0">
                  <h2 className="text-[20px] font-bold text-green">
                    15. How Can You Review, Update or Delete The Data We Collect
                    From You
                  </h2>
                  <div className="border border-[#FCAE17]"></div>
                </div>
                <div className="my-[36px] mx-[24px] text-gray-400 opacity-80">
                  <p className="py-4">
                    Based on the applicable laws of your country, you may have
                    the right to request access to the personal information we
                    collect from you, change that information, or delete it. To
                    request to review, update, or delete your personal
                    information, please fill out and submit a data subject
                    access request.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

const handleScroll = (e, itemId) => {
  e.preventDefault();
  window.scrollTo({
    top:
      window.innerWidth < 992
        ? document.querySelector(itemId).offsetTop - 50
        : document.querySelector(itemId).offsetTop - 100,
    behavior: "smooth",
  });
};

export default handleScroll;
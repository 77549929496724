import React from 'react';
import styled from 'styled-components';

const HowToApply = () => {
  return (
    <Container id='apply'>
      <Header>
        <h1>How to Apply</h1>
        <p>Start your application process today and
          join Afroshop</p>
      </Header>
      <Steps>
        <Step>
          <Number>01</Number>
          <Details>
            <StepTitle>Create an account</StepTitle>
            <Description>
              Start by creating a driver profile, you must be 18+, eligible to work in Canada, and have a vehicle.
            </Description>
          </Details>
        </Step>
        <Step>
          <Number>02</Number>
          <Details>
            <StepTitle>Share some documents</StepTitle>
            <Description>
              Your driver’s license and some other documents would be needed for approval, along with a background check.
            </Description>
          </Details>
        </Step>
        <Step>
          <Number>03</Number>
          <Details>
            <StepTitle>Start earning</StepTitle>
            <Description>
              Once approved, you start receiving orders and earning.
            </Description>
          </Details>
        </Step>
      </Steps>
    </Container>
  );
};

export default HowToApply;

const Container = styled.div`
 
  padding: 112px 70px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 24px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto auto 70px;

  h1 {
    font-size: 39px;
    font-weight: 700;
    line-height: 56px;
    text-align: center;
    margin-bottom: 8px;
    color: #333333;
  }

  p {
    font-size: 16px;
    color: #696969;
    width: 35ch;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
  }

  @media only screen and (max-width: 600px) {
    margin: auto auto 70px;
    margin-bottom: 32px;

    h1 {
      font-size: 25px;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }
  }

`;

const Steps = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 20px;
  
  display:flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Step = styled.div`
  width: 406.67px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Details = styled.div`
  padding-left: 116px;

  @media (max-width: 600px) {
    padding-left: 49.69px
  }
`;
const Number = styled.div`
  font-size: 150px;
  font-weight: 800;
  line-height: 187.5px;
  margin-bottom: -23px;
  color: #000000; 
  opacity: 0.1;

  @media (max-width: 600px) {
    font-size: 70px;
    font-weight: 800;
    line-height: 87.5px;
    text-align: left;
    margin-bottom: -8px;
  }
}
`;

const StepTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  margin-bottom: 8px;
  color: #333333;

  @media (max-width: 600px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
  }
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #7F7F7F;
  width: 28ch;

  @media (max-width: 600px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

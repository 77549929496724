import React, { useState } from "react";
import {
  AfroShopBusinessHeroFormPasswordSet,
  LockIcon,
  PasswordCloseIcon,
  PasswordOpenIcon,
} from "../../../images";
import { Button } from "../../shared";
import InputComponent from "../../shared/inputComponent";

const SetupPassword = ({ form, loading }) => {
  const {
    control,
    formState: { errors },
    register,
  } = form;
  const [viewPassword, setViewPassword] = useState({
    new: false,
    confirm: false,
  });

  const handleViewPassword = (text) => {
    setViewPassword((prevPassword) => ({
      ...prevPassword,
      [text]: !prevPassword[text],
    }));
  };

  return (
    <div className="grid grid-flow-row gap-8 lg:h-[600px] h-[900px]">
      <div className="flex flex-col gap-6">
        <img
          src={AfroShopBusinessHeroFormPasswordSet}
          alt="Password Set"
          className="flex mx-auto"
        />
        <div className="flex flex-col gap-4 w-[290px] lg:w-full mx-auto text-center text-[#333333]">
          <h2 className="font-bold text-[20px]">Almost there!</h2>
          <p>Now set up a password to protect your account</p>
        </div>
        <div>
          <label className="text-[#B3B3B3] text-xs font-normal">Password</label>
          <InputComponent
            inputType=""
            type={viewPassword["new"] ? "text" : "password"}
            label="Password"
            name="password"
            fieldName="password"
            required={true}
            requiredMessage={"This field is required"}
            placeholder={"Enter Password"}
            className="bg-[#F2F2F2] mt-2"
            control={control}
            errors={errors}
            register={register}
            leftIcon={LockIcon}
            rightIcon={
              viewPassword["new"] ? PasswordOpenIcon : PasswordCloseIcon
            }
            onIconClick={() => handleViewPassword("new")}
            iconClassName="cursor-pointer"
            patternValue={
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/
            }
            patternMessage={
              "Password must contain at least one letter, one number, one special character and be at least 8 characters long"
            }
          />
        </div>
        <div>
          <label className="text-[#B3B3B3] text-xs font-normal">
            Confirm Password
          </label>
          <InputComponent
            inputType=""
            type={viewPassword["confirm"] ? "text" : "password"}
            label="Confirm password"
            name="confirmPassword"
            fieldName="confirmPassword"
            required={true}
            requiredMessage={"This field is required"}
            placeholder={"Enter Confirm Password"}
            className="bg-[#F2F2F2] mt-2"
            control={control}
            errors={errors}
            register={register}
            leftIcon={LockIcon}
            rightIcon={
              viewPassword["confirm"] ? PasswordOpenIcon : PasswordCloseIcon
            }
            onIconClick={() => handleViewPassword("confirm")}
            iconClassName="cursor-pointer"
            patternValue={
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/
            }
            patternMessage={
              "Password must contain at least one letter, one number, and be at least 8 characters long"
            }
          />
        </div>
      </div>
      <div className={"self-end h-[40px]"}>
        <Button
          type="submit"
          className={"flex"}
          loading={loading}
          disable={loading}
          size={"full"}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default SetupPassword;

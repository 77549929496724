import React from "react";
import { useDispatch } from "react-redux";
import { activeModal } from "../../redux/action";
import { CancelIcon, GreenCheckbox } from "../../images/access";
import { handleBackdropClick } from "../../helpers/backdropClick";

const EarlyAccessSuccess = () => {
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(activeModal(""));
  };
  return (
    <div className="fixed inset-0 bg-blackTransparent bg-opacity-10 z-50 flex justify-center items-center">
      <div className="md:w-[585px] h-[295px] md:h-[505px] w-full md:py-[24px] md:px-[32px] relative overflow-y-auto md:mt-6 p-4  bg-[#FFFFFF] rounded-lg flex flex-col justify-center">
      <div onClick={(e) => handleBackdropClick(e, closeModal)}>
        <div className="absolute top-[20px] right-[20px] cursor-pointer" onClick={closeModal}>
        <CancelIcon />
        </div>
      </div>
        <div className="flex flex-col justify-center items-center gap-2">
          <GreenCheckbox />
          <h2 className="text-[#186F3D] mt-2 font-bold">Success!</h2>
        </div>
        <p className="text-[#333333] mt-12 text-center">
        Thank You! 🎉 <br /> You have been added to the waitlist and will be informed when the stores go live.
        </p>
      </div>
    </div>
  );
};

export default EarlyAccessSuccess;

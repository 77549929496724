import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "../shared";
import { faqDriverData, faqBusinessData } from "../../data/faq";
import { FaqCloseIcon, FaqOpenIcon } from "../../images";

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const lastItem = pathSegments[pathSegments.length - 1];

  const faqData = lastItem === "drivers" ? faqDriverData : faqBusinessData;

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="flex lg:flex-row flex-col justify-between lg:pl-20 px-8 grid-grid-cols-[30%_70%]" id="faqs">
      <h2 className="text-[#186F3D] text-[20px] font-bold text-center lg:hidden block mb-4">
        Frequently Asked Questions
      </h2>
      <div className="hidden lg:block">
        <h1 className="text-[39px] text-[#333333] font-bold leading-[56px] lg:pt-6 w-[363px]">
          We have 24/7 support available
        </h1>
        <p className="text-[#696969] pt-1">Reach out to us.</p>
        <Button
          className={"mt-7 w-[220px]"}
          variant="tertiary"
          outline="green"
          icon={"green"}
          onClick={() => window.location.href = 'mailto:info@afroshop.ca'}
          
        >
          Contact Support
        </Button>
      </div>
      <div className="w-full lg:max-w-[700px] mx-auto lg:pb-16 pb-8 px-4">
        {faqData.map((faq, index) => (
          <div key={index} className="border-b-[0.5px] border-[#6A6A6A66]">
            <div
              className="cursor-pointer py-8 text-[#333333] font-bold flex justify-between items-center"
              onClick={() => toggleFAQ(index)}
            >
              <span>{faq.question}</span>
              {activeIndex === index ? <FaqOpenIcon className="ml-2"/> : <FaqCloseIcon className="ml-2"/>}
            </div>
            <div
              className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${
                activeIndex === index ? "max-h-screen" : "max-h-0"
              }`}
            >
              <div className="pb-6 text-[#4F4F4F]">{faq.answer}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="block lg:hidden pb-8">
        <h1 className="md:text-[28px] text-[20px] text-[#333333] font-bold leading-[30px]">
          We have 24/7 support available
        </h1>
        <p className="text-[#696969]">Reach out to us.</p>
        <Button
          className={"mt-7 w-full"}
          variant="tertiary"
          outline="green"
          icon={"green"}
          onClick={() => window.location.href = 'mailto:info@afroshop.ca'}
        >
          Contact Support
        </Button>
      </div>
    </div>
  );
};

export default Faqs;

import React, { useState } from "react";
import { BusinessLogo, CloseIcon } from "../../images";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { activeModal } from "../../redux/action";
import SignupForm from "./sign-up-steps/sign-up-form";
import SetupPassword from "./sign-up-steps/setup-password";
import ReceivedSubmission from "./sign-up-steps/submission-received";

const BusinessSignUpMobile = () => {
  const dispatch = useDispatch();
  const form = useForm({ mode: "all" });
  const getActiveModalName = useSelector((state) => state.getActiveModalName);
  const [step, setStep] = useState(1);

  const onSubmit = () => {
    if (step < 3) {
      setStep((prev) => prev + 1);
    }
  };

  return (
    <div
      className={`fixed h-full top-0 left-0 right-0 bg-blackTransparent bg-opacity-10 z-50 ${
        getActiveModalName?.length > 0 ? "flex lg:hidden" : "hidden"
      } justify-center items-center z-[200]`}
      onClick={(e) => {}}
    >
      <div className="bg-white h-[100vh] overflow-y-scroll w-screen p-8">
        <a href="/">
          <img src={BusinessLogo} alt="drivers" className="flex mx-auto" />
        </a>
        <CloseIcon
          alt="Close"
          className="absolute top-4 right-[24px] cursor-pointer"
          onClick={() => {
            dispatch(activeModal(""));
          }}
        />
        {step < 3 && (
          <h2 className="text-[20px] text-[#186F3D] font-bold my-8">
            List your store and get started
          </h2>
        )}
        <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
          {step === 1 && (
           <SignupForm form={form} />
          )}

          {step === 2 && (
           <SetupPassword form={form} />
          )}
          {step === 3 && (
           <ReceivedSubmission />
          )}
        </form>
      </div>
    </div>
  );
};

export default BusinessSignUpMobile;

import React from "react";
import { BENEFIT_DATA } from "../../data";

const DriversBenefitsSection = () => {
  return (
    <section className="bg-[#F4F8F4] w-[90%] rounded-[32px] m-auto mt-16 py-16 px-4 lg:px-24" id="benefits">
      <div className="text-center mb-8">
        <h2 className="text-3xl lg:text-4xl font-bold text-[#333333]">
          Benefits
        </h2>
        <p className="w-[90%] lg:w-[30%] m-auto mt-2 text-[#696969]">
          Why Choose Afroshop, Experience the Perks of Being a Delivery Driver
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {BENEFIT_DATA.map((benefit, index) => (
          <div
            key={index}
            className="flex flex-col items-start bg-white rounded-[15px] border border-[#E6E6E6] p-6"
          >
            <img src={benefit.icon} alt={benefit.title} className="h-[40px] w-[40px]"/>
            <div className="mt-6">
              <h3 className="text-lg lg:text-xl font-bold text-[#333333]">
                {benefit.title}
              </h3>
              <p className="mt-2 text-[#7F7F7F] text-sm lg:text-base">
                {benefit.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DriversBenefitsSection;

export const COOKIE_PREFERENCES = [
    {
        name:"Essential Cookies",
        details:"These cookies are necessary for core website functionality. They cannot be disabled in our system, as they are essential for providing key services you request."
    },
    {
        name:"Functional Cookies",
        details:"These cookies enhance your shopping experience by remembering your preferences, such as language selection or past interactions. While not essential, they help personalize and improve your browsing experience."
    },
    {
        name:"Session Cookies",
        details:"Session cookies help keep your visit smooth by temporarily storing information like login status or shopping cart contents. These cookies expire once you leave the site or close your browser."
    },
    {
        name:"Performance and Analytic Cookies",
        details:"These cookies collect anonymous data on how visitors use our website, helping us analyze traffic, identify trends, and improve performance. They ensure we continue optimizing your shopping experience without compromising your privacy."
    }
]
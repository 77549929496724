export const faqDriverData = [
    {
        question: "What types of African groceries can I find on Afroshop?",
        answer: "Afroshop offers a diverse range of African groceries, including spices, grains, flours, condiments, snacks, beverages, and more. Whether you're looking for traditional ingredients for your favorite recipes or specialty items from specific regions, you'll find a wide selection of products to choose from."
    },
    {
        question: "What are the delivery options available on Afroshop?",
        answer: "Afroshop offers a diverse range of African groceries, including spices, grains, flours, condiments, snacks, beverages, and more. Whether you're looking for traditional ingredients for your favorite recipes or specialty items from specific regions, you'll find a wide selection of products to choose from."
    },
    {
        question: "How long does it take for my order to be delivered?",
        answer: "Afroshop offers a diverse range of African groceries, including spices, grains, flours, condiments, snacks, beverages, and more. Whether you're looking for traditional ingredients for your favorite recipes or specialty items from specific regions, you'll find a wide selection of products to choose from."
    },
    {
        question: "What if I have an issue with my order or need assistance?",
        answer: "Afroshop offers a diverse range of African groceries, including spices, grains, flours, condiments, snacks, beverages, and more. Whether you're looking for traditional ingredients for your favorite recipes or specialty items from specific regions, you'll find a wide selection of products to choose from."
    },
    {
        question: "Do you offer returns or refunds?",
        answer: "Afroshop offers a diverse range of African groceries, including spices, grains, flours, condiments, snacks, beverages, and more. Whether you're looking for traditional ingredients for your favorite recipes or specialty items from specific regions, you'll find a wide selection of products to choose from."
    },
    {
        question: "Can I track my order after it's been placed?",
        answer: "Afroshop offers a diverse range of African groceries, including spices, grains, flours, condiments, snacks, beverages, and more. Whether you're looking for traditional ingredients for your favorite recipes or specialty items from specific regions, you'll find a wide selection of products to choose from."
    },
]

export const faqBusinessData = [
    {
        question: "When I sign up for Afroshop as a business, what can I expect?",
        answer: "When you sign up as a business on Afroshop, you gain access to an online platform that helps you showcase your products and grocery items to a larger customer base. Once approved, you can list your products, set your prices, receive online orders, and use Afroshop’s delivery services to fulfill them."
    },
    {
        question: "How do I sign up for Afroshop if I have multiple store locations?",
        answer: "You can register multiple store locations under one Afroshop merchant account. Each location can have its own inventory, pricing, and delivery settings to better serve customers in different areas. Simply follow the sign-up process and specify your store locations when setting up your profile."
    },
    {
        question: "How do I receive and manage orders from Afroshop?",
        answer: "Once a customer places an order, you will receive a notification via your registered email,  phone number and on your registered Afroshop fulfilment portal. You can review the order details, confirm item availability, and prepare the order for pickup or delivery. The platform allows you to track orders and update their status in real time."
    },
    {
        question: "How often will I get paid for my sales on Afroshop?",
        answer: "Afroshop processes merchant payouts regularly, once a week. Afroshop provides secure payment processing and a detailed breakdown of your earnings."
    },
    {
        question: "Why should I sell my products on Afroshop?",
        answer: "Afroshop gives your business an online presence, making it easier for customers to find and buy your products. It also provides access to delivery services, sales report dashboard, inventory management, secure payment processing, and marketing opportunities to help you grow your sales."
    },
    {
        question: "What types of businesses can sign up with Afroshop?",
        answer: "Afroshop is open to African-Carribean grocery stores, and businesses selling authentic Afro-carribean items or products. Whether you own a physical store or operate virtually, you can register as a merchant and start selling online"
    },
    {
        question: "What African food products and items can I sell on Afroshop?",
        answer: "You can sell a variety of African grocery items, food products and specialty items. You must ensure that all listed products meet quality and regulatory standards."
    },
    {
        question: "Can I change my product prices or update my inventory on Afroshop?",
        answer: "Yes! Afroshop provides an easy-to-use business portal where you can update your product listings, change prices, add new items, and mark products as out of stock when necessary."
    },
    {
        question: "What impacts my store’s visibility in the Afroshop marketplace?",
        answer: "Your store’s visibility depends on factors like product availability, competitive pricing, positive customer reviews, and timely order fulfillment. Keeping your inventory updated and ensuring fast delivery can help improve your ranking on the platform."
    },
]
import { countries } from "../data/countries";

export function getSubregionByISO(isoCode) {
  const country = countries.find(c => c.cca2 === isoCode);
  return country ? country.subregion : "Unknown Subregion";
}

export function getCommonNameByIso(isoCode) {
  const country = countries.find(c => c.cca2 === isoCode);
  return country ? country.name.common : "Unknown";
}

import React, { useEffect, useState } from "react";
import IC_DynamicCart from "../../../images/icons/IC_DynamicCart";
import { ClockIcon, RightGreyIcon, WhiteFilledCart } from "../../../images";
import { getStoreCartSize } from "../../../helpers/cartFunctions";
import { useSelector } from "react-redux";
import { renderValidUrl } from "../../../utils/constants";
import { formatOpeningDays, convertTimeFormat } from "../../../helpers";
import { useParams } from "react-router-dom";
import { countries } from "../../../data/countries";

function StoreCard({
  card,
  onClick,
  isBorder,
  noArrow,
  full,
  cartIcon = false,
}) {
  const { name } = useParams();
  const cart = useSelector((state) => state.cart);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    setQuantity(getStoreCartSize(cart, card?.id));
  }, [cart, card]);

  // Format opening days
  const formattedOpeningDays = formatOpeningDays(
    card.openDays,
    card.openingTimes
  );

  const countryIsoCodes = countries?.reduce((acc, country) => {
    acc[country?.name?.common] = country?.cca2;
    return acc;
  }, {});

  const isoCode = countryIsoCodes[card?.address?.country];


  const Flag = ({ className = "", isoCode }) => (
    <span
      className={`fi fi-${isoCode} fib mr-2 rounded-full h-[20px] w-[20px] ${className}`}
    />
  );

  return (
    <div className="relative">
      {quantity !== 0 && cartIcon === true && (
        <div className="absolute right-[-10px] top-[-12px] flex bg-[#186F3D] rounded-full p-2">
          <IC_DynamicCart
            quantity={quantity}
            className="justify-center text-white"
          />
        </div>
      )}
      <div
        className={`w-full ${full ? `lg:w-full md:w-full` : `lg:w-[395.33px] md:w-[342px]`
          } h-[164px] bg-white flex items-center rounded ${!isBorder
            ? `shadow-[0px_4px_8px_rgba(51,51,51,0.16)]`
            : `border border-1 border-[#E6E6E6]`
          } py-8 pb-4 lg:pb-8 px-4 justify-between`}
        onClick={onClick}
      >
        <div className="relative lg:w-[100px] lg:h-[100px] w-[50px] h-[50px] object-cover">
          <img
            src={renderValidUrl(card.image)}
            alt=""
            className="w-full h-full rounded-[50%] object-cover"
          />
        </div>

        <div className="ml-6 md:ml-3 flex-1">
          <div className="flex items-center justify-between">
            <div
              className={`rounded-full min-w-fit text-[10px] leading-[15px] py-2 px-[0.8rem] md:px-2.5 lg:px-4 ${card.openDays && card.openingTimes && card.openDays.length > 0
                ? card.openDays.length > 0 &&
                  formatOpeningDays(card.openDays, card.openingTimes) !==
                  "Closed"
                  ? "bg-[rgba(24,111,61,0.1)] text-[#186F3D]"
                  : "bg-[rgba(255,59,48,0.1)] text-[#FF3B30]"
                : "bg-[rgba(255,59,48,0.1)] text-[#FF3B30]"
                }`}
            >
              {card.openDays && card.openingTimes && card.openDays.length > 0
                ? formatOpeningDays(card.openDays, card.openingTimes)
                : "Closed"}
            </div>
            <p className="text-[#B3B3B3] text-[10px] leading-[10px]">
              {card?.distance && card.distance !== null && card.distance.text
                ? `${card.distance.text} away`
                : ""}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <h3
                className={`text-[16px] md:text-[18px] lg:text-[25px] text-[#333333] font-bold leading-10 mt-2 truncate md:overflow-hidden sm:max-w-[220px] max-w-[190px]`}
              >
                {card?.name}
              </h3>
              <div className="flex items-center">
                <ClockIcon className="mr-[0.3rem]" />
                <p
                  className={`rounded-full text-[10px] leading-[15px] text-[#186F3D]`}
                >
                  {formattedOpeningDays === "Closed"
                    ? "Not Delivering"
                    : `Delivery from ${convertTimeFormat(
                      card?.deliveryTime.from
                    )} - ${convertTimeFormat(card?.deliveryTime.to)}`}
                </p>
              </div>
              {!name && (
                <div className="h-[44px] flex flex-wrap gap-3 items-center">
                  {card?.countries?.split(",")?.map((country, key) => (
                    <span
                      key={key}
                      className={`w-5 h-5 rounded-full overflow-hidden fi fi-${country?.toLowerCase()} fib`}
                    ></span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        {!noArrow && <RightGreyIcon className="block lg:hidden absolute right-[0px]" />}
      </div>
    </div>
  );
}

export default StoreCard;

import React, { useState } from 'react';
import { Button } from "../../shared";
import { AfroshopLogo, CloseIcon, StoreImage } from '../../../images';
import { useDispatch } from 'react-redux';
import { activeModal, postRequest } from '../../../redux/action';
import InputComponent from '../../shared/inputComponent';
import { useForm } from 'react-hook-form';
import useToast from '../../toast/useToast';

function NewStores() {
  const dispatch = useDispatch();
  const showToast = useToast();

  const [loading, setLoading] = useState(false)
  const { control, handleSubmit, formState: { errors }, register } = useForm({ mode: "onSubmit" });

  const handleModalClose = () => {
    dispatch(activeModal(''));
  };

  const handleFormSubmit = async (data) => {
    setLoading(true)
    try {
      const [success, responseData] = await postRequest(
        `/api/store-suggestions?user=customer`,
        data
      );

      if (!success || responseData?.error) {
        throw new Error(
          responseData?.error?.message || "An error occurred, please try again"
        );
      }
      showToast(
        "success",
        `Thanks for Submitting ${data.name}. While we reach out to them, kindly explore the rest of our stores for the best African Goods.`,
        "top-right",
        5000
      );
      handleModalClose();
    } catch (error) {
      showToast(
        "error",
        `${error?.message || "An error occured please try again"
        }`,
        "top-right",
        5000
      );
    } finally {
      setLoading(false);
    }
  };

  const formData = [
    { label: "Store Name", placeholder: "Enter", fieldName: "name" },
    { label: "Store Location", placeholder: "Enter", fieldName: "location" },
    { label: "Store Phone Number", placeholder: "Enter", fieldName: "phoneNumber" },
  ];

  return (
    <div
      className='fixed inset-0 bg-blackTransparent bg-opacity-10 z-50 flex justify-center items-center'
    >
      <div className='md:w-[585px] sm:w-auto w-[95%] md:px-[48px] overflow-y-auto overflow-hidden py-[32px] px-[20px] bg-[#FFFFFF] rounded-lg flex flex-col justify-center items-center gap-[8px] md:gap-[15px]'>

        {/* Close Icon */}
        <CloseIcon
          alt="Close"
          className="cursor-pointer self-end"
          onClick={handleModalClose}
        />

        {/* Store Image and Description */}
        <div className='flex flex-col gap-[8px] items-center'>
          <img src={StoreImage} className='h-[75px] md:h-[100px]' alt="Store" />
          <h4 className='text-[#186F3D] text-[16px] font-bold md:text-[20px] md:leading-[32px] leading-[24px] text-center'>
            Suggest a store for our platform
          </h4>
          <p className='text-[#696969] mt-[16px] text-[12px] md:text-[16px] md:leading-[24px] leading-[18px] text-center'>
            Help us expand our offerings by recommending your favorite local stores.
          </p>
        </div>

        {/* Divider */}
        <hr className='border-[#E6E6E6] border-dashed w-full md:w-[489px] my-[4px] md:my-[12px]' />

        {/* Form */}
        <form onSubmit={handleSubmit(handleFormSubmit)} className="w-full flex flex-col gap-[16px] md:gap-[24px] items-center">
          {formData.map(({ fieldName, label, placeholder }) => (
            <div key={fieldName} className="w-full">
              {/* Label for Input */}
              <label htmlFor={fieldName} className="block text-sm text-[#B3B3B3] mb-[8px]">{label}</label>

              {/* Input Component */}
              <InputComponent
                type="text"
                placeholder={placeholder}
                fieldName={fieldName}
                control={control}
                errors={errors}
                register={register}
                requiredMessage="This is required"
              />
            </div>
          ))}

          {/* Submit Button */}
          <Button className="md:w-[400px] w-[290px]" type="submit" loading={loading}>Submit</Button>
        </form>
      </div>
    </div>
  );
}

export default NewStores;

import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, BusinessLogo } from "../../images";
import { useForm } from "react-hook-form";
import SignupForm from "./sign-up-steps/sign-up-form";
import SetupPassword from "./sign-up-steps/setup-password";
import ReceivedSubmission from "./sign-up-steps/submission-received";
import { BusinessPage } from "../../pages";
import useSignUp from "./useSignUp";

const MobileBusinessSignup = () => {
  const navigate = useNavigate();
  const form = useForm({ mode: "all", defaultValues: {} });
  const { onSubmit, loading, step, setStep } = useSignUp();

  const handleBack = () => {
    if (step === 1) {
      navigate(-1)
    }
    if (step === 3) {
      navigate("/business")
    } else {
      setStep((prev) => prev - 1);
    }
  };

  return (
    <div className={""} onClick={(e) => { }}>
      <div className="bg-white h-[100vh] overflow-y-scroll w-screen p-8 lg:hidden">
        <a href="/business">
          <img src={BusinessLogo} alt="drivers" className="flex mx-auto" />
        </a>
        <div className="flex justify-center items-center">
          <h2 className="text-[20px] text-[#186F3D] font-bold my-8">
            List your store and get started
          </h2>

          <div
            className="flex items-center absolute top-[20px] right-0 pr-8"
            onClick={handleBack}
          >
            <ArrowLeft
              className="mr-2 cursor-pointer text-sm"
              alt="arrow-left"
            />
            <p
              aria-label="Back"
              className="text-sm font-normal text-[#333333] cursor-pointer"
            >
              Go Back
            </p>
          </div>
        </div>

        <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
          {step === 1 && <SignupForm form={form} />}
          {step === 2 && <SetupPassword form={form} loading={loading} />}
          {step === 3 && <ReceivedSubmission />}
        </form>
      </div>
      <div className="hidden lg:block">
        <BusinessPage />
      </div>
    </div>
  );
};

export default MobileBusinessSignup;

import React from "react";
import { PaymentIcon, SettingsIcon, ShopIcon, SupportIcon } from "../../images";


const Benefits = () => {
    return (
        <div className="bg-[#F2F9F1] rounded-[32px] sm:p-[70px] px-[16px] py-[24px] sm:mx-[70px] mx-[24px]" id="benefits">
            <h2 className="text-[39px] leading-[56px] font-bold text-[#333333] text-center">Benefits</h2>
            <p className="text-[16px] leading-[24px] text-[#696969] text-center">Why Choose Our Platform</p>

            <div className="mt-[70px] flex sm:flex-row flex-col sm:justify-between sm:flex-wrap">

                <div className="bg-[#FFFFFF] sm:w-[47.5%] w-full sm:mb-[48px] mb-[24px] py-[24px] px-[32px] rounded-[15px] border border-[#FFFFFF]">
                    <div className="w-[40px] h-[40px] ">
                        <img src={SettingsIcon} alt="settings" />
                    </div>
                    <h5 className="text-[20px] leading-[32px] font-bold text-[#333333] mt-[24px]">Seamless Integration</h5>
                    <p className="text-normal text-[#7F7F7F] mt-2">Easily connect your existing inventory and sales channels.</p>
                </div>

                <div className="bg-[#FFFFFF] sm:w-[47.5%] w-full sm:mb-[48px] mb-[24px] py-[24px] px-[32px] rounded-[15px] border border-[#FFFFFF]">
                    <div className="w-[40px] h-[40px] ">
                        <img src={ShopIcon} alt="shop" />
                    </div>
                    <h5 className="text-[20px] leading-[32px] font-bold text-[#333333] mt-[24px]">Customizable Storefront</h5>
                    <p className="text-normal text-[#7F7F7F] mt-2">Create a unique online store that reflects your brand.</p>
                </div>

                <div className="bg-[#FFFFFF] sm:w-[47.5%] w-full sm:mb-[48px] mb-[24px] py-[24px] px-[32px] rounded-[15px] border border-[#FFFFFF]">
                    <div className="w-[40px] h-[40px] ">
                        <img src={PaymentIcon} alt="payment" />
                    </div>
                    <h5 className="text-[20px] leading-[32px] font-bold text-[#333333] mt-[24px]">Secure Payments</h5>
                    <p className="text-normal text-[#7F7F7F] mt-2">A safe and secure checkout experience for your customers.</p>
                </div>

                <div className="bg-[#FFFFFF] sm:w-[47.5%] w-full sm:mb-[48px] mb-[24px] py-[24px] px-[32px] rounded-[15px] border border-[#FFFFFF]">
                    <div className="w-[40px] h-[40px] ">
                        <img src={SupportIcon} alt="support" />
                    </div>
                    <h5 className="text-[20px] leading-[32px] font-bold text-[#333333] mt-[24px]">24/7 Support</h5>
                    <p className="text-normal text-[#7F7F7F] mt-2">Get expert assistance from our dedicated support team.</p>
                </div>


            </div>
        </div>
    )
}


export default Benefits;
import React from "react";
import InputComponent from "../shared/inputComponent";


const DriversFormInput = ({ name, placeholder, patternValue, icon, register, errors, value, handleChange, maxLength }) => {
    return (
        <div className="sm:w-[32.5%] w-full mb-6">
            <label className="block font-normal text-[13px] leading-[23px] text-[#B3B3B3] mb-2">{name}</label>

            <InputComponent
                placeholder={placeholder}
                type={name === "Email" ? "email" : "text"}
                fieldName={name.replaceAll(" ", "-")}
                maxLength={maxLength}
                required={true}
                requiredMessage={'This field is required'}
                patternValue={patternValue}
                patternMessage={`Enter Valid ${name}`}
                register={register}
                errors={errors}
                leftIcon={icon}
                value={value}
                handleChange={handleChange}
                className=""
            />
        </div>
    )
}


export default DriversFormInput;
import React from "react";
import { Tick } from "../../../images";
import { Button } from "../../shared";


const DriversSignupSuccess = () => {
  const userEmail = localStorage.getItem("userEmail");

  return (
    <div>    
      <div>
        <h3 className="font-bold text-green text-[20px] lg:text-[31px] pb-[12px]">
          Drive with Afroshop
        </h3>
        <p className="text-[13px] lg:text-[16px] text-gray-400 pb-[40px]">
          Unlock Delivery Opportunities with AfroShop! <br /> Submit your details to be
          eligible to deliver african groceries with us.
        </p>
      </div>
      <div className="flex flex-col items-center px-[24px] py-[32px] mb-[48px]">
        <img src={Tick} alt="tick" />
        <h5 className="py-[24px] font-bold text-gray-400 text-[20px]">Sign Up Successful!</h5>
        <p className="text-center text-[16px] text-gray-400 px-0 lg:px-[24px]">
          You've successfully signed up as a driver with Afroshop. We'll be
          conducting a background check to ensure the safety of our passengers.
          You'll get an email with instructions. Be ready to submit documents.
          We'll update you.
        </p>
      </div>

      <Button className="w-full"   onClick={() => window.location.href = `mailto:${userEmail}`}>Go to Email</Button>
    </div>
  );
};

export default DriversSignupSuccess;

export const DUMMY_TESTIMONIALS = [
    {
      name: "Fatou Diallo, Grocery Store Owner",
      location: "Toronto, Ontario, Canada",
      text: "Afroshop has completely transformed my business! I used to rely on walk-in customers, but now, I get steady online orders from people looking for authentic African groceries. The platform is easy to use, and sales have never been better!",
      image: "https://res.cloudinary.com/eshy/image/upload/v1740088646/afrogirl1_pbpy8e.png",
      rating: 4,
    },
    {
      name: "Chidi Okafor, Meat Market Owner",
      location: "Toronto, Ontario, Canada",
      text: "Selling through Afroshop has brought more customers to my meat market than I ever imagined! People love that they can order fresh, high-quality cuts online, and I love how seamless the ordering process is. It’s a win-win",
      image: "https://res.cloudinary.com/eshy/image/upload/v1729007704/Rectangle_9_ekr47u.png",
      rating: 5,
    },
    {
      name: "Esther Mensah, Grocery Store Owner",
      location: "Toronto, Ontario, Canada",
      text: "Thanks to Afroshop, I no longer worry about slow days at my store. Customers can now order online, and I get a steady flow of sales. The exposure and convenience have helped my business grow beyond my local community!",
      image: "https://res.cloudinary.com/eshy/image/upload/v1740088654/afrogirl2_b0onvk.png",
      rating: 5,
    },
    {
      name: "Kwame Boateng, Meat Market Owner",
      location: "Toronto, Ontario, Canada",
      text: "Afroshop has made it easier for customers to find and order fresh, high-quality meat from my shop. The platform connects me with buyers who truly appreciate authentic African cuts, and business has never been better!",
      image: "https://res.cloudinary.com/eshy/image/upload/v1740088638/afroboy_dnnkp3.png",
      rating: 5,
    },
  ];
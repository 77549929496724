import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { DUMMY_TESTIMONIALS } from "../../data";

import {
  GreenQuote,
  RoundedRightGreenArrow,
  RoundedGreenArrow,
  GreyStar,
  WhiteStar,
} from "../../images";

const Testimonials = () => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const isDesktop = useMediaQuery({ minWidth: 768 });

  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const lastItem = pathSegments[pathSegments.length - 1];

  const title = lastItem === "drivers" ? 'What our drivers say' : 'What our store partners say';

  const settings = {
    dots: false,
    arrows: false,
    swipeToSlide: true,
    overflowX: "scroll",
    whiteSpace: "nowrap",
    infinite: false,
    speed: 900,
    slidesToShow: 1.5,
    afterChange: setCurrentSlide,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "0",
        },
      },
    ],
  };

  const nextSlide = () => {
    sliderRef?.current?.slickNext();
  };

  const prevSlide = () => {
    sliderRef?.current?.slickPrev();
  };

  useEffect(() => {
    const leftArrow = document.querySelector("#arrow_left");
    const rightArrow = document.querySelector("#arrow_right");
    if (leftArrow && rightArrow) {
      if (currentSlide === 0) {
        leftArrow.style.display = "none";
      } else {
        leftArrow.style.display = "block";
      }

      if (
        currentSlide === (DUMMY_TESTIMONIALS.length - (isDesktop ? 1.5 : 1))
      ) {
        rightArrow.style.display = "none";
      } else {
        rightArrow.style.display = "block";
      }
    }
  }, [currentSlide, DUMMY_TESTIMONIALS.length, isDesktop]);

  return (
    <section className="py-16 mb-16 relative" id="testimonials">
      <h2 className="text-4xl font-bold text-center mb-2 text-[#333333]">
        Testimonials
      </h2>
      <p className="text-center mb-12 text-[#696969]">{title}</p>

      <div className="w-[90%] m-auto mt-[5rem] lg:mt-0">
        <Slider ref={sliderRef} {...settings}>
          {DUMMY_TESTIMONIALS.map((testimonial, index) => (
            <div
              key={index}
              className="rounded-[32px] !flex flex-col-reverse lg:flex-row overflow-hidden !w-[95%] mx-4 bg-[#186F3D] lg:bg-transparent"
            >
              <div className="bg-[#186F3D] flex flex-col py-8 lg:pb-20 px-6 lg:px-10 lg:w-[70%]">
                <img
                  src={GreenQuote}
                  alt="quote-icon"
                  className="w-[104px] h-[104px] mb-4 ml-auto !hidden lg:!block"
                />
                <div className="flex space-x-1">
                  {[...Array(5)].map((_, i) => (
                    <img
                      key={i}
                      src={i < testimonial.rating ? WhiteStar : GreyStar}
                      alt="star"
                      className="w-6 h-6"
                    />
                  ))}
                </div>
                <p className="mt-4 text-white">{testimonial.text}</p>
                <div className="flex items-center mt-6">
                  <div>
                    <p className="font-bold text-white">{testimonial.name}</p>
                    <p className="text-sm text-white">{testimonial.location}</p>
                  </div>
                </div>
              </div>
              <div className="h-auto">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className=" w-[90%] m-auto lg:m-0 px-2 py-4 lg:p-0 lg:w-full h-full object-cover rounded-[32px] lg:rounded-l-[0px] filter grayscale-[100%]"
                />
              </div>
            </div>
          ))}
        </Slider>
        <div className="absolute bottom-[-2rem] right-[1rem] lg:right-[7rem] flex space-x-4 z-10">
          <img
            onClick={prevSlide}
            className="cursor-pointer"
            id="arrow_left"
            src={RoundedGreenArrow}
            alt="arrow-left"
          />

          <img
            onClick={nextSlide}
            className="cursor-pointer"
            id="arrow_right"
            src={RoundedRightGreenArrow}
            alt="arrow-right"
          />
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

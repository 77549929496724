import React from 'react'
import { useDispatch } from 'react-redux';
import { addToast } from '../../redux/action';

const useToast = () => {
  const dispatch = useDispatch();

    const showToast = (type, message, position = 'top-right', timeout = 1000) => {
      dispatch(addToast({ type, message, position, timeout }));
    };
  
    return showToast;
  };


export default useToast
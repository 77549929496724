import React, { useState } from "react";
import { BorderTop, GreenUpload } from "../../images";
import UploadedDocument from "./uploaded-document";


const UploadDocument = ({ name, list, handleUploads, documentUpload }) => {

    const [selectedFile, setSelectedFile] = useState(null)

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            handleUploads(name, file.type)
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
        }
        
    };

    const ids = name.replaceAll(" ", "-").toLowerCase();


    const removeFile = () => {
        setSelectedFile(null);
        document.getElementById(ids).value = '';
    }



    return (
        <li className="mb-8">
            <div className="flex sm:flex-row flex-col justify-between items-center">
                <div className="sm:w-[53%] w-full flex justify-between items-center">
                    <p className="">{name}</p>
                    <div>
                        <label htmlFor={ids} className="cursor-pointer">
                            <div className="flex justify-center items-center gap-2 border border-[#186F3D] rounded w-[140px] h-[40px] text-[#186F3D]">
                                <GreenUpload className="" />
                                <span>Upload</span>
                            </div>
                        </label>
                        <input type="file" id={ids} name={ids} className="hidden" onChange={handleFileChange} />
                    </div>
                </div>

                {selectedFile && <UploadedDocument filename={selectedFile.name} removeFile={removeFile} documentUpload={documentUpload} name={name} />}
            </div>

            <BorderTop className={`w-full my-6 sm:hidden ${(list < 4) ? "block" : "hidden"}`} />
        </li>
    )
}


export default UploadDocument;
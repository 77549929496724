import React from "react";
import { Convenient, LandingImage, RealTime, Seamless } from "../../../images";

const About = () => {

  const cards =
    [{
      img: Convenient,
      heading: "Convenient Shopping",
      text: "Explore a variety of stores to choose from."
    }, {
      img: Seamless,
      heading: "Seamless Delivery",
      text: "Order your items and enjoy delivery to your doorstep.s"
    }, {
      img: RealTime,
      heading: "Real-Time Tracking",
      text: "Keep tabs on your orders with real-time tracking."
    },]

  return (
    <section className="w-full flex flex-col justify-center items-center gap-[40px] mx-auto px-[10px] xl:px-[0px] lg:max-w-[1300px]">
      <h1 className="text-[20px] lg:text-[39px] text-[#186F3D] lg:max-w-[681px] font-extrabold leading-tight text-center underline decoration-transparent inline-flex flex-col">
        The largest Afro-carribean grocery marketplace in Canada
      </h1>
      <div className="relative w-full h-342px max-w-[1300px] rounded-[24px] lg:rounded-[30px] overflow-hidden lg:h-auto">
        <img
          src={LandingImage}
          className="w-full h-full object-cover"
          alt="Shopping"
        />
      </div>
      <div className="w-full flex flex-row justify-between flex-wrap gap-[16px] xl:gap-[40px]">
        {
          cards.map(({ heading, img, text }, index) => (
            <div key={index} className="flex items-center justify-center max-w-[320px] xl:max-w-[660px] w-full h-[223px] xl:w-[406.67px] xl:h-[280px] bg-[#F2F2F280] mx-auto px-[10px] xl:px-[60px] rounded-[24px] xl:rounded-[30px] flex-col gap-[16px] xl:gap-[32px]">
              <img className="w-[32px] h-[32px] xl:w-[64px] xl:h-[64px]" src={img} alt={heading} />
              <h2 className="text-[#186F3D] text-[16px] xl:text-[20px] font-bold text-center">{heading}</h2>
              <p className="text-center text-[13px] xl:text-[16px] text-[#4F4F4F]">{text}</p>
            </div>
          ))
        }
      </div>
    </section >
  );
};

export default About;

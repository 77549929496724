import React, { useEffect, useState } from "react";
import { DeleteIcon, WhiteCancel, cancelIcon } from "../../images";


const UploadedDocument = ({ filename, removeFile, documentUpload, name }) => {

    const [stateOfUploads, setStateOfUploads] = useState(documentUpload[name]);
    const [isAnimating, setIsAnimating] = useState(true);


    // updating stateOfUploads based on changes to documentUpload
    useEffect(() => {
        setStateOfUploads(documentUpload[name]);

        if (documentUpload[name] === "Progress") {
            // setIsAnimating(true);

            setTimeout(() => {
                setIsAnimating(false);
            }, 5)
        }
    }, [documentUpload[name]]);


    const stateOfUpload = (stateOfUpload) => {
        switch (stateOfUpload) {
            case "Completed":
                return "bg-[rgba(255,59,48,0.1)]";
            case "Progress":
                return "bg-[#F2F2F2]";
            case "Error":
                return "bg-[#FF3B30]";
            default:
                return "bg-[#F2F2F2]";
        }
    };

    const stateOfUploadBorder = (stateOfUpload) => {
        switch (stateOfUpload) {
            case "Completed":
                return "border-[#186F3D]";
            case "Progress":
                return "border-[#CCCCCC]";
            case "Error":
                return "border-[#FF3B30]";
            default:
                return "border-[#CCCCCC]";
        }
    };

    const filenameUsed = filename.replace(filename.substring(filename.lastIndexOf(".")), "").slice(0, 20) + ".." + filename.substring(filename.lastIndexOf("."))
    return (
        <div className="h-[40px] sm:w-[34%] w-[88vw] sm:ml-0 -ml-4 sm:mt-0 mt-4">
            <div className={`flex items-center px-4 h-full w-full  sm:mt-0 border ${stateOfUploadBorder(stateOfUploads)} rounded relative`}>
                <div className="flex justify-between items-center w-full">
                    <p>{filename.length > 24 ? filenameUsed : filename}</p>

                    <div className={`w-[24px] h-[24px] ${stateOfUpload(stateOfUploads)} flex justify-center items-center rounded-full cursor-pointer`} onClick={removeFile}>
                        {stateOfUploads === "Completed" && <DeleteIcon className="w-[16px] h-[16px]" />}
                        {stateOfUploads === "Progress" && <img src={cancelIcon} alt="" className="w-[16px] h-[16px]" />}
                        {stateOfUploads === "Error" && <WhiteCancel className="w-[16px] h-[16px]" />}
                    </div>
                </div>

                <div className="w-full absolute bottom-[-2px] left-0">
                    {stateOfUploads === "Progress" &&
                        <div className={`transition-[width] duration-[2000ms] ease-linear ${isAnimating ? "w-[20px]" : "w-full"} `}>
                            <div className={`w-full h-[4px] rounded-[10px] bg-[#186F3D]`}></div>
                        </div>
                    }
                </div>
            </div>

            {stateOfUploads === "Error" &&
                <p className="text-[10px] leading-[15px] text-[#FF3B30]">This document is not supported, please delete and upload another file</p>
            }
        </div>
    )
}

export default UploadedDocument;
import React, { useState, useEffect, useCallback, useRef } from "react";
import InputComponent from "../shared/inputComponent";
import _ from "lodash";
import { StyledList, StyledListItem } from "../shared/inputComponent/placeInput";
import { LoadingAnimation } from "../../images";
import { testPlace } from "../../redux/action";
import { getTokenFromCookie } from "../../utils/constants";


function AddressComponent({ name, form, defaultAddress, setAddressField, showLabel = true, setValid }) {
    const [address, setAddress] = useState(defaultAddress ?? "")
    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef()

    const [fetchingAddress, setFetching] = useState(false)
    const [suggestions, setSuggestions] = useState([])
    const [highlightedIndex, setIndex] = useState(null)

    const token = getTokenFromCookie()

    const {
        register,
        formState: { errors },
        clearErrors,
    } = form;

    const handleAddressFetch = _.debounce(async (value) => {
        setFetching(true);
        try {
            const [success, data] = await testPlace(value, token)
            if (success) {
                setSuggestions([data.address])
                setIndex(0)
            }
            else {
                setSuggestions([])
            }
        } catch (error) {
            console.error(error);
            setSuggestions([])
        } finally {
            setFetching(false);
        }
    }, 1000);

    const handleAddressChange = (value) => {
        setAddress(value)
        setValid(false)
        clearErrors(name)
        if (address.length > 3) {
            setIsOpen(true)
            handleAddressFetch(value)
        }
        else {
            setIsOpen(false)
        }
    }

    const handleAddressSelect = (value) => {
        setAddress(value)
        setValid(true)
        setAddressField(value)
        setIsOpen(false)
        setFetching(false)
        setSuggestions([])
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (isOpen) {
                if (e.key === "ArrowDown") {
                    e.preventDefault()
                    // Move down the list, looping back to the start if at the bottom
                    setIndex((prevIndex) =>
                        prevIndex === suggestions.length - 1 ? 0 : prevIndex + 1
                    );
                } else if (e.key === "ArrowUp") {
                    e.preventDefault()
                    // Move up the list, looping to the end if at the top
                    setIndex((prevIndex) =>
                        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
                    );
                } else if (e.key === "Enter") {
                    e.preventDefault()
                    // When Enter is pressed, select the highlighted option
                    if (suggestions[highlightedIndex]) {
                        handleAddressSelect(suggestions[highlightedIndex]);
                    }
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            // Cleanup the event listener on unmount
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [highlightedIndex, isOpen, suggestions]);

    return (
        <form
            className="sm:w-full mb-4"
        >
            {
                showLabel &&
                <label
                    htmlFor="address"
                    className="capitalize block text-[13px] leading-[23px] text-[#b3b3b3] font-normal mb-2"
                >
                    Address
                </label>
            }

            <InputComponent
                placeholder="Enter Address"
                type="text"
                fieldName={name}
                required={true}
                requiredMessage={"This field is required"}
                register={register}
                errors={errors}
                value={address}
                patternValue={/^.+$/}
                patternMessage={`Enter Valid Address`}
                handleChange={(e) => {
                    handleAddressChange(e.target.value);
                }}
                className=""
            />
            <div className="relative">
                <div className="mb-4 text-start absolute bg-white rounded px-2 pt-2 w-full">
                    <StyledList
                        className="custom-scrollbar py-5"
                        open={isOpen}
                    >
                        {
                            suggestions?.map((data, index) => {
                                return (
                                    <StyledListItem
                                        ref={ref}
                                        key={index}
                                        className={`${index === highlightedIndex
                                            ? "bg-[#f1f1f1] text-[#186F3D]"
                                            : ""
                                            } `}
                                        id={data}
                                        onMouseEnter={(e) => {
                                            setIndex(index);
                                        }}
                                        onClick={(_e) =>
                                            handleAddressSelect(data)
                                        }
                                    >
                                        {data}
                                    </StyledListItem>
                                );
                            })
                        }
                        {
                            fetchingAddress ? (
                                <div className="flex justify-center py-5">
                                    <LoadingAnimation color={"#186F3D"} />
                                </div>
                            ) :
                                suggestions?.length === 0 && (
                                    <StyledListItem
                                        onClick={() => setIsOpen(false)}
                                        className="italic text-grey-409 text-center"
                                    >
                                        Keep typing ... we haven't found the address yet.
                                    </StyledListItem>
                                )
                        }
                    </StyledList>
                </div>
            </div>
        </form>
    );
}

export default AddressComponent
import React from "react";
import Testimonials from "../drivers/testimonial";
import Faqs from "../drivers/faqs";
import Benefits from "./benefits";
import Features from "../business/features";
import HeroSection from "./hero";

const Business = () => {
  return (
    <div className="w-full h-full flex flex-col gap-[60px] md:gap-[80px] large-screen lg:pt-[6rem] max-w-[1440px] mx-auto">
      <HeroSection />
      <Benefits />
      <Features />
      <Testimonials />
      <Faqs />
    </div>
  );
};

export default Business;

import React from "react";
import { BlackUserIcon, BlackEmailIcon, BlackNumberIcon, BlackPhone, DateIconBlack } from "../../images";
import useInput from "../../hooks/useInput";
import DriversFormInput from "./form-input";


const DriversFormInputs = ({ register, errors }) => {

    const phoneInput = useInput("", "number");
    const firstNameInput = useInput("", "text");
    const lastNameInput = useInput("", "text");
    const sinInput = useInput("", "number");
    const dateInput = useInput("", "date");

    return (
        <div className="flex flex-wrap justify-between">
            <DriversFormInput
                name={"First Name"}
                placeholder="Enter First Name"
                icon={BlackUserIcon}
                register={register}
                errors={errors}
                value={firstNameInput.value}
                handleChange={(e) => firstNameInput.handleInputChange(e)}
            />

            <DriversFormInput
                name={"Last Name"}
                placeholder="Enter Last Name"
                icon={BlackUserIcon}
                register={register}
                errors={errors}
                value={lastNameInput.value}
                handleChange={(e) => lastNameInput.handleInputChange(e)}
            />

            <DriversFormInput
                name={"Email"}
                placeholder="Enter Email"
                patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
                icon={BlackEmailIcon}
                register={register}
                errors={errors}
            />

            <DriversFormInput
                name={"Phone"}
                placeholder="Enter Phone Number"
                patternValue={/^\d{10,13}$/}
                icon={BlackPhone}
                register={register}
                errors={errors}
                value={phoneInput.value}
                handleChange={(e) => phoneInput.handleInputChange(e)}
            />

            <DriversFormInput
                name={"Date of Birth"}
                placeholder="DD/MM/YYYY"
                patternValue={/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/}
                icon={DateIconBlack}
                register={register}
                errors={errors}
                value={dateInput.value}
                handleChange={(e) => dateInput.handleInputChange(e)}
                maxLength={10}
            />

            <DriversFormInput
                name={"Social Insurance Number (SIN)"}
                placeholder="Enter"
                patternValue={/^\d{17}$/}
                icon={BlackNumberIcon}
                register={register}
                errors={errors}
                value={sinInput.value}
                handleChange={(e) => sinInput.handleInputChange(e)}
                maxLength={17}
            />
        </div>
    )
}

export default DriversFormInputs;
import { ADD_TOAST, REMOVE_TOAST } from "./action";

const PRIVATE_INITIAL_STATE = {
  addresses: [],
  orders: [],
  orderOutForPayment: null,
  isAuthenticated: false,
  user: null,
};

const INITIAL_STATE = {
  ...PRIVATE_INITIAL_STATE,
  userInfoTab: "Profile",
  filters: {},
  cart: {},
  checkoutStore: "",
  getActiveModalName: "",
  loginGuest: false,
  editInfoTab: false,
  showUserForm: false,
  editAddressForm: false,
  products: {},
  stores: [],
  productCategories: [],
  orderID: null,
  location: "",
  referrals: "",
  announcementBannerData: null,
  toasts: {
    messages: [], // Toasts is initialized as an array
  }
};

export const reducer = (previousState = INITIAL_STATE, action) => {
  let newCart = null;
  let storeID = null;
  let uniqueID = null;

  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...previousState,
        isAuthenticated: true,
        user: action.payload,
      };
    case "LOG_OUT":
      return {
        ...previousState,
        ...PRIVATE_INITIAL_STATE,
      };
    case "SET_ERROR":
      return {
        ...previousState,
        error: action.error,
        isFetching: false,
      };
    case "SET_LOCATION":
      return {
        ...previousState,
        location: action.location,
      };
    case "GET_API_REQUEST":
      return {
        ...previousState,
        ...action.hash,
        isFetching: false,
      };
    case "ADD_TO_CART":
      if (!action.store || !action.store.name || !action.store.id) {
        console.error(
          "Invalid store information in addToCart action:",
          action.store
        );
        return previousState;
      }

      // Update these lines to correctly form the storeID
      storeID = `${action.store.id}`;
      uniqueID = `${action.data.SKU}`;

      return {
        ...previousState,
        cart: {
          [storeID]: {
            ...previousState.cart[storeID],
            ...action.store,
            items: {
              [uniqueID]: {
                ...action.data,
                quantity:
                  (previousState.cart[storeID]?.items?.[uniqueID]?.quantity ||
                    0) + 1,
                category: action.category,
                disabled: false,
              },
              ...previousState.cart[storeID]?.items,
            },
            disabled: false,
          },
          ...Object.fromEntries(
            Object.entries(previousState.cart).filter(
              ([key]) => key !== storeID
            )
          ), // Move the store to the top
        },
      };

    case "HANDLE_CART":
      newCart = { ...previousState.cart };
      storeID = action.storeID;
      uniqueID = action.uniqueID;
      let checkoutStore = previousState.checkoutStore;

      try {
        switch (action.operation) {
          case "INCREMENT":
            newCart[storeID].items[uniqueID].quantity += 1;
            break;
          case "DECREMENT":
            newCart[storeID].items[uniqueID].quantity -= 1;
            if (newCart[storeID].items[uniqueID].quantity === 0) {
              delete newCart[storeID].items[uniqueID];
            }
            if (Object.keys(newCart[storeID].items).length === 0) {
              delete newCart[storeID];
              checkoutStore = "";
            }
            break;
          case "ADJUST":
            newCart[storeID].items[uniqueID].quantity = action.availability;
            if (newCart[storeID].items[uniqueID].quantity === 0) {
              delete newCart[storeID].items[uniqueID];
            }
            if (Object.keys(newCart[storeID].items).length === 0) {
              delete newCart[storeID];
              checkoutStore = "";
            }
            break;
          case "REMOVE":
            delete newCart[storeID].items[uniqueID];
            if (Object.keys(newCart[storeID].items).length === 0) {
              delete newCart[storeID];
              checkoutStore = "";
            }
            break;
          default:
            console.error("not a valid operation");
        }
      } catch (error) {
        console.error(error.message);
        console.error("item not in cart");
      }

      if (action.operation === "INCREMENT") {
        return {
          ...previousState,
          cart: {
            [storeID]: {
              ...newCart[storeID],
              items: {
                ...newCart[storeID]?.items,
              },
            },
            ...Object.fromEntries(
              Object.entries(newCart).filter(([key]) => key !== storeID)
            ),
          },
        };
      } else {
        return {
          ...previousState,
          cart: newCart,
          checkoutStore: checkoutStore,
        };
      }
    case "CHECKOUT_STORE":
      return {
        ...previousState,
        checkoutStore: action.checkoutStore,
      };
    case "CLEAR_STORE":
      newCart = { ...previousState.cart };
      delete newCart[action.storeID];
      return {
        ...previousState,
        cart: newCart,
      };
    case "UPDATE_CART":
      if (action.inplace) {
        return {
          ...previousState,
          cart: { ...action.payload }, // replace the old cart and the new cart
        };
      } else {
        return {
          ...previousState,
          cart: { ...previousState.cart, ...action.payload }, // overlap the old cart and the new cart
        };
      }
    case "CLEAR_CART":
      return {
        ...previousState,
        cart: INITIAL_STATE.cart,
      };
    case "UPDATE_FILTERS":
      return {
        ...previousState,
        filters: action.filters,
      };
    case "CLEAR_FILTERS":
      return {
        ...previousState,
        filters: {},
      };
    case "USER_INFO_TAB":
      return {
        ...previousState,
        userInfoTab: action.userInfoTab,
      };
    case "ACTIVE_MODAL":
      return {
        ...previousState,
        getActiveModalName: action.getActiveModalName,
      };
    case "GUEST_LOGIN":
      return {
        ...previousState,
        loginGuest: action.loginGuest,
      };
    case "GUEST_LOGOUT":
      return {
        ...previousState,
        loginGuest: false,
      };
    case "EDIT_INFO_TAB":
      return {
        ...previousState,
        editInfoTab: action.editInfoTab,
      };
    case "UNDO_INFO_TAB":
      return {
        ...previousState,
        editInfoTab: false,
      };
    case "SHOW_FORM":
      return {
        ...previousState,
        showUserForm: action.showUserForm,
      };
    case "HIDE_FORM":
      return {
        ...previousState,
        showUserForm: false,
      };
    case "SHOW_FORM_EDIT":
      return {
        ...previousState,
        editAddressForm: action.editAddressForm,
      };
    case "HIDE_FORM_EDIT":
      return {
        ...previousState,
        editAddressForm: false,
      };
    case "UPDATE_USER":
      return {
        ...previousState,
        user: action.payload,
      };
    case "CHECKOUT_ORDER":
      return {
        ...previousState,
        orderOutForPayment: action.checkoutOrder,
      };
    case "RESET":
      return INITIAL_STATE;

    case ADD_TOAST:
      return {
        ...previousState,
        toasts: {
          ...previousState.toasts,
          messages: [...previousState.toasts.messages, action.payload],
        },
      };
    case REMOVE_TOAST:
      return {
        ...previousState,
        toasts: {
          ...previousState.toasts,
          messages: previousState.toasts.messages.filter(
            (toast) => toast.id !== action.id
          ),
        },
      };
    // other cases
    default:
      return previousState;
  }
};

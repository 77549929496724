import React, { useState } from "react";
import { CloseIcon, Menu, BusinessLogo } from "../../images";
import { Button } from "../shared";
import { useNavigate } from "react-router-dom";

const NavLink = ({ href, children }) => (
  <li>
    <a
      href={href}
      className="text-[16px] leading-[24px] text-[#333333] cursor-pointer"
    >
      {children}
    </a>
  </li>
);

const DesktopNav = () => (
  <ul className="hidden lg:flex gap-[40px] w-auto items-center">
    <NavLink href="#benefits">Benefits</NavLink>
    <NavLink href="#features">Features</NavLink>
    <NavLink href="#testimonials">Testimonials</NavLink>
    {/* <NavLink href="#howToJoin">How to Join</NavLink> */}
    <NavLink href="#faqs">FAQs</NavLink>
    {/* <li>
      <Button
        className="md:flex lg:mr-8 md:mr-4 h-[40px]"
        variant="primary"
        onClick={() =>
          (window.location.href = "https://afroshop-admin.vercel.app/login")
        }
      >
        Log In
      </Button>
    </li> */}
  </ul>
);

const MobileNav = ({ onClose }) => {
  const navigate = useNavigate();
  const onSignUpClick = (e) => {
    e?.stopPropagation();
    navigate("/business/signup");
  };

  return (
    <div className="bg-white w-full h-[100vh] absolute left-0 right-0 z-10 py-[32px] px-[24px] lg:hidden block">
      <CloseIcon
        alt="Close"
        className="absolute right-[24px] cursor-pointer"
        onClick={onClose}
      />
      <ul className="w-full flex flex-col gap-4 items-center text-[16px] leading-[24px] text-[#1E1E1E] mt-4">
        <NavLink href="#benefits">Benefits</NavLink>
        <NavLink href="#features">Features</NavLink>
        <NavLink href="#testimonials">Testimonials</NavLink>
        {/* <NavLink href="#howToJoin">How to Join</NavLink> */}
        <NavLink href="#faqs">FAQs</NavLink>
        {/* <li className="w-full">
          <Button
            className="h-[40px] w-full"
            variant="primary"
            onClick={() =>
              (window.location.href = "https://afroshop-admin.vercel.app/login")
            }
          >
            Log In
          </Button>
        </li> */}
        <li className="w-full" onClick={onSignUpClick}>
          <Button className="h-[40px] w-full" variant="primary">
            Sign Up
          </Button>
        </li>
      </ul>
    </div>
  );
};

const BusinessNav = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <nav className="sm:py-[24px] sm:px-[70px] py-[12px] px-[24px] w-full sm:h-[92px] h-[68px] flex justify-between fixed bg-white z-[10] shadow">
      <a href="/business">
        <img src={BusinessLogo} alt="drivers" />
      </a>
      <DesktopNav />
      <Menu
        alt="menu"
        className="lg:hidden block cursor-pointer"
        onClick={() => setMobileNavOpen(true)}
      />
      {mobileNavOpen && <MobileNav onClose={() => setMobileNavOpen(false)} />}
    </nav>
  );
};

export default BusinessNav;

import React from "react";
import { FEATURES } from "../../data";

const Feature = () => {
  return (
    <div className="px-[24px] md:px-[70px]" id="features">
      <div className="text-center pb-[70px]">
        <h1 className="text-gray-400 font-bold text-[39px]">Features</h1>
        <p className="text-[18px] text-gray-900">
          Key features of our platform
        </p>
      </div>
      <div>
        {FEATURES.map((feature, index) => (
          <div
            className={` items-center justify-center gap-[40px] lg:p-[70px] lg:bg-gray-200 rounded-[32px] mb-[70px] text-left flex flex-wrap`}
            key={index}
          >
            <div
              className={` w-full lg:w-[45%] ${
                index === 1 ? "lg:order-2 sm:order-1" : ""
              }`}
            >
              <h2 className="text-[20px] lg:text-[31px] pb-[23px] font-bold text-green">
                {feature.title}
              </h2>
              <p className="text-[16px] lg:text-[24px] text-gray-400">
                {feature.description}
              </p>
            </div>
            <div
              className={`w-full lg:w-[45%] px-[16px] sm:px-[0px] ${
                index === 1 ? "lg:order-1 sm:order-2" : ""
              }`}
            >
              <img src={feature.image} alt="Responsive feature image" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Feature;

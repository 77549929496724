import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../shared";
import { Broccoli, JumboDriver, OrangeLine, User1, User2, User3 } from "../../images";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const DriversJumbo = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-[#FFFAF0] flex sm:flex-row flex-col justify-between sm:p-[80px] py-[40px] px-[24px] items-center gap-[45px] relative">
            <div className="sm:w-1/2 w-full text-center sm:text-left relative">
                <h1 className="sm:text-[48px] text-[31px] leading-[45px] sm:leading-[56px] text-[#186F3D] sm:font-extrabold font-bold mb-[24px]">Join Afroshop and Deliver Happiness to Every Door!</h1>
                <img src={OrangeLine} alt="orange-line" className="w-[455px] absolute sm:top-[25%] top-[20%]"/>
                <p className="sm:text-[20px] text-[16px] leading-[32px] text-[#696969] sm:mb-[48px]">Become a delivery driver and earn more with Afroshop.</p>

                <Button className="md:flex lg:mr-8 md:mr-4 sm:w-[141px] w-full sm:mb-0 mb-[40px]" variant="primary" onClick={() => navigate("/signup")}>Sign Up</Button>

            </div>

            <div className="h-full sm:w-1/2 w-full relative">
                <img src={JumboDriver} alt="Driver with goods" className="sm:h-[651px] h-[399px] w-full sm:w-[557px]" />

                <div className="flex justify-center items-center gap-1 absolute right-[10%] top-[38%] z-2 border-[0.5px] border-[#FFFFFF] w-[121px] h-[48px] rounded-[100px] bg-[rgba(255,255,255,0.1)]">
                    <p className="text-[#FFFFFF] font-semibold text-[16px] leading-[24px]">So Fresh</p>
                    <img src={Broccoli} alt="broccoli" className="w-[24px] h-[24px]" />
                </div>

                <div className="sm:p-[24px] p-[11px] sm:min-h-[327px] min-h-[151px] w-[120px] sm:w-[261px] bg-[#ffffff] absolute sm:left-[-25%] -left-[5%] sm:top-[35%] -top-[5%] z-2 rounded-[20px] flex flex-col sm:gap-4 gap-1">
                <div className="sm:min-h-[85px] h-[40px] sm:p-[16px] p-[7px] border-[0.5px] border-[#E6E6E6] rounded-[10px] flex sm:gap-4 gap-[6px] items-start">
                    <img src={User1} alt="user-profile" className="sm:w-[40px] sm:h-[40px] w-[18px] h-[18px]" />
                    <div className="sm:text-[6px] text-[3px] w-full">
                        <p className="font-bold sm:text-[16px] sm:leading-[25px] text-[7px] leading-[11px] text-[#186F3D] sm:mb-[8px] mb-[4px]">$26.87</p>
                        <Skeleton count={2} />
                    </div>
                </div>
                <div className="sm:min-h-[85px] h-[40px] sm:p-[16px] p-[7px] border-[0.5px] border-[#E6E6E6] rounded-[10px] flex sm:gap-4 gap-[6px] items-start">
                    <img src={User2} alt="user-profile" className="sm:w-[40px] sm:h-[40px] w-[18px] h-[18px]" />
                    <div className="sm:text-[6px] text-[3px] w-full">
                        <p className="font-bold sm:text-[16px] sm:leading-[25px] text-[7px] leading-[11px] text-[#186F3D] sm:mb-[8px] mb-[4px]">$10.99</p>
                        <Skeleton count={2} />
                    </div>
                </div>
                <div className="sm:min-h-[85px] h-[40px] sm:p-[16px] p-[7px] border-[0.5px] border-[#E6E6E6] rounded-[10px] flex sm:gap-4 gap-[6px] items-start">
                    <img src={User3} alt="user-profile" className="sm:w-[40px] sm:h-[40px] w-[18px] h-[18px]" />
                    <div className="sm:text-[6px] text-[3px] w-full">
                        <p className="font-bold sm:text-[16px] sm:leading-[25px] text-[7px] leading-[11px] text-[#186F3D] sm:mb-[8px] mb-[4px]">$32.79</p>
                        <Skeleton count={2} />
                    </div>
                </div>
            </div>

            </div>
          
        </div>
    )
}

export default DriversJumbo;
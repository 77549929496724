import React, { useEffect, useRef, useState, useMemo } from "react";
import { afroCarribeanCountries } from "../../../data/countries";
import { InputBox } from "./inputStyles";
import Checkbox from "../checkbox";
import { OutSideClick } from "../../../helpers";
import { getCommonNameByIso } from "../../../helpers/getCountriesSubregion";
import { CloseIcon } from "../../../images";
import { Controller } from "react-hook-form";

const Flag = ({ className = "", isoCode, handleClick }) => (
    <span
        className={`fi fi-${isoCode} fib mr-2 rounded-full h-[20px] w-[20px] ${className}`}
        onClick={handleClick}
    />
)

const FlagPole = ({ isoCode, handleCheckboxChange }) => {
    return (
        <div
            className="rounded-full bg-white flex items-center text-[10px] leading-[15px] px-5 py-[8px]"
            onClick={(e) => { e.stopPropagation(); }}
        >
            <Flag isoCode={isoCode.toLowerCase()} className="h-[14px] w-[14px]" />
            <span>{getCommonNameByIso(isoCode)}</span>
            <CloseIcon
                alt="Close"
                className="cursor-pointer h-[12px] w-[12px] text-[#000000] ml-2"
                onClick={() => handleCheckboxChange(isoCode)}
            />
        </div>
    )
}

const MultiSelectCountryDropdown = ({ placeholder = "Select countries", className, control, setValue, getValues }) => {
    const [selectedCountries, setSelectedCountries] = useState(
        getValues("countries") ? getValues("countries").split(",") : []
    );
    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState("");
    const [highlightedIndex, setHighlightedIndex] = useState(0);

    const ref = useRef();
    const boxOutsideClick = OutSideClick(ref);

    const inputRef = useRef(null)
    const containerRef = useRef(null);
    const listRef = useRef(null);

    const cachedCountryData = useMemo(() => afroCarribeanCountries, []);

    const filteredCountries = useMemo(() => {
        return cachedCountryData.filter((country) =>
            country.name.common.toLowerCase().includes(filter)
        );
    }, [cachedCountryData, filter]);

    useEffect(() => {
        if (boxOutsideClick) {
            document.body.style.overflow = "";
            setIsOpen(false);
        }
    }, [boxOutsideClick]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [selectedCountries]);

    // Toggle dropdown visibility
    const toggleDropdown = () => setIsOpen((prev) => !prev);

    // Handle checkbox change
    const handleCheckboxChange = (countryIsoCode) => {
        const newSelectedCountries = selectedCountries.includes(countryIsoCode)
            ? selectedCountries.filter((code) => code !== countryIsoCode)
            : [...selectedCountries, countryIsoCode];
        if (filter.length > 0 && newSelectedCountries.length > selectedCountries.length) {
            setFilter("")
        }
        setSelectedCountries(newSelectedCountries);
        setValue("countries", newSelectedCountries.join(","));
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
        setHighlightedIndex(0)
        if (e.target.value.length > 0) {
            setIsOpen(true)
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            const nextIndex = Math.min(highlightedIndex + 1, filteredCountries.length - 1);
            setHighlightedIndex(nextIndex);

            if (listRef.current) {
                // Find the element for the highlighted index
                const highlightedElement = document.getElementById(`country-item-${nextIndex}`);
                if (highlightedElement) {
                    // Scroll the container to the highlighted element
                    const container = listRef.current;
                    const elementTop = highlightedElement.offsetTop;
                    const elementHeight = highlightedElement.clientHeight;

                    // Scroll to position the element correctly
                    container.scrollTop = elementTop - container.offsetTop - container.clientHeight + elementHeight;
                }
            }
        } else if (e.key === "ArrowUp") {
            const nextIndex = Math.max(highlightedIndex - 1, 0);
            setHighlightedIndex(nextIndex);
            if (listRef.current) {
                listRef.current.scrollTop = Math.max(0, listRef.current.scrollTop - 60);
            }
        } else if (e.key === "Enter" && filteredCountries.length > 0) {
            e.preventDefault();
            const selectedCountry = filteredCountries[highlightedIndex];
            handleCheckboxChange(selectedCountry.cca2);
            setFilter("")
        } else if (e.key === "Backspace" && selectedCountries.length > 0 && filter.length === 0) {
            e.preventDefault();
            handleCheckboxChange(selectedCountries[selectedCountries.length-1]);
        }
    };

    return (
        <Controller
            control={control}
            name="countries"
            defaultValue=""
            render={({ field }) => (
                <div className={`relative ${className}`} ref={ref} onSubmit={(e) => { e.preventDefault(); }}>
                    {/* Placeholder/Dropdown Toggle */}
                    <InputBox
                        onClick={toggleDropdown}
                        customStyles={{
                            height: '100%',
                            minHeight: '53px',
                        }}
                        className="cursor-pointer items-center"
                        aria-expanded={isOpen}
                        aria-controls="country-dropdown-menu"
                    >
                        <div ref={containerRef} className="flex flex-wrap gap-2 max-h-[120px] overflow-auto custom-scrollbar">
                            {
                                selectedCountries?.map((country) => (
                                    <FlagPole
                                        isoCode={country}
                                        key={country}
                                        handleCheckboxChange={handleCheckboxChange}
                                    />
                                ))
                            }
                            <input
                                type="text"
                                ref={inputRef}
                                className={`rounded focus:outline-none flex-grow min-w-[50px] bg-transparent border-none outline-none text-blackplaceholder-text-[#333333] ${(selectedCountries?.length > 0 && filter?.length === 0) ? "" : "opacity-[70%]"}`}
                                placeholder={selectedCountries.length > 0 ? "" : placeholder}
                                value={filter}
                                onChange={handleFilterChange}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <span
                            className="ml-auto"
                            style={{
                                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                                transformOrigin: "right",
                                transition: "transform 0.3s ease",
                            }}
                        >
                            <svg
                                height="20"
                                width="20"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                focusable="false"
                                className="css-tj5bde-Svg"
                            >
                                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                            </svg>
                        </span>

                    </InputBox>

                    {/* Dropdown Menu */}
                    {isOpen && (
                        <div
                            className="absolute border rounded bg-white shadow mt-1 w-full max-h-60 overflow-auto z-1 mt-2 custom-scrollbar"
                            ref={listRef}
                        >
                            {
                                filteredCountries?.map((country, index) => {
                                    const checked = selectedCountries.includes(country.cca2)
                                    const handleChecked = () => handleCheckboxChange(country.cca2)

                                    return (
                                        <div
                                            key={country.cca2}
                                            id={`country-item-${index}`}
                                            className={`flex items-center p-2 hover:bg-gray-100 w-full cursor-pointer ${highlightedIndex === index ? "bg-gray-100" : ""}`}
                                        >
                                            <Checkbox
                                                isChecked={checked}
                                                handleChange={() => { handleChecked(); }}
                                            />
                                            <span className='flex-1' onClick={handleChecked}>{country.name.common}</span>
                                            <Flag className="ml-auto" handleClick={handleChecked} isoCode={country.cca2.toLowerCase()} />
                                        </div>
                                    )
                                })
                            }
                            {
                                filteredCountries?.length === 0 && filter.length > 0 && (
                                    <div className="p-6 text-[#333333] opacity-[50%] text-center">No countries found.</div>
                                )
                            }
                        </div>
                    )}
                </div>
            )
            } />
    );
};

export default MultiSelectCountryDropdown;
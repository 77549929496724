import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  App,
  NewApp,
  OrderSuccessPage,
  UserInfoPage,
  EarlyAccessPage,
  Store,
  ConfirmOrderPage,
  PageNotFound,
  DriversApplicationPage,
  NewPasswordPage,
  ResetPasswordSuccessPage,
  PrivacyPolicyPage,
  DriversPage,
  BusinessPage,
  DriversSignupSuccessPage,
  DriversSignupPage
} from "./pages";
import RedirectHandler from "./components/redirectHandler";
import MobileBusinessSignup from "./components/business/mobile-business-signup";

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/early-access" element={<EarlyAccessPage />} />
        {/* <Route exact path="/order-success/" element={<App />} />
        <Route exact path="/ref/:code" element={<App />} />
        <Route exact path="/order-success/:orderID" element={<OrderSuccessPage />} />
        <Route path="/confirm-order" element={<ConfirmOrderPage />} />
        <Route exact path="/user-info" element={<UserInfoPage />} />
        <Route exact path="/store/:name" element={<Store />} />
        <Route exact path="/reset-password" element={<NewPasswordPage />} />
        <Route exact path="/reset-password-success" element={<ResetPasswordSuccessPage />} /> */}
        <Route exact path="/drivers-application" element={<DriversApplicationPage />} />
        {/* <Route path="/connect/:provider/redirect" element={<RedirectHandler />} /> */}
        <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route exact path="/drivers" element={<DriversPage />} />
        <Route exact path="/business" element={<BusinessPage />} />
        <Route exact path="/signup-success" element={<DriversSignupSuccessPage />} />
        <Route exact path="/signup" element={<DriversSignupPage />} />
        <Route exact path="/business/signup" element={<MobileBusinessSignup />} />
        <Route exact path="/404" element={<PageNotFound />} />
        <Route exact path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;


import { useLayoutEffect } from "react";
import {
  SEO,
  Business,
  BusinessLayout,
} from "../components";

function BusinessPage() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  useLayoutEffect(() => {
    scrollToTop()
  }, [])
  
  return (
    <>
      <SEO
        url="/business"
        openGraphType="website"
        schemaType="website"
        title="Afroshop"
        description="Business Landing Page"
      />
      <BusinessLayout>
        <Business />
      </BusinessLayout>
    </>
  );
}

export default BusinessPage;

export const startTypes = [
    {
        label: "Physical",
        value: "Physical"
    },
    {
        label: "Virtual",
        value: "Virtual"
    },
];

export const businessTypes = [
    {
        label: "Grocery Store",
        value: "Grocery Store"
    },
    {
        label: "Bakery and Confectionary",
        value: "Bakery and Confectionary"
    },
    {
        label: "Beauty Store",
        value: "Beauty Store"
    },
    {
        label: "Wholesale",
        value: "Wholesale"
    },
    {
        label: "Shoes and Apparel",
        value: "Shoes and Apparel"
    },
];
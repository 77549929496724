import React, {useRef} from 'react'
import CookieConsent from "react-cookie-consent";
import { Button } from '../../shared';
import { cancelIcon } from '../../../images';
import { useDispatch } from 'react-redux';
import { activeModal } from '../../../redux/action';
import Cookies from "js-cookie";
import { SELECTED_LOCATION } from '../../../utils/constants';

const CookiePopUp = () => {
    const cookiePopupRef = useRef();
    const dispatch = useDispatch();

    const  acceptClicked = () => {
        cookiePopupRef.current.accept();
        const savedCity = Cookies.get(SELECTED_LOCATION);
      if (!savedCity) {
        dispatch(activeModal("deliveryAvailability"))
      }
      }
    
      const  rejectClicked = ()  => {
        cookiePopupRef.current.decline();
        const savedCity = Cookies.get(SELECTED_LOCATION);
        if (!savedCity) {
          dispatch(activeModal("deliveryAvailability"))
        }
      }

  return (
    <div>
        <CookieConsent
        ref={cookiePopupRef}
        overlay={true}
        disableButtonStyles={true}
        buttonText=" "
        containerClasses="lg:mb-[4%] w-full md:max-w-[600px] lg:max-w-[1000px] md:ml-[15%] text-base border rounded shadow"
        cookieName="Afroshop_Cookie"
        style={{ backgroundColor: "white" }}
      >
        <div className="flex flex-col gap-4 md:p-5">        
          <img src={cancelIcon} alt="cancelIcon" className="lg:ml-[98%] ml-[95%] w-6 h-6" onClick={rejectClicked}/>
        
          <div className="text-[#333333]">
            <p>
            Welcome to Afroshop! We use cookies to improve your shopping & delivery experience. By using this website, you agree to our use of cookies in accordance with our{" "}
             {/* <a href="/privacy-policy" target='_blank' className="underline text-[#186F3D]">
                 Privacy Policy
              </a> */}
            </p>
          </div>

          <div className="flex flex-col lg:flex-row items-center gap-6 lg:justify-between   ">
           <div className="flex gap-5">
            <div>
              <Button className="w-[160px] h-[40px]" variant="primary" 
              onClick={acceptClicked}
              >
                  Accept All
              </Button>
              </div>
              <div>
              <Button
                 outline="green"
                 variant="tertiary"
                 className="w-[160px] h-[40px]"           
                 onClick={rejectClicked}
              >
                Reject All
              </Button>
              </div>              
            </div>

            <div>
             <Button outline="white" variant="tertiary" onClick={()=>dispatch(activeModal("manageCookies"))} className="lg:flex underline text-[#186F3D]">
                <b>Manage Cookies</b>
              </Button>
            </div>
          </div>
        </div>
        </CookieConsent>
    </div>
  )
}

export default CookiePopUp
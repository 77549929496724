import React from "react";

const IC_DynamicCart = (props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M18.19 21.75H7.53999C6.54999 21.75 5.59999 21.33 4.92999 20.6C4.25999 19.87 3.92 18.89 4 17.9L4.83 7.94C4.86 7.63 4.74999 7.33001 4.53999 7.10001C4.32999 6.87001 4.04 6.75 3.73 6.75H2C1.59 6.75 1.25 6.41 1.25 6C1.25 5.59 1.59 5.25 2 5.25H3.74001C4.47001 5.25 5.15999 5.56 5.64999 6.09C5.91999 6.39 6.12 6.74 6.23 7.13H18.72C19.73 7.13 20.66 7.53 21.34 8.25C22.01 8.98 22.35 9.93 22.27 10.94L21.73 18.44C21.62 20.27 20.02 21.75 18.19 21.75ZM6.28 8.62L5.5 18.02C5.45 18.6 5.64 19.15 6.03 19.58C6.42 20.01 6.95999 20.24 7.53999 20.24H18.19C19.23 20.24 20.17 19.36 20.25 18.32L20.79 10.82C20.83 10.23 20.64 9.67001 20.25 9.26001C19.86 8.84001 19.32 8.60999 18.73 8.60999H6.28V8.62Z"
        fill="white"
      />
      <path
        d="M16.25 26.75C15.15 26.75 14.25 25.85 14.25 24.75C14.25 23.65 15.15 22.75 16.25 22.75C17.35 22.75 18.25 23.65 18.25 24.75C18.25 25.85 17.35 26.75 16.25 26.75ZM16.25 24.25C15.97 24.25 15.75 24.47 15.75 24.75C15.75 25.03 15.97 25.25 16.25 25.25C16.53 25.25 16.75 25.03 16.75 24.75C16.75 24.47 16.53 24.25 16.25 24.25Z"
        fill="white"
      />
      <path
        d="M8.25 26.75C7.15 26.75 6.25 25.85 6.25 24.75C6.25 23.65 7.15 22.75 8.25 22.75C9.35 22.75 10.25 23.65 10.25 24.75C10.25 25.85 9.35 26.75 8.25 26.75ZM8.25 24.25C7.97 24.25 7.75 24.47 7.75 24.75C7.75 25.03 7.97 25.25 8.25 25.25C8.53 25.25 8.75 25.03 8.75 24.75C8.75 24.47 8.53 24.25 8.25 24.25Z"
        fill="white"
      />
      <path
        d="M21 12.75H9C8.59 12.75 8.25 12.41 8.25 12C8.25 11.59 8.59 11.25 9 11.25H21C21.41 11.25 21.75 11.59 21.75 12C21.75 12.41 21.41 12.75 21 12.75Z"
        fill="white"
      />
      <circle cx="20" cy="8" r="7.5" fill="#186F3D" stroke="white" />
      <text
        x="20"
        y="12"
        textAnchor="middle"
        fontFamily="Lexend"
        fontSize="10px"
        fill="#FFFFFF"
      >
        {props?.quantity}
      </text>
    </svg>
  );
};

export default IC_DynamicCart;

import { useLayoutEffect } from "react";
import {
    SEO,
    Drivers,
    DriversLayout,
  } from "../components";
  
  function DriversPage() {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    useLayoutEffect(() => {
      scrollToTop()
    }, [])

    return (
      <>
        <SEO
          url="/drivers"
          openGraphType="website"
          schemaType="website"
          title="Afroshop"
          description="Drivers Landing Page"
        />
        <DriversLayout>
          <Drivers />
        </DriversLayout>
      </>
    );
  }
  
  export default DriversPage;

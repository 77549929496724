import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import axios from 'axios';
import "./index.css";
import MyRoutes from "./Routes";
import { store } from './redux/store';
import ToastWrapper from "./components/toast/ToastWrapper";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <MyRoutes />
      <ToastWrapper />
    </React.StrictMode>
  </Provider>
);

import { Analytics, Order, Product, BusinessFeature1Image, BusinessFeature2Image, BusinessFeature3Image } from "../images";

export const FEATURES = [
  {
    title: "Product Management",
    description:
      "Manage your inventory, pricing, and product descriptions effortlessly.",
    image: BusinessFeature1Image,
  },
  {
    title: "Order Fulfillment",
    description: "Streamline your order processing and shipping.",
    image: BusinessFeature2Image,
  },
  {
    title: "Analytics & Reporting",
    description:
      "Track your sales performance and identify growth opportunities.",
    image: BusinessFeature3Image,
  },
];

import React from "react";
import { AfroshopDriversLogo, BigDriversLogo, Person } from "../../../images";
import { Link } from "react-router-dom";

const DriverAuthLayout = ({ children }) => {
  return (
    <div className="p-[24px] lg:p-[70px] flex gap-[100px] justify-center items-start w-full bg-white">
      <section className="w-[600px]">
        <div className="pb-[53px] hidden lg:block">
          <Link to="/drivers">
            <img src={BigDriversLogo} alt="drivers" />
          </Link>
        </div>
        <div className="pb-[53px] flex justify-center lg:hidden">
          <Link to="/drivers">
            <img src={AfroshopDriversLogo} alt="drivers" />
          </Link>
        </div>
        {children}
      </section>
      <section className="w-[600px] h-[884px] hidden lg:block">
        <img src={Person} alt="person" className="h-full"/>
      </section>
    </div>
  );
};

export default DriverAuthLayout;

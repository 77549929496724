import {
  RoundedGreenTime, RoundedGreenDots, RoundedGreenFile
} from "../images";

export const BENEFIT_DATA = [
  {
    icon: RoundedGreenTime, 
    title: "Flexible Hours",
    description: "Work when it suits you, whether it’s full-time or part-time.",
  },
  {
    icon: RoundedGreenFile, 
    title: "Competitive Pay",
    description: "Earn a competitive hourly rate plus tips.",
  },
  {
    icon: RoundedGreenDots, 
    title: "Serve Your Community",
    description: "Make a positive impact on your local community.",
  },
];
import React from "react";
import { Button } from "../../shared";
import { SubmissionReceived } from "../../../images";

const ReceivedSubmission = () => {
  return (
    <div className="grid grid-flow-row grid-[max-content_fit] text-center lg:h-[600px] h-[900px]">
      <div className="flex flex-col gap-6 mt-28">
        <img
          src={SubmissionReceived}
          className="w-[300px] h-[200px] flex mx-auto"
          alt="Submission received"
        />
        <h2 className="text-[20px] font-bold text-[#333333]">
          Store Submission Received!
        </h2>
        <p className="sm:w-[403px] flex mx-auto text-[#7F7F7F]">
          Thanks for submitting your store to Afroshop! 🎉Your submission is now
          under review. We'll notify you once it's been approved.
        </p>
      </div>
      {/* <div className={"self-end h-[40px]"}>
        <Button
          type="submit"
          size={"full"}
          onClick={() =>
            (window.location.href = "https://afroshop-admin.vercel.app/login")
          }
        >
          Log in
        </Button>
      </div> */}
    </div>
  );
};

export default ReceivedSubmission;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { RadioButton } from "../../shared";
import { ArrowLeft } from "../../../images";
import Tips from "./tip";
import useToast from "../../toast/useToast";

const InputNumber = styled.input`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StoreTip = ({ setTip, subTotal, tipAmount, setTipAmount, tipType, setTipType, }) => {
    const [otherTip, setOtherTip] = useState(false);
    const [percentTip, setPercentTip] = useState(10)

    const showToast = useToast();

    const handleClickedOther = (e) => {
        e.stopPropagation()
        setOtherTip(true)
    }

    useEffect(() => {
        let newTip = 0
        try {
            if (tipAmount > 1500 || (subTotal > 0 && tipAmount / subTotal > 500 * 100)) {
                throw new Error('We limit tips to $1500 or 500% of subTotal.')
            }

            if (tipType === "percent" && otherTip) {
                setTipAmount(percentTip)
            }

            if (tipAmount === "") {
                newTip = 0;
            } else if (tipType === "amount") {
                newTip = parseFloat(tipAmount);
            } else {
                newTip = parseFloat(tipAmount / 100 * subTotal)
            }

        } catch (error) {
            newTip = 0
            console.error(error.message)
            showToast('error', error.message, 'top-right', 3000);
        }
        setTip(newTip)
    }, [tipAmount, tipType, otherTip])

    const handleReset = () => {
        setOtherTip(false)
    }

    useEffect(() => {
        setTipAmount(0)
    }, [tipType])

    return (
        <>
            <p className="font-bold textt-[#333333] text-[16px] leading-[25px] mb-4 cursor-pointer flex gap-2" onClick={() => handleReset()}>
                {otherTip && <ArrowLeft />}
                Add a Tip</p>
            <div className="flex gap-6 mb-4">
                <RadioButton
                    checked={tipType === "amount"}
                    name="amount"
                    id="amount"
                    handleChange={() => setTipType("amount")}
                >
                    <label htmlFor="amount">Amount</label>
                </RadioButton>

                <RadioButton
                    checked={tipType === "percent"}
                    name="percent"
                    id="percent"
                    handleChange={() => setTipType("percent")}
                >
                    <label htmlFor="percent">Percentage</label>
                </RadioButton>


            </div>

            <div className={`flex gap-1 justify-center items-center ${otherTip ? "" : "hidden"}`}>
                <div className="w-[50px] h-[53px] rounded bg-[#186F3D] flex items-center justify-center text-[#ffffff]">
                    {tipType === "amount" ? "$" : "%"}
                </div>
                <InputNumber
                    type="number"
                    name="otherTip"
                    className="w-full h-[53px] items-center border rounded focus:outline-none focus:border-solid focus:border-[#186F3D] bg-[#F2F2F2] text-[#333333] py-2 px-2"
                    onChange={(e) => {
                        setTipAmount(e.target.value);
                        tipType === "percent" && setPercentTip(e.target.value)
                    }}
                    value={tipType === "amount" ? tipAmount : percentTip}
                />


            </div>

            <div className={`flex justify-between mt-2 ${otherTip ? "hidden" : ""}`}>
                <Tips
                    tipAmount={tipAmount}
                    setTipAmount={setTipAmount}
                    tipType={tipType}
                    handleClickedOther={handleClickedOther} />
            </div>
        </>)
}

export default StoreTip
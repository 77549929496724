import { SEO, PageLayout, DriversApplication } from "../components";


function DriversApplicationPage() {
  return (
    <>
      <SEO
        url="/drivers-application"
        openGraphType="website"
        schemaType="website"
        title="Afroshop"
        description=""
      />

      <PageLayout>
        <DriversApplication />
      </PageLayout>
    </>
  );
}

export default DriversApplicationPage;

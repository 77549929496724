const convertTimeFormat = (time) => {
  if(!time) return
  const [hours, minutes] = time?.split(":");
  const parsedHours = parseInt(hours, 10);
  const period = parsedHours >= 12 ? "pm" : "am";
  const formattedHours = parsedHours % 12 === 0 ? 12 : parsedHours % 12;

  return `${formattedHours}:${minutes} ${period}`;
};

export default convertTimeFormat;
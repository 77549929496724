import React from "react";
import InputComponent from "../../shared/inputComponent";
import { Button } from "../../shared";
import { businessTypes, startTypes } from "../../../data/businessHeroData";
import MultiSelectCountryDropdown from "../../shared/inputComponent/countryDropdown";

const SignupForm = ({ form, loading }) => {
  const {
    control,
    formState: { errors },
    setValue,
    register
  } = form;

  return (
    <div className="grid grid-flow-row gap-8 lg:h-full h-[950px] pb-2">
      <div className="flex flex-col gap-2">
        <div className="flex lg:flex-row flex-col gap-5">
          <div className="flex-1">
            <label className="text-[#B3B3B3] text-xs font-normal">
              First Name
            </label>
            <InputComponent
              type="text"
              placeholder="Enter First Name"
              fieldName="firstName"
              control={control}
              errors={errors}
              register={register}
              requiredMessage="Enter First Name"
            />
          </div>

          <div className="flex-1">
            <label className="text-[#B3B3B3] text-xs font-normal">
              Last Name
            </label>
            <InputComponent
              type="text"
              placeholder="Enter Last Name"
              fieldName="lastName"
              control={control}
              errors={errors}
              register={register}
              requiredMessage="Enter Last Name"
            />
          </div>
        </div>

        <div>
          <label className="text-[#B3B3B3] text-xs font-normal">
            Business Name
          </label>
          <InputComponent
            type="text"
            placeholder="Enter Business Name"
            fieldName="businessName"
            control={control}
            errors={errors}
            register={register}
            requiredMessage="Enter Business Name"
          />
        </div>

        <div>
          <label className="text-[#B3B3B3] text-xs font-normal">
            Business Address
          </label>
          <InputComponent
            type="text"
            placeholder="Enter Business Address"
            fieldName="businessAddress"
            control={control}
            errors={errors}
            register={register}
            requiredMessage="Enter Business Address"
          />
        </div>
        <div className="flex lg:flex-row flex-col gap-5">
          <div className="flex-1">
            <label className="text-[#B3B3B3] text-xs font-normal">
              Phone Number
            </label>
            <InputComponent
              type="text"
              placeholder="Enter Phone Number"
              fieldName="phoneNumber"
              control={control}
              errors={errors}
              register={register}
              requiredMessage="Enter Phone Number"
            />
          </div>
          <div className="flex-1">
            <label className="text-[#B3B3B3] text-xs font-normal">
              Email Address
            </label>
            <InputComponent
              type="text"
              placeholder="Enter Email Address"
              fieldName="email"
              control={control}
              errors={errors}
              register={register}
              requiredMessage="Enter Email Address"
            />
          </div>
        </div>
        <div className="flex lg:flex-row flex-col gap-5">
          <div className="flex-1">
            <label className="text-[#B3B3B3] text-xs font-normal">
              Store Type
            </label>
            <InputComponent
              inputType="select"
              placeholder="Select a store type"
              fieldName="startType"
              errors={errors}
              control={control}
              options={startTypes}
              register={register}
              required
              requiredMessage="Select a store type"
              className={
                "focus:ring-0 w-full focus:outline-none text-base mt-2"
              }
            />
          </div>
          <div className="flex-1">
            <label className="text-[#B3B3B3] text-xs font-normal">
              Business Type
            </label>
            <InputComponent
              inputType="select"
              placeholder="Select a business type"
              fieldName="businessType"
              errors={errors}
              control={control}
              options={businessTypes}
              register={register}
              required
              requiredMessage="Select a business type"
              className={
                "w-full focus:ring-0 focus:outline-none text-base rounded mt-2"
              }
            />
          </div>
        </div>

        <div>
          <label className="text-[#B3B3B3] text-xs font-normal">
            Country Served
          </label>
          <MultiSelectCountryDropdown
            placeholder="Select all that apply"
            {...form}
            className="w-full focus:ring-0 focus:outline-none text-base rounded mt-2"
          />
        </div>

        {/* <div className="text-base text-[#333333] pt-8">
          By clicking "Submit", you are accepting our{" "}
          <a href="#" className="text-blue-500 underline">
            Privacy Policy
          </a>
          .
        </div> */}
      </div>
      <div className={"self-end h-[40px]"}>
        <Button
          className={"flex mt-5"}
          size={"full"}
          type="submit"
          loading={loading}
          disable={loading}>
          Submit
        </Button>
      </div>

      {/* <span className="text-[#000] flex mx-auto items-center gap-2 text-[13px] md:text-[16px] font-normal">
        Already have an account?
        <button
          type="button"
          className="text-[#186F3D] underline"
          onClick={() =>
            (window.location.href = "https://afroshop-admin.vercel.app/login")
          }
        >
          Log In
        </button>
      </span> */}
    </div>
  );
};

export default SignupForm;

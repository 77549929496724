import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import {
  activeModal,
  postRequest,
  putRequest,
  userLogin,
} from "../../../redux/action";
import { AfroshopLogo, EmailIcon, ArrowLeft } from "../../../images";
import { Button } from "../../shared";
import { useForm } from "react-hook-form";
import { AFROPOT_TOKEN, expirationDate } from "../../../utils/constants";
import useToast from "../../toast/useToast";

function EmailVerification() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const { handleSubmit } = useForm({ mode: "all" });

  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const showToast = useToast();

  const handleOtp = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([
      ...otp.map((value, idx) => (index === idx ? element.value : value)),
    ]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }

    otp.join("");
  };

  const allOtpsNotEmpty = otp.every((str) => str.length > 0);

  // resend user otp
  const sendUserOtp = async (user) => {
    setLoading(true);
    try {
      const [success, response] = await postRequest("/api/otps", {
        email: user.email,
      });

      if (!success || response?.error) {
        console.error(response.error.message);
        showToast('error',
          `${
            response?.error?.message ||
            "An error occured while sending your otp"
          }`,
           'top-right', 1000);
      } else {
        showToast('success', 'otp sent to your email adress', 'top-right', 1000);
        dispatch(activeModal("emailVerify"));
      }
    } catch (error) {
      showToast('error', "An error occured while sending your otp", 'top-right', 1000);
    } finally {
      setLoading(false);
    }
  };

  const registerUser = async () => {
    setLoading(true);
    try {
      const [success, responseData] = await postRequest(
        "/api/auth/local/register",
        {
          username: user.email,
          email: user.email,
          password: user.password,
          ...(user.referralCode && { referralCode: user.referralCode }),
        }
      );

      if (!success || responseData?.error) {
        console.error(responseData.error.message);
        showToast('error',
          `${
            responseData?.error?.message || "An error occured while signing up"
          }`,
           'top-right', 1000);
      } else {
        dispatch(userLogin(responseData?.user));
        Cookies.set(AFROPOT_TOKEN, responseData?.jwt, {
          expires: expirationDate,
        });
        dispatch(activeModal("nameVerify"));
      }
    } catch (error) {
      showToast('error', "An error occured while signing up", 'top-right', 1000);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/otps?email=${user?.email}&otp=${Number(otp.join(""))}`
      );
      if (response?.error) {
        showToast('error',
          `${
            response?.error?.message ||
            "An error occured while verifying your otp"
          }`,
           'top-right', 1000);
      } else {
        await registerUser();
      }
    } catch (error) {
      console.error("error", error);
      showToast('error',
        `${
          error?.response?.data?.error?.message ||
          "An error occured while verifying your otp"
        }`,
         'top-right', 1000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-blackTransparent bg-opacity-10 z-50 flex justify-center items-center">
      <div className="md:w-[500px] w-full relative md:py-[30px] overflow-y-auto md:mt-6 py-7  bg-[#FFFFFF] rounded-lg flex flex-col justify-center">
        <div
          className="flex items-center absolute top-[20px] right-0 pr-8"
          onClick={() => dispatch(activeModal("signUp"))}
        >
          <ArrowLeft className="mr-2 cursor-pointer" alt="arrow-left" />
          <p className="text-base font-normal text-[#333333] cursor-pointer">
            Go Back
          </p>
        </div>
        <div className=" flex flex-col justify-center items-center gap-6 pt-8">
          <AfroshopLogo />
          <EmailIcon />
          <div className="flex flex-col justify-center items-center gap-3">
            <h5 className="text-base font-bold md:text-xl">
              Verify your email
            </h5>
            <div className="flex flex-col gap-2 text-[#CCCCCC] text-xs md:text-base justify-center items-center">
              <span>Please enter the 4 digit code sent to </span>
              <span>{user?.email}</span>
            </div>
            <form
              className="flex flex-col items-center justify-center bg-[#FFFFFF1A]"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-row gap-4 sm:gap-9">
                {otp.map((data, index) => {
                  return (
                    <input
                      type="text"
                      maxLength="1"
                      name="otp"
                      key={index}
                      value={data}
                      onChange={(event) => handleOtp(event.target, index)}
                      onFocus={(event) => event.target.select()}
                      className="w-[64px] h-[64px] text-2xl font-bold border border-[#CCCCCC] focus:outline-none focus:ring-0 focus:border-[#186F3D] px-5 py-3"
                    />
                  );
                })}
              </div>

              <div className="flex flex-col items-center gap-6 w-[88%] mt-4">
                <Button
                  size
                  variant="transparent"
                  outline="white"
                  className="text-[#186F3D] text-sm md:text-base"
                  onClick={async () => await sendUserOtp(user)}
                >
                  Resend Code
                </Button>

                <Button
                  className=" sm:w-[370px] w-[300px]"
                  icon="white"
                  type="submit"
                  loading={loading}
                  disabled={!allOtpsNotEmpty}
                >
                  Verify
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { SEO, PageLayout, Stores, Header, About, FAQ } from "../components";
import {
  activeModal,
  fetchStores,
  fetchProductCategories,
  fetchProducts,
} from "../redux/action";
import { useDispatch, useSelector } from "react-redux";
function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userLocation = useSelector((state) => state.location.full_address);

  const location = useLocation();
  const hasRefSegment = location.pathname.includes("/ref");
  useEffect(() => {
    const afroCookie = Cookies.get("Afroshop_Cookie");
    if (!afroCookie) {
      dispatch(activeModal("cookiePopup"));
    }

    if (hasRefSegment && !user) {
      const urlPath = location.pathname.split("/");
      const lastUrlString = urlPath[urlPath.length - 1];

      const refCode = lastUrlString.slice(-6);
      Cookies.set("refCode", refCode);
      dispatch(activeModal("signUp"));
    }
  }, [user, hasRefSegment]);

  useEffect(() => {
    dispatch(fetchProductCategories());
    dispatch(fetchProducts());
  }, []);

  useEffect(() => {
    dispatch(fetchStores(userLocation));
  }, [userLocation]);

  return (
    <>
      <SEO
        url="/"
        openGraphType="website"
        schemaType="website"
        title="Afroshop"
      />
      <PageLayout>
        <Header />
        <Stores />
        <About />
        <FAQ />
      </PageLayout>
    </>
  );
}

export default App;

import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeModal, guestLogOut, logOutUser } from "../../redux/action";
import {
  AvatarGrey,
  ChevronRight,
  GreenCancel,
  Location,
  LogoutIcon,
  UserAvatar,
} from "../../images";
import { OutSideClick } from "../../helpers";
import { Button } from "../shared";
import { Link, useNavigate } from "react-router-dom";
import { removeTokenFromCookie } from "../../utils/constants";

const MobileNavbar = ({
  open,
  setOpen,
  location,
  openLocationForm,
  setOpenLocationForm,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const ref = useRef(null);
  const boxOutsideClick = OutSideClick(ref);

  const navigate = useNavigate();

  useEffect(() => {
    if (boxOutsideClick) {
      setOpen(false);
    }
  }, [boxOutsideClick]);

  const handleLoginClick = (e) => {
    e?.stopPropagation();
    // dispatch(activeModal("login"));
    // setOpen(false);
    navigate(`/early-access`)
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleUserLogOut = () => {
    // dispatch(logOutUser());
    // removeTokenFromCookie();
    navigate(`/early-access`)
  };
  const handlesignUp = () => {
    // dispatch(activeModal("signUp"));
    // setOpen(false);
    navigate(`/early-access`)
  };

  return (
    <div>
      <div
        className={`transform top-0 right-0 fixed overflow-auto ease-in-out transition-all duration-300 backdrop-sepia-0 bg-blackTransparent lg:hidden h-full w-full z-[1000] ${open ? "translate-x-0" : "translate-x-0"
          }`}
      >
        <aside
          ref={ref}
          onClick={(e) => e.stopPropagation()}
          className="flex flex-col gap-4 slide-in bg-white z-100 fixed right-0 top-0 px-6 py-8 h-full lg:w-[93%] md:w-[94%] sm:w-[92%] w-[87%]"
        >
          <GreenCancel
            className="ml-auto cursor-pointer"
            onClick={handleCloseModal}
          />
          <div
            className="ml-auto"
            onClick={(e) => {
              e?.stopPropagation();
              handleCloseModal();
              dispatch(activeModal("deliveryAvailability"));
            }}
          >
            <div className="text-[#186F3D] flex flex-row gap-1 h-6">
              <Location className="self-center" />
              <p>{location}</p>
            </div>
            <p className="text-[10px] text-[#007AFF] text-center underline underline-offset-1">
              Change Location
            </p>
          </div>

          {isAuthenticated ? (
            <>
              <div className="flex flex-col items-center gap-4 my-3">
                <img
                  className="w-[50px] md:w-[100px] md:h-[100px] h-[50px] cursor-pointer object-contain rounded-[50%]"
                  src={user?.avatarUrl || UserAvatar}
                  alt="userImage"
                />
                <h6 className="font-bold text-base">
                  {" "}
                  {user?.firstName
                    ? `${user?.firstName} ${user?.lastName}`
                    : "Guest User"}
                </h6>
              </div>
              <hr className=" bg-[#E6E6E6] h-[1px] w-full" />
              <Link
                to={"/user-info"}
                className="w-full"
                onClick={handleCloseModal}
              >
                <div className="flex justify-between gap-7">
                  <div className="flex gap-6">
                    <img src={AvatarGrey} alt="avatar" />
                    <p className="text-[#333333] text-base">Manage Account</p>
                  </div>
                  <img src={ChevronRight} alt="RightIcon" />
                </div>
              </Link>
              <div
                className="flex justify-between w-full h-6"
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserLogOut();
                  dispatch(activeModal(""));
                  dispatch(guestLogOut());
                }}
              >
                <button className="text-[#FF3B30] font-normal text-base cursor-pointer">
                  Logout
                </button>
                <img src={LogoutIcon} alt="LogoutIcon" />
              </div>

              <hr className="bg-[#E6E6E6] h-[1px] w-full" />
            </>
          ) : (
            <>
              <Button className="w-full" onClick={handlesignUp}>
                Sign Up
              </Button>
              <Button
                className="w-full"
                variant="transparent"
                onClick={handleLoginClick}
              >
                {" "}
                Log In
              </Button>
            </>
          )}

          <a
            className="text-base bg-white py-[10px] cursor-pointer"
            href="/business"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sell on Afroshop
          </a>
          {/* <a
            className="text-base bg-white py-[10px] cursor-pointer"
            href="/drivers"
            target="_blank"
            rel="noopener noreferrer"
          >
            Deliver with Afroshop
          </a> */}
        </aside>
      </div>
    </div>
  );
};

export default MobileNavbar;

import React from "react";
import DriversNav from "../drivers/nav";
import Footer from "../footer";

const DriversLayout = ({ children }) => {
  return (
    <>
      <DriversNav />
      <div className="bg-white">
        {children}
      </div>
      <Footer isNonShoppers />
    </>
  );
};

export default DriversLayout;

import React from "react";
import { Broccoli, HeroImage, Underline, Star } from "../../../images";
import { Button } from "../../shared";
import handleScroll from "../../../helpers/handleScroll";

const Header = () => {
  return (
    <section className="w-full h-max relative bg-[#fffaf0] flex flex-col lg:flex-row justify-between px-4 lg:px-16 lg:pr-4 pt-12 lg:pt-[8rem]">
      <img
        src={Star}
        className="w-[24px] h-[25.48px] absolute left-[10%] lg:left-auto lg:right-[5%] top-[50px] lg:w-[50px] lg:h-[53.8px] lg:top-[40px]"
        alt=""
        aria-hidden="true"
      />
      <img
        src={Star}
        className="w-[24px] h-[25.48px] absolute right-[10%] top-[50%] lg:top-auto lg:right-auto lg:left-[30%] bottom-[20px] lg:w-[50px] lg:h-[53.8px] lg:bottom-[20%]"
        alt=""
        aria-hidden="true"
      />
      {/* Text Content */}
      <div className="relative z-[5]">
        <span className="flex w-[25%] lg:inline-flex items-center justify-center text-center text-[#FF9500] text-[10px] lg:text-[16px] bg-[#FFF0D8] lg:w-auto h-[23px] lg:h-[48px] mx-auto rounded-[11.5px] px-[10px] lg:rounded-[24px] lg:px-[24px] mb-[15px]">
          So Fresh
          <img
            className="w-[12px] h-[12px] lg:w-[24px] lg:h-[24px] ml-2"
            src={Broccoli}
            alt="Broccoli"
          />
        </span>
        <h1 className="text-[#186F3D] text-[31px] lg:text-[61px] leading-[45px] lg:leading-[72px] font-bold lg:font-extrabold text-center lg:text-left mb-2 lg:mb-8">
          Bring the Groceries <br /> to your Door
        </h1>
        <img
          className="absolute lg:top-[19%] top-[36%] left-[32%] z-[-10] w-[180px] h-[9.38px] lg:w-[455.5px] lg:h-[24px]"
          src={Underline}
          alt=""
        />
        <p className="text-[rgba(105,105,105,1)]  lg:text-2xl leading-[23px] lg:leading-[30px] text-center lg:text-left mb-2 lg:mb-16">
          Select preferred store, shop groceries <br /> and have them delivered
          to you!
        </p>
        <Button
          className="m-auto lg:m-0 w-[191px] mt-4"
          icon="white"
          onClick={(e) => handleScroll(e, "#store")}
        >
          Shop Now
        </Button>
      </div>
      {/* Background & Hero Image */}
      <div className="mt-[0.2rem] flex flex-1 justify-center items-center lg:mt-0 lg:mb-0 w-full h-full">
        <img
          src={HeroImage}
          alt="hero"
          className="w-full max-w-[256px] lg:max-w-[514px] mb-4 lg:mb-0 lg:ml-auto"
        />
      </div>
    </section>
  );
};

export default Header;

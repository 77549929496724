import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RiTwitterXLine } from "react-icons/ri";
import {
  TwitterLogo,
  GpayLogo,
  LogoWhite,
  LinkedinLogo,
  VisaLogo,
  PaypalLogo,
  PaypassLogo,
  InstagramLogo,
  MastercardLogo,
  PhoneLogo,
  EmailLogo,
} from "../../images";
import { useForm } from "react-hook-form";
import { postRequest } from "../../redux/action";
import useToast from "../toast/useToast";
import { useDispatch, useSelector } from "react-redux";
import InputComponent from "../shared/inputComponent";
import { Button } from "../shared";

const Footer = ({ isNonShoppers }) => {
  const [loading, setLoading] = useState(false);
  const location = useSelector((state) => state.location.display_name);
  const dispatch = useDispatch();
  const showToast = useToast();

  const navigate = useNavigate();
  const pageLocation = useLocation();

  const handleClick = (to) => {
    if (pageLocation.pathname === to) {
      window.scrollTo(0, 0);
    } else {
      navigate(to);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageLocation.pathname]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all" });

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = {
      data: { unSubscribed: false, email: data.email },
    };
    try {
      const [success, response] = await postRequest(
        "/api/subscribers",
        formData
      );
      if (!success || response?.error) {
        showToast(
          "error",
          `${
            response?.error?.message || "An Error occured while saving location"
          }`,
          "top-right",
          1000
        );
      } else {
        showToast("success", response?.message, "top-right", 1000);
        reset();
      }
    } catch (error) {
      showToast(
        "error",
        "An Error occured while saving location",
        "top-right",
        1000
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <footer className="bg-[#186F3D] sm:pt-[64px] sm:pb-[32px] sm:px-[70px] px-[24px] pb-[24px] pt-[32px] text-white text-base font-normal">
      <div className="flex gap-[92px] sm:flex-row sm:justify-start md:justify-center lg:justify-between xl:flex-nowrap sm:flex-wrap sm:items-start flex-nowrap flex-col justify-center items-center">
        <div className="flex flex-col border-box gap-[16px] xl:mr-[72px] ">
        <Link to="/" onClick={() => handleClick("/")}>
          <LogoWhite alt="logo" className="w-[173px] h-46" />
          </Link>
          <p className="w-full lg:w-[270px]">
            Shop conveniently, order groceries online and have delivered at your
            doorstep.
          </p>
          <div className="flex gap-[32px] mt-[8px] sm:mt-[16px]">
            <a
              href="https://twitter.com/afroshop_ca"
              target="_blank"
              rel="noreferrer"
            >
              <RiTwitterXLine className="h-[30px] text-white" />
            </a>
            <a
              href="https://www.linkedin.com/company/afroshop-ca"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinLogo alt="linkedin-logo" className="h-[30px]" />
            </a>
            <a
              href="https://www.instagram.com/afroshop_ca"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramLogo alt="instagram-logo" className="h-[30px]" />
            </a>
          </div>
        </div>

        <div className="flex flex-col border-box gap-[16px] mr-auto lg:mr-0 xl:mr-[72px] ">
          <p className="font-semibold">Quick Links</p>
          <p className="mt-2 cursor-pointer" onClick={() => handleClick("/")}>
            Shopper
          </p>
          {/* <p className="mt-2 cursor-pointer" onClick={() => handleClick("/drivers")}>
            Driver
          </p> */}
          <p className="mt-2 cursor-pointer" onClick={() => handleClick("/business")}>
            Business
          </p>
        </div>
        <div className="flex w-full lg:w-auto flex-col gap-[14px]">
          <p className="font-semibold">New to Afroshop?</p>
          <p className="lg:w-[284px]">
            Subscribe to our newsletter to get updates on out latest offers!
          </p>

          <form
            className="flex mt-[10px] gap-[12px] items-center w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="h-[50px] text-black bg-transparent">
              <InputComponent
                fieldName="email"
                compoundValidation={true}
                required
                placeholder="Enter Email Address"
                title={"email"}
                register={register}
                errors={errors}
                requiredMessage={"Enter Valid Email"}
                name={"email"}
                patternValue={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
                patternMessage="Invalid email address"
                type={"email"}
              />
            </div>
            <Button
              type="submit"
              loading={loading}
              outline="white"
              className="font-normal w-[136px] px-1 border border-white border-1 h-[40px] rounded"
            >
              Submit
            </Button>
          </form>
        </div>

        {/* <div className="flex w-full lg:w-auto flex-col gap-[25px]">
          <p className="font-semibold mb-4">Payment Methods</p>
          <div className="flex flex-wrap w-full gap-[40px] lg:w-[290px] items-center">
            <MastercardLogo alt="mastercard-logo" />
            <GpayLogo alt="gpay-logo" />
            <VisaLogo alt="visa-logo" />
          </div>
        </div> */}
      </div>

      <div className="sm:flex block flex-wrap justify-between items-center">
        <div className="mt-[64px] sm:mt-[84px] text-center order-1 sm:order-2 w-full sm:w-auto">
          {" "}
          {/* <a href="/privacy-policy">Privacy Policy</a> */}
        </div>
        <p className="mt-[64px] sm:mt-[84px] order-2 sm:order-1 w-full sm:w-auto text-center sm:text-left">
          &copy; Copyright Afroshop {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
};

export default Footer;

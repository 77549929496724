import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { Button } from "../shared";
import DeliveryInfo from "./delivery-info";
import PaymentMethod from "./payment-method";
import DeliveryCheckout from "./confirmation";
import { ArrowLeft } from "../../images";
import UserSignedIn from "./user-signed-in";
import { UseViewport } from "../../helpers";
import { getCartSize, getStoreCartSize } from "../../helpers/cartFunctions";
import { useNavigate } from "react-router-dom";
import { getDeliveryDates } from "../../helpers/deliveryDates";
import { guestLogOut, putRequest } from "../../redux/action";
import useToast from "../toast/useToast";

function isPayloadEqual(payload1, payload2) {
    // Check if the payloads have the same keys and values
    return JSON.stringify(payload1) === JSON.stringify(payload2);
}

const getDeliveryDate = (date, time) => {
    const deliveryDate = new Date(date)
    // Extract the start time from the time range
    const startTime = time.split(" - ")[0];
    const [hours, minutes] = startTime.split(":");
    deliveryDate.setHours(parseInt(hours, 10));
    deliveryDate.setMinutes(parseInt(minutes, 10));
    deliveryDate.setSeconds(0);
    return deliveryDate.toISOString()
}

const ConfirmOrderDelivery = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const showToast = useToast
        ();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    useLayoutEffect(() => {
        scrollToTop()
        return () => {
            dispatch(guestLogOut())
        }
    }, [dispatch])

    const isAuthenticated = useSelector(state => state.isAuthenticated);
    const cart = useSelector(state => state.cart)
    const checkoutStore = useSelector(state => state.checkoutStore)
    const addresses = useSelector((state) => state.addresses);

    const storeAddress = cart[checkoutStore].fullAddress
    const deliveryDates = getDeliveryDates(
        cart[checkoutStore].openDays,
        cart[checkoutStore].deliveryTime,
        cart[checkoutStore].deliverySlots.deliverySlotLengthinHrs ?? 2,
        cart[checkoutStore].deliverySlots.restPeriodinHrs ?? 0
    )

    const [loadingDeliveryFee, setLoading] = useState(true)
    const [deliveryFeeData, setFeeData] = useState({
        deliveryFee: 0,
        deliveryMessage: 'When you input an Address we will calculate your Delivery Fee.',
        payload: null,
        success: false
    })

    const [cartSize, setCartSize] = useState(0)
    useEffect(() => {
        setCartSize(checkoutStore ? getStoreCartSize(cart, checkoutStore) : getCartSize(cart))
    }, [cart, checkoutStore])

    const isMobileView = UseViewport();

    const [steps, setSteps] = useState(1);
    const handleArrowClick = () => {
        scrollToTop()
        if (isMobileView && steps > 1) {
            setSteps((steps) => steps - 1)
        } else {
            navigate("/")
        }
    }

    // Functions for getting form data
    const [orderData, setOrderData] = useState({
        deliveryOption: "delivery",
        dayIndex: 0,
        timeIndex: 0,
        deliveryDateTime: getDeliveryDate(deliveryDates[0].dateObject, deliveryDates[0].time[0]),
        newAddress: (!isAuthenticated || addresses?.length === 0),
        saveNewAddress: false,
        savedAddress: (isAuthenticated && addresses?.length > 0) ? addresses[addresses?.length - 1].id : null, // id for saved address else object with address info
    });

    const [validAddress, setValid] = useState(false) // true if the user's typed address has been validated

    const handleOrderData = (option, value) => {
        if (option === 'deliveryOption') {
            setFeeData({
                deliveryFee: 0,
                deliveryMessage: value === 'pickup' ? "We don't charge for pickup deliveries!" : "When you input an Address we will calculate your Delivery Fee.",
                payload: null,
                success: false
            })
        }
        setOrderData(prevState => {
            if (option === 'timeIndex') {
                return {
                    ...prevState,
                    timeIndex: value,
                    deliveryDateTime: getDeliveryDate(prevState.deliveryDateTime, deliveryDates[prevState.dayIndex].time[value])
                };
            } else if (option === 'dayIndex') {
                return {
                    ...prevState,
                    timeIndex: 0,
                    dayIndex: value,
                    deliveryDateTime: getDeliveryDate(deliveryDates[value].dateObject, deliveryDates[value].time[0])
                };
            } else if (option === 'useNewAddress') {
                return {
                    ...prevState,
                    newAddress: value,
                    savedAddress: value ? null : addresses[addresses?.length - 1].id // if true set saved Address to null else the last address
                };
            } else if (option === 'useSavedAddress') {
                return {
                    ...prevState,
                    newAddress: false,
                    savedAddress: value
                };
            } else if (option === 'deliveryOption') {
                return {
                    ...prevState,
                    deliveryOption: value,
                    savedAddress: (
                        value === 'pickup' ?
                            cart[checkoutStore].address
                            :
                            (isAuthenticated && addresses?.length > 0) ?
                                addresses[addresses?.length - 1].id
                                :
                                null
                    )
                }
            }
            return {
                ...prevState,
                [option]: value
            };
        });
    };

    const [paymentData, setPaymentData] = useState({
        cardType: 'mastercard',
        cardHolderName: '',
        CVV: null,
        expiryDate: null,
        cardNumber: ''
    });

    // const handlePaymentData = (option, value) => {
    //     setPaymentData(prevState => {
    //         return {
    //             ...prevState,
    //             [option]: value
    //         };
    //     });
    // };

    const deliveryForm = useForm({ mode: "all" });
    const paymentForm = useForm({ mode: "onSubmit" });

    const submitDeliveryForm = async () => {
        await deliveryForm.handleSubmit(() => { })()
        const isComplete = (
            orderData.deliveryOption !== "delivery" || !orderData.newAddress || orderData.newAddress && validAddress
        ) // If we are using a new address then the form must be filled and valid
        return isComplete
    }

    const submitPaymentForm = async () => {
        await paymentForm.handleSubmit(() => { })()
        const isComplete = paymentForm.formState.isValid || Object.keys(paymentForm.formState.errors).length === 0
        return isComplete
    }

    const handleNextButton = async () => {
        let isComplete = false
        switch (steps) {
            case 1:
                isComplete = await submitDeliveryForm()
                break
            case 2:
                isComplete = await submitPaymentForm()
                break
            default:
                console.error('unusual step state')
                break
        }
        if (
            steps === 1  // delivery form
            && (
                orderData.deliveryOption === "delivery" && orderData.newAddress && !validAddress // address is not yet valid
            )
        ) {
            deliveryForm.setError('address', {
                type: "manual",
                message: "Select a valid address"
            })
            return null
        }
        else if (
            steps === 1  // delivery form
            && (
                !deliveryFeeData.success  // delivery fee wasn't calculated
                && deliveryFeeData.payload !== null // error message has been populated
                && orderData.deliveryOption === "delivery" // it is delivery
            )
        ) {
            showToast('error', deliveryFeeData?.deliveryMessage, 'top-right', 3000);
            return null
        }
        else if (steps < 3 && isComplete) {
            scrollToTop();
            setSteps(steps + 1)
        }
    };

    const handleSubmitOrder = async () => {
        let isComplete = true
        if (!isMobileView) {
            // to reach this screen on mobile, order is already complete so we only submit for desktop
            isComplete = await submitDeliveryForm()
        }
        if (
            orderData.deliveryOption === "delivery" && orderData.newAddress && !validAddress // address is not yet valid
        ) {
            deliveryForm.setError('address', {
                type: "manual",
                message: "Select a valid address"
            })
        }
        if (!isMobileView && isComplete) {
            // this will run only if deliveryForm is complete and check if payment form is complete
            isComplete = await submitPaymentForm()
        }

        return [isComplete, { paymentData, orderData }]
    }

    const orderSteps = [
        "delivery info",
        // "payment method",
        "confirm & pay",
    ]


    useEffect(() => {
        const { address } = deliveryForm.getValues()
        const payload = {
            type: orderData.deliveryOption,
            storeID: cart[checkoutStore].id,
            address: orderData.newAddress ? address : orderData.savedAddress,
            storedAddress: !orderData.newAddress
        }

        const getDeliveryFee = async () => {
            // delivery form is complete

            const isComplete = (
                orderData.deliveryOption !== "delivery" || !orderData.newAddress || orderData.newAddress && validAddress
            )

            if (!isComplete) return

            try {
                setLoading(true)
                const [success, response] = await putRequest(
                    `/api/distance`,
                    payload
                );
                if (!success || response.status === 400 || response?.error) {
                    throw new Error(response.error)
                }
                setFeeData({
                    deliveryFee: response.deliveryFee,
                    deliveryMessage: response.message,
                    payload,
                    success: true
                })
                setLoading(false)
            } catch (error) {
                console.error(error);
                setFeeData({
                    deliveryFee: 0,
                    deliveryMessage: error.message,
                    payload,
                    success: false
                })
            }
        }

        if (
            !isPayloadEqual(payload, deliveryFeeData.payload)
            && orderData.deliveryOption !== 'pickup'
            &&
            (
                (
                    orderData.newAddress
                    && address
                )
                ||
                (
                    !orderData.newAddress
                    && orderData.savedAddress
                )
            )
        ) {
            getDeliveryFee()
        }
    }, [orderData, deliveryForm])

    return (
        <div className={isMobileView ? "w-[100vw] py-[24px] px-4 pb-[60px] block lg:hidden" : "px-20 hidden lg:block mb-28"}>
            <div className="flex mt-[0] mb-4 md:mb-8 md:mt-12" onClick={handleArrowClick}>
                <ArrowLeft className="mr-2 cursor-pointer" alt="arrow-left" />
                <p className="text-base font-normal text-[#333333] cursor-pointer">
                    {
                        isMobileView && steps > 1 ?
                            "Go Back"
                            :
                            "Back to Store"
                    }
                </p>
            </div>

            {
                isMobileView &&
                <div className="flex justify-between items-center mb-12">
                    <div className={`flex flex-column justify-between w-[95%] gap-[20px]`}>
                        {
                            orderSteps.map((orderStep, index) => (
                                <div key={index} className="w-[100%] h-[4px] bg-[#D9D9D9] relative">
                                    {
                                        steps > index + 1 ?
                                            <div className={`w-full ${index + 1 >= steps ? "bg-[#25945A]" : "bg-[#186F3D]"} h-full`}></div>
                                            :
                                            <div className="w-[8px] h-[8px] bg-[#186F3D] rounded-full -translate-y-1/3"></div>
                                    }
                                    <p className={`capitalize ${index >= steps ? "text-[#E9E9E9]" : "text-[#333333]"} text-[10px] leadimg-[15px] absolute top-[15px]`}>{orderStep}</p>
                                </div>
                            ))
                        }
                    </div>

                    <p className="mt-0 w-[5%] ml-2">
                        <span className="text-base text-[#186F3D] font-normal">{steps}</span>
                        <span className="text-[10px] leading-[15px] text-[#333333]">/{orderSteps.length}</span>
                    </p>
                </div>
            }

            <div className={`flex w-full justify-between gap-[1%] ${isMobileView && "flex-col w-full"}`}>
                <div className={`${isMobileView ? "" : "max-w-[52%] py-[24px]"}`}>
                    {!isAuthenticated && <UserSignedIn />}
                    <div className="w-full">
                        {
                            (!isMobileView || steps === 1) &&
                            <DeliveryInfo
                                handleData={handleOrderData}
                                storeAddress={storeAddress}
                                data={orderData}
                                addresses={addresses}
                                deliveryDates={deliveryDates}
                                isAuthenticated={isAuthenticated}
                                form={deliveryForm}
                                setValidAddress={setValid}
                            />
                        }
                        {/* {
                            (!isMobileView || steps === 2) &&
                            <PaymentMethod
                                paymentData={paymentData}
                                handlePaymentData={handlePaymentData}
                                isAuthenticated={isAuthenticated}
                                form={paymentForm}
                            />
                        } */}
                    </div>
                </div>

                {
                    (!isMobileView || steps === 2) &&
                    <div className={isMobileView ? "px-[12px]" : "w-[42%] min-w-[420px] justify-between mt-[34px]"}>
                        <p className="mb-4">{`Order Summary ${cartSize === 0 ? '' : `(${cartSize})`}`}</p>
                        <div className={isMobileView ? "" : "border border-solid border-[#E6E6E6] py-[24px] px-8"}>
                            <DeliveryCheckout
                                handleSubmitOrder={handleSubmitOrder}
                                deliveryFee={deliveryFeeData.deliveryFee}
                                deliveryMessage={deliveryFeeData.deliveryMessage}
                                isFeeCalculated={deliveryFeeData.success}
                                delivery={orderData.deliveryOption === "delivery"}
                            />
                        </div>
                    </div>
                }
                {
                    (isMobileView && steps < orderSteps.length) &&
                    <Button
                        type="button"
                        variant="primary"
                        size="full"
                        outline="green"
                        icon="white"
                        className="py-1 mt-4 mb-4 w-[100%]"
                        onClick={handleNextButton}>
                        Next
                    </Button>
                }
            </div>
        </div >
    )
}

export default ConfirmOrderDelivery;
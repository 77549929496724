import React from 'react'
import { DriversAuthLayout, DriversSignupSuccess } from '../components'

const DriversSignupSuccessPage = () => {
  return (
    <DriversAuthLayout>
        <DriversSignupSuccess />
    </DriversAuthLayout>
  )
}

export default DriversSignupSuccessPage
import React from "react";
import DriversJumbo from "./jumbo";
import Testimonials from "./testimonial";
import DriversBenefitsSection from "./benefits";
import Faqs from "./faqs";
import HowToApply from "./howToApply";

const Drivers = () => {
  return (
    <div className="max-w-[1440px] mx-auto w-full h-full flex flex-col gap-[60px] md:gap-[80px] large-screen pt-[5rem]">
      <DriversJumbo />
      <DriversBenefitsSection />
      <HowToApply />
      <Testimonials />
      <Faqs />
    </div>
  )
}


export default Drivers;

import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { ArrowLeft } from "../../images";
import { useSelector } from "react-redux";
import { getCartSize } from "../../helpers/cartFunctions";
import { getName } from "../../helpers/getStoreName";
import EmptyCart from "../empty-cart";
import StoreCart from "../store-in-cart/StoreCart";

const CartCheckout = ({ handleHideCart }) => {
  const cart = useSelector(state => state.cart)
  const [cartSize, setCartSize] = useState(0)

  useEffect(() => {
    setCartSize(getCartSize(cart))
  }, [cart])

  const location = useLocation();
  const [storeIDs, setStoreIDs] = useState(Object.keys(cart))

  useEffect(() => {
    if (location?.pathname && storeIDs.length === 0) return

    for (let storeUID of storeIDs) {
      if (storeUID in cart && location.pathname === `/store/${getName(cart[storeUID].name)}`) {

        const newArray = [
          storeUID,
          ...storeIDs.filter((item) => item !== storeUID)
        ];
        
        setStoreIDs(newArray)
        break
      }
    }
  }, [location.pathname, cart])

  return (
    <div className="flex flex-col gap-[24px] w-[100vw] sm:w-[585px] rounded px-4 sm:px-8 bg-white pb-12">
      <div className="flex py-4 cursor-pointer" onClick={handleHideCart}>
        <ArrowLeft alt="arrow-left" className="mr-2" />
        <p>{`Cart ${cartSize === 0 ? '' : `(${cartSize})`}`}</p>
      </div>
      {
        cartSize === 0 ?
          <EmptyCart />
          :
          <>
            {
              storeIDs.map((store, key) =>
                <StoreCart store={store} key={key} handleHideCart={handleHideCart} />
              )
            }
          </>
      }
    </div>
  );
};

export default CartCheckout;

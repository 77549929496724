import React from 'react'
import { PageLayout, PrivacyPolicy } from '../components'

const PrivacyPolicyPage = () => {
  return (
   <PageLayout>
    <PrivacyPolicy />
   </PageLayout>
  )
}

export default PrivacyPolicyPage
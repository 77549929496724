import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { HiOutlineExclamationCircle, HiOutlineExclamationTriangle, HiOutlineCheckCircle } from "react-icons/hi2";
import { LiaTimesSolid } from "react-icons/lia";
import { removeToast } from '../../redux/action';

const ToastWrapper = ({ type, message, defaultPosition = "top-right", defaultTimeout = 1000 }) => {
    const dispatch = useDispatch();
    const messages = useSelector((state) => state.toasts.messages);

    // State to track which toasts are exiting
    const [exitingToasts, setExitingToasts] = useState([]);

  // useEffect to handle toast removal after a timeout  
  useEffect(() => {
    const timers = messages.map(toast => {
      const timeout = toast.timeout !== undefined ? toast.timeout : defaultTimeout;
      setTimeout(() => {
        setExitingToasts(prev => [...prev, toast.id]);
        setTimeout(() => {
          dispatch(removeToast(toast.id));
          setExitingToasts(prev => prev.filter(id => id !== toast.id));
        }, 500); // 0.5 seconds to match the slideOutRight animation duration
      }, timeout); // Custom timeout
    });
    return () => {
        timers.forEach(timer => clearTimeout(timer));
      };
    }, [messages, dispatch, defaultTimeout]);
    

    // Function to get CSS classes for toast type
      const getTypeStyles = (type) => {
        switch (type) {
          case 'success':
            return 'bg-white border-[#34C759]';
          case 'error':
            return 'bg-white border-[#FF3B30]';
          case 'warning':
            return 'bg-white border-[#FFD60A]';
          case 'info':
            return 'bg-white border-[#0000FF]';
          default:
            return '';
        }
      };
    
      // Function to get the icon for toast type
      const getIcon = (type) => {
        switch (type) {
          case 'success':
            return <HiOutlineCheckCircle className="w-5 h-5 text-green" />;
          case 'error':
            return <HiOutlineExclamationCircle className="w-5 h-5 text-red-700" />;
          case 'warning':
            return <HiOutlineExclamationTriangle className="w-5 h-5 text-yellow" />;
          case 'info':
            return <AiOutlineInfoCircle className='w-5 h-5 text-blue-700' />;
          default:
            return null;
        }
      };

      // Function to get CSS classes for toast position
      const getPositionStyles = (position) => {
        switch (position) {
            case 'top-left':
                return 'top-12 lg:top-20 left-0';
            case 'top-right':
                return 'top-12 lg:top-20 right-0';
            case 'bottom-left':
                return 'bottom-0 left-0';
            case 'bottom-right':
                return 'bottom-0 right-0';
            default:
                return 'top-20 right-0';
        }
    };

    // Group toasts by their positions
    const groupedToasts = messages.reduce((acc, toast) => {
      const position = toast.position || defaultPosition;
      if (!acc[position]) {
          acc[position] = [];
      }
      acc[position].push(toast);
      return acc;
  }, {});

    
      return (
        <>
        {Object.keys(groupedToasts).map(position => (
                <div key={position} className={`fixed ${getPositionStyles(position)} p-2 sm:p-4 space-y-2 sm:space-y-4 z-[999]`}>
                    {groupedToasts[position].map((toast) => (
                        <div
                            key={toast.id}
                            className={`
                              ${exitingToasts.includes(toast.id) ? 'toast-exit' : 'toast-enter'} 
                                  w-full
                                  max-w-xs  // Default max width for very small screens
                                  sm:max-w-sm  // Small screens and up (640px and above)
                                  md:max-w-md  // Medium screens and up (768px and above)
                                  lg:max-w-lg  // Large screens and up (1024px and above)
                                  xl:max-w-xl  // Extra large screens and up (1280px and above)
                                  h-auto
                                  border
                                  p-2 sm:p-4
                                  rounded
                                  shadow-lg
                                  flex items-center
                                  gap-1 
                                  ${getTypeStyles(toast.type)} 
                                  z-50`}
                            
                        >
                            <div className='flex items-center justify-between w-full'>
                                {getIcon(toast.type)}
                                <span className='ml-2 text-black text-xs sm:text-sm md:text-base'>
                                    {toast.message}
                                </span>
                                <button
                                    onClick={() => dispatch(removeToast(toast.id))}
                                    className='text-gray-400 ml-2'>
                                    <LiaTimesSolid className='w-5 h-5 text-gray-400' />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </>
        
      );
    };
  

export default ToastWrapper;
import React from "react";
import { Phone, Sms } from "../../images";

const YellowBanner = ({}) => {

  return (
    <div className="flex flex-row justify-between content-start lg:px-16 px-6 py-3 text-[#003D1A] bg-[#fffaf0] text-[13px] lg:text-base leading-6 no-underline font-normal">
      <div className="flex flex-row gap-2 md:flex">
        <Phone />
        <a href="tel:+1-437-345-0501">+1-437-345-0501</a>
      </div>

      <div className="flex flex-row gap-3 md:flex">
        <div className="w-full flex gap-2">
          <img src={Sms} alt="smsImage" />
          <a href="mailto:info@afroshop.ca">info@afroshop.ca</a>
        </div>
      </div>
    </div>
  );
};

export default YellowBanner;
import React, { useEffect, useState } from "react";
import { CheckIconWhite, CloseIcon } from "../../images";
import { getSubregionByISO } from "../../helpers/getCountriesSubregion";
import { useSelector } from "react-redux";
import { Checkbox } from "../shared";

const StoreFilterPanel = ({ regions, isMobile, setStoresData, setOpenFilter, openFilter }) => {
  const [selectedRegions, setSelectedRegions] = useState([regions[0].value]);
  const stores = useSelector((state) => state.stores);

  useEffect(() => {
    setStoresData(stores);
  }, []);

  const handleFilter = (region) => {
    let selectedFilters = [];

    if (region.value === "all") {
      selectedFilters = ["all"];
    } else {
      if (selectedRegions.includes(region.value)) {
        selectedFilters = selectedRegions.filter(
          (item) => item !== region.value
        );

        // If no regions are selected, reset to "all"
        if (selectedFilters.length === 0) {
          selectedFilters = ["all"];
        }
      } else {
        // Remove "all" if another region is selected
        selectedFilters = selectedRegions.filter((item) => item !== "all");

        // Add the new region
        selectedFilters.push(region.value);
      }
    }

    setSelectedRegions(selectedFilters);

    if (selectedFilters.includes("all")) {
      setStoresData(stores);
    } else {
      const newStores = stores?.filter((store) => {
        if (store?.countries) {
          let countries = store?.countries
            ?.split(",")
            .map((country) => getSubregionByISO(country));

          if (countries?.some((value) => selectedFilters.includes(value))) {
            return store;
          }
        }
      });
      setStoresData(newStores);
    }
  };

  const handleReset = () => { handleFilter(regions[0]) }

  return (
    <>
      {
        isMobile && openFilter ?
          <div
            className={`fixed top-0 left-0 z-[10] bg-[#FFFFFF] p-[24px] flex flex-col gap-3 w-full h-full`}
          >
            <div className="flex justify-between items-center gap-2 py-2">
              <CloseIcon
                alt="cancelIcon"
                onClick={() => setOpenFilter(false)}
                className="cursor-pointer self-center"
              />
              <p>
                Filters
                <span>  ({selectedRegions.length === 1 && selectedRegions[0] === regions[0].value ? 0 : selectedRegions.length})</span>
              </p>
              <button
                className="w-[72px] h-[40px] text-[#186F3D] border border-solid border-[#186F3D] rounded"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>

            <h4 className="text-[#186F3D] font-bold mb-4">Region</h4>

            <div className="flex flex-col gap-5">
              {
                regions.map((region, index) => {
                  const isChecked = selectedRegions?.includes(region.value)
                  return (
                    <div
                      key={index}
                      className="flex"
                    >
                      <Checkbox
                        name={`region-${index}`}
                        id={index}
                        isChecked={isChecked}
                        handleChange={() => handleFilter(region)}
                      />
                      <span
                        onClick={() => handleFilter(region)}
                      >
                        {region.name}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
          :
          <div className={`flex flex-wrap gap-4 mt-8 ${isMobile ? "hidden" : ""}`}>
            {regions.map((region, key) => (
              <div
                key={key}
                className={`${selectedRegions?.includes(region.value)
                  ? "bg-green text-white flex gap-2 items-center justify-center"
                  : "border border-green text-green text-center"
                  } md:py-4 md:px-6 p-3 md:text-base text-sm rounded-[100px] w-[180px] cursor-pointer`}
                onClick={() => handleFilter(region)}
              >
                {region.name}{" "}
                {selectedRegions?.includes(region.value) && (
                  <span>
                    <CheckIconWhite />
                  </span>
                )}
              </div>
            ))}
          </div >
      }
    </>
  );
};

export default StoreFilterPanel;

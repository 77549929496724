import React from "react";
import BusinessNav from "../business/navBar";
import Footer from "../footer";
import ModalFactory from "../modal/modalFactory";

const BusinessLayout = ({ children }) => {
  return (
    <>
      <BusinessNav />
      <div className="bg-white">
        {children}
      </div>
      <Footer isNonShoppers/>
      <ModalFactory />
    </>
  )
}

export default BusinessLayout;
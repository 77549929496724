import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../navbar";
import { YellowBanner, GreenBanner } from "../banner";
import Footer from "../footer";
import ModalFactory from "../modal/modalFactory";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCheckoutStore,
  activeModal,
  fetchStores,
  fetchProductCategories,
  fetchProducts,
  logOutUser,
  updateCart,
  getAnnouncementBanner,
  getUserAddress,
  getUserOrders,
  getUserReferralId
} from "../../redux/action";
import { getTokenFromCookie, removeTokenFromCookie } from "../../utils/constants";
import useIdleActivityTimer from "../../hooks/useIdleTimer";

const PageLayout = ({ userAccount = false, children }) => {
  const user = useSelector((state) => state.user);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const announcementBannerData = useSelector((state) => state.announcementBannerData);
  const userLocation = useSelector((state) => state.location.full_address);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  /*

    This section handles user Inactivity after 20mins

  */

  // idle logout during idle state
  const handleIdle = () => {
    removeTokenFromCookie();
    console.log('logged out because of inactivity')
    dispatch(logOutUser());
  };

  const handleActive = () => {
    idleTimer.reset();
  };

  const idleTimer = useIdleActivityTimer(handleIdle, handleActive, 20);

  /*

    This section handles Token expiry after 1hour
    This section handles redirect for user specific pages

  */
  useEffect(() => {
    if (isAuthenticated) {
      const intervalId = setInterval(() => {
        const token = getTokenFromCookie();
        const isCookieExpired = !token;
        if (isCookieExpired) {
          console.log('logged out because token expired')
          dispatch(logOutUser()); // Dispatch the logout action when the cookie expires
        }
      }, 60000);
      return () => {
        clearInterval(intervalId); // Clear the interval on component unmount
      };
    } else {
      if (userAccount) {
        navigate("/");
      }
    }
  }, [isAuthenticated]);

  /*

    This section gets Stores, Products and their Category Data and updates cart

  */


  useEffect(() => {
    dispatch(fetchStores(userLocation));
  }, [location.pathname, userLocation])

  useEffect(() => {
    dispatch(fetchProductCategories());
    dispatch(fetchProducts());
    dispatch(getAnnouncementBanner());
  }, [location.pathname]);

  const cart = useSelector((state) => state.cart)
  const stores = useSelector((state) => state.stores)
  const products = useSelector((state) => state.products)

  useEffect(() => {
    const newCart = { ...cart }

    // Iterate through each store in the cart
    Object.keys(cart).forEach(storeID => {
      newCart[storeID].disabled = true
    })

    // Iterate through each store
    stores?.forEach(
      store => {
        const storeID = `${store.id}`

        if (storeID in cart) {
          // Update store
          newCart[storeID] = { ...cart[storeID], ...store };
          newCart[storeID].disabled = false

          const storeName = newCart[storeID].name

          Object.keys(newCart[storeID].items).forEach(SKU => {
            newCart[storeID].items[SKU].disabled = true
          })

          // Iterate through each product category in that store for products
          products[storeName].forEach(({ category, data }) => {
            data.forEach(product => {
              if (product.SKU in newCart[storeID].items && parseInt(newCart[storeID].items[product.SKU].availability) > 0) {
                const quantity = newCart[storeID].items[product.SKU].quantity
                newCart[storeID].items[product.SKU] = {
                  ...newCart[storeID].items[product.SKU],
                  ...product,
                  quantity,
                  category,
                  disabled: false
                }
              }
            })

          })


        }
      }
    )

    dispatch(updateCart({ payload: newCart, inplace: false }));
  }, [stores, products]);

  /*
  
    This Section ensures users who's token expired while they were not on the website have their details cleared
  
  */
  useEffect(() => {
    const token = getTokenFromCookie();
    if (user && !token) {
      console.log('clearing old loggedIn User')
      dispatch(logOutUser()); // Dispatch the logout action when the cookie expires
    }
  }, [])

  /*

    This Section ensures logged in users confirm their details after signing up

  */
  useEffect(() => {
    if (user && ('firstName' in user) && !user.firstName) {
      dispatch(activeModal("nameVerify"));
    }
  }, [user]);

  /*

    This section is for page specific api calls and actions

  */
  const confirmOrder = "/confirm-order";
  const userInfo = "/user-info";
  useEffect(() => {
    // This resets the store for checkout when you navigate away from the confirm order page
    if (location.pathname !== confirmOrder) {
      dispatch(changeCheckoutStore(""));
    }

    // User specific api calls
    if (isAuthenticated) {
      const token = getTokenFromCookie()
      if (location.pathname === confirmOrder ) {
        dispatch(getUserAddress(token))
      }
      else if (location.pathname === userInfo) {
        dispatch(getUserAddress(token))
        dispatch(getUserOrders(token, user.id))
        dispatch(getUserReferralId(token))
      }
    }
  }, [location.pathname, isAuthenticated]);

  return (
    <section>
      {!userAccount && (
        <>
          <YellowBanner
            showPromoText
            announcementBannerData={announcementBannerData}
          />
          <GreenBanner announcementBannerData={announcementBannerData} />
        </>
      )}
      <Navbar />
      <div className="bg-white w-full h-full flex flex-col gap-[60px] md:gap-[80px] large-screen">
        {children}
      </div>
      {!userAccount && <Footer />}
      <ModalFactory />
    </section>
  );
};

export default PageLayout;

import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { activeModal } from "../../../redux/action";
import { AfroshopLogo } from '../../../images'
import { WELCOME_DATA } from '../../../data'
import { Button } from "../../shared";
import { useNavigate } from 'react-router-dom';

function Welcome() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const loginGuest = useSelector((state) => state.loginGuest)

  const handleNavigation = () => {
    dispatch(activeModal(""));
    if (loginGuest) {
      navigate("/confirm-order");
    }
  }

  return (
    <div className='fixed inset-0 bg-blackTransparent bg-opacity-10 z-50 flex justify-center items-center'>
      <div className='md:w-[500px] w-[95%] md:py-[32px] overflow-y-auto overflow-hidden py-7 px-5 bg-[#FFFFFF] rounded-lg flex flex-col justify-center items-center gap-6 md:gap-[40px]'>

        <div className='flex flex-row gap-1'>
          <h4 className='text-2xl font-bold md:text-3xl'>Welcome to</h4>
          <AfroshopLogo />
        </div>

        <div className='flex flex-col gap-6 md:gap-[40px] md:px-8 md:w-full w-[290px]'>
          {WELCOME_DATA.map((item, index) =>
            <>
              <div className='flex flex-row gap-3 md:gap-8 '>
                <img key={index} src={item.image} alt="Icon" />
                <div className='flex flex-col gap-2'>
                  <h5 key={index} className='text-base font-bold md:text-xl'>{item.title}</h5>
                  <span key={index} className='text-[#CCCCCC] text-xs md:text-base'>{item.description}</span>
                </div>
              </div>
            </>
          )}
        </div>
        <Button className="md:w-[400px] w-[290px]" icon="white" onClick={handleNavigation}>Continue</Button>
      </div>
    </div>
  )
}

export default Welcome